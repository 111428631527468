import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/interceptors/api.service';
import { BCURegionDTO } from '../Models/BCURegionDTO.Model';
import { environment } from 'src/environments/environment';
import { UrlHelperService } from '../core/services/url-helper.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  BCURegionDTO: BCURegionDTO = new BCURegionDTO()

  // isSignupRoute = false;

  constructor(
    private _apiService: ApiService,
    private _urlHelperService: UrlHelperService
    ) { }

  getRegionList() {
    const url = "/BCU/RegionList";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setSelectedBCURegionDTO(BCURegionDTO: BCURegionDTO) {
    localStorage.setItem("BCURegionDTO", JSON.stringify(BCURegionDTO))
  }

  getSelectedBCURegionDTO(): BCURegionDTO {
    let obj = localStorage.getItem("BCURegionDTO")
    this.BCURegionDTO = JSON.parse(obj);
    return this.BCURegionDTO;
  }
  setPageTitle(title: any) {
    var documet_title = document.getElementsByTagName('title');
    if (documet_title != null) {
      documet_title[0].innerHTML = title
    }
  }


  GetCurrentPageHelpURL(route: string): string{
    if (route === this._urlHelperService.BcuUserDashboardUrl()){
      return environment.PCR_help_BCU_dashboard_URL;
    }
    else if (route === this._urlHelperService.BcuUserContactUrl()){
      return environment.PCR_help_BCU_Contacts_URL;
    }
    else {
      return environment.PCR_help_LA_dashboard_URL;
    }
    
  }

  navbarClass(route: string){
    if (route ===  this._urlHelperService.BcuUserDashboardUrl()){
      return 'mx-sm-4 mx-2';
    }
    else{
      return 'container';
    }
  }
}
