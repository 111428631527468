<div class="pt-50 local-auth-btn ">
  <div class="container">
    <div class="row">
      <!-- <div class="col-12 col-sm-3 col-lg-2 col-xl-2">
        <div class="option-card bg_blue ">
          <a [routerLink]="['/new-incident']" 
            class="title-icon text-white d-flex flex-wrap flex-wrap">
            <h3>Start<br>
              incident</h3>
          </a>
        </div>
      </div> -->
      
      <div class="col-12 col-sm-3 col-lg-2 col-xl-2">
        <div class="option-card  border-btn d-flex option-dropdown">  <!--[routerLinkActive]="['bg_gradient']"-->
          <span class="title-icon">
            <h3>Team</h3>
          </span>
          <div class="dropdown">
            <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown"
              aria-expanded="false"></button>
            <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
              <!-- <li><a [routerLink]="['/initial-incident-form',0,'StartIncidentTab']">Incidents</a></li> -->
              <!-- <li *ngIf="teamMemberLoaded"><a [routerLink]="'/team-members'">Team</a></li>
              <li *ngIf="homeDashboardLoaded || teamMemberLoaded"><a [routerLink]="'/team-messaging-dashboard'">Messaging</a></li>
              <li *ngIf="homeDashboardLoaded || teamMemberLoaded"><a [routerLink]="'/add-team-member'">Add, remove, archive</a></li> -->

              <li ><a [routerLink]="'/team-members'">Team</a></li>
              <li><a [routerLink]="'/team-messaging-dashboard'">Messaging</a></li>
              <li><span class="showHand" (click)="AddTeamClicked()">Add team member</span></li>
              <!-- <li><a [routerLink]="['/team-messaging-dashboard']">Messaging</a></li>
              <li><a href="#">Add, remove, archive</a></li> -->

            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-3 col-lg-2 col-xl-2">
        <div class="option-card  border-btn d-flex option-dropdown"> <!--[routerLinkActive]="['bg_gradient']"-->
          <span [routerLink]="[_urlHelperService.LaUserDashboardUrl()]" class="title-icon ">
            <h3>Stakeholders</h3>
          </span>
          <div class="dropdown">
            <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown"
              aria-expanded="false"></button>
            <ul class="dropdown-menu" aria-labelledby="incidentdropdown">
               <!-- <li><a [routerLink]="['/initial-incident-form',0,'StartIncidentTab']">Incidents</a></li> -->

               <li ><a [routerLink]="'/stakeholder-members'">Stakeholders</a></li>
              <li><a [routerLink]="'/stakeholder-messaging'">Messaging </a></li>
              <li><span class="showHand" (click)="AddStakeholderClicked()">Add Stakeholder</span></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-4 col-lg-3 col-xl-2">
        <div class="option-card border-btn d-flex option-dropdown">
          <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]" class="title-icon d-flex flex-wrap align-items-start">
            <h3>Incidents</h3>
          </a>
          <div class="dropdown">
            <button class="btn dropdown-toggle" type="button" id="incidentdropdown" data-bs-toggle="dropdown"
              aria-expanded="false"></button>
            <ul class="dropdown-menu" aria-labelledby="incidentdropdown" style="">
              <li><a [routerLink]="['/new-incident']">Start Incident </a></li>
              <li><a [routerLink]="['/cst-template-create']">Create Template </a></li>
              <!-- <li><a [routerLink]="['/cst-template']">View templates</a></li> -->
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
