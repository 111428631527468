import { AddStakeholderComponent } from './../add-stakeholder/add-stakeholder.component';
import { StakeholderMembersService } from './stakeholder-members.service';
import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NavbarService } from "src/app/nav-menu/navbar.service";
import { MemberProfile } from 'src/app/Models/Members/MemberProfile';
import { Stakeholder } from 'src/app/Models/Stakeholder/Stakeholder';
import { MatDialog } from '@angular/material/dialog';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';

@Component({
  selector: "app-stakeholder-members",
  templateUrl: "./stakeholder-members.component.html",
  styleUrls: ["./stakeholder-members.component.css"],
})
export class StakeholderMembersComponent implements OnInit {
  stakeHolderMemberForm: FormGroup;
  isSubmitted: boolean;

  incidentId: string;
  isTeamMembersLoaded = false;
  stakeholders: Stakeholder[] = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private toastr: ToastrService,
    private _stakeholderMembersService: StakeholderMembersService,
    private dialog: MatDialog,
    private _apiErrorService: ApiErrorService,
    public _urlHelperService: UrlHelperService
  ) {}

  ngOnInit(): void {
    
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    //this.InitForm();

    this._activatedRoute.params.subscribe((param) => {
      this.incidentId = param["id"];
    });

    // this.emergencyPlanningService.GetTeamMembersForIncident(this.incidentId).subscribe(response => {
    //   if (response && response.data && response.statusCode == 200){
    //     this.isTeamMembersLoaded = true;
    //     this.teamMemebers = response.data;
    //     //console.log(response.data);
    //   }
    // }, err => {
    //   console.log("Error:" + JSON.stringify(err));
    //   this.toastr.error(err?.error?.message, "Error");
    // });

    this._stakeholderMembersService.GetStakeHolderMembers().subscribe(response => {
      if (response && response.data && response.statusCode == 200){
        this.isTeamMembersLoaded = true;
        this.stakeholders = response.data;
        console.log(response.data);
      }
    }, 
    (error) => {
      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this._apiErrorService.ShowErrorModal("Error", error.error.message);
        //this.toastr.error(error.error.message, "Error");
      }
      else{
        this._apiErrorService.ShowErrorModal();
        //this.toastr.error("Something went wrong!");
      }
    });

  }

  stackHolderEditClicked(memberProfile: Stakeholder) {
    this.dialog.open(AddStakeholderComponent, {
      data: { memberProfile: memberProfile },
    });
  }

  // InitForm() {
  //   this.stakeHolderMemberForm = this._fb.group({
  //     firstName: [null, Validators.required],
  //     givenName: [null, Validators.required],
  //     role: [null, Validators.required],
  //     employer: [null, Validators.required],
  //     phone: [
  //       null,
  //       [Validators.required, Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")],
  //     ],
  //     email: [null, [Validators.required, Validators.email]],
  //     alertPreferences: [null, Validators.required],
  //   });
  // }

  // onSubmit() {
  //   this.isSubmitted = true;
  // }
}
