import { StakeholderMessageSendComponent } from './../../overlays/stakeholder-message-send/stakeholder-message-send.component';
import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LATeamMessagingDashboardDTO } from 'src/app/Models/Stakeholder/LATeamMessagingDashboardDTO';
import { StakeholderService } from '../stakeholder.service';
import { LATeamMessage, ToInformation } from 'src/app/Models/la-Team-messages/LATeamMessagingDashboard';
import { IncidentMessageComponent } from 'src/app/overlays/incident-message/incident-message.component';
import { ErrorConfirmationBoxComponent } from 'src/app/overlays/error-confirmation-box/error-confirmation-box.component';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { Title } from '@angular/platform-browser';
import { NavbarService } from 'src/app/nav-menu/navbar.service';
import { ActivatedRoute } from '@angular/router';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';

@Component({
  selector: 'app-stakeholder-messaging',
  templateUrl: './stakeholder-messaging.component.html',
  styleUrls: ['./stakeholder-messaging.component.css']
})
export class StakeholderMessagingComponent implements OnInit {

  incidentMessagesData : LATeamMessagingDashboardDTO[] = [];
  isLoading: boolean = true;

  constructor(
    private _stakeholderService: StakeholderService,
    private _toastr: ToastrService,
    private _dialog: MatDialog,
    private _apiErrorService: ApiErrorService,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute,
    public _urlHelperService: UrlHelperService
    ) { }

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    this.GetStakeHolderDashBoard();
  }

  GetStakeHolderDashBoard(){
    this._stakeholderService.GetStakeHolderDashBoard().pipe(tap(x => this.isLoading = false)).subscribe(response => {
      if (response == null || response == undefined || response.statusCode !== 200 || response.data == null || response.data == undefined){
        this._toastr.error('Something went wrong');
      }

      this.incidentMessagesData = this._stakeholderService.SortIncidents(response.data);
      console.log(response);
    }, 
    (error) => {
      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this._apiErrorService.ShowErrorModal("Error", error.error.message);
        //this._toastr.error(error.error.message, "Error");
      }
      else{
        this._apiErrorService.ShowErrorModal();
        //this._toastr.error("Something went wrong!");
      }
    }
    );
  }


  sendMessageClicked(messageType: string, incidentId: string) {
    if (messageType == undefined || incidentId == undefined){
      return;
    }
    let messageData = {messageType : messageType, incidentId : incidentId}
    this._dialog.open(StakeholderMessageSendComponent, {
      minHeight: '100px',
      minWidth : '100px',
      data: { messageData: messageData }
    }).afterClosed().subscribe(result => {
      
      if (result == null || result == undefined){
        return;
      }
      
      this.isLoading = true;

      const messageQueueDTO = result;

      this._dialog.open(ErrorConfirmationBoxComponent, {
        width: 'auto',
        panelClass: 'custom-dialog-container',
        data: {tital: "Message sent", body: "Your message has been sent"}
      });

      const stakeHolderMessageIncidentIndex = this.incidentMessagesData.findIndex(x => x.incidentId == incidentId);
      if (stakeHolderMessageIncidentIndex != -1){
        let laTeamMessage = new LATeamMessage();
        laTeamMessage.content = messageQueueDTO.message;
        laTeamMessage.from = messageQueueDTO.fromInfo;
        laTeamMessage.sent = new Date();
        laTeamMessage.subject = messageQueueDTO.subject;
        laTeamMessage.to = messageQueueDTO.toInfo;
        laTeamMessage.type = messageQueueDTO.deliveryChannel?.toString();
        this.incidentMessagesData[stakeHolderMessageIncidentIndex].messages.push(laTeamMessage);
      }

      this.isLoading = false;

      // this.isLoading = true;
      // this.GetStakeHolderDashBoard();
    });
  }

  IncidentMessageClicked(message: LATeamMessage) {
    this._dialog.open(IncidentMessageComponent, {
      data: { messageInfo: message }
    });
  }


  messageToStringBuilder(stringArray: ToInformation[]) {
    if (!stringArray || stringArray.length <= 0){
      return;
    }
    let result: string = "";

    for (const to of stringArray) {
      if (!to || to.to == undefined){
        continue;
      }
      result += to.to + ", ";
    }

    if (result.charAt(result.length - 1) == ",") {
      result = result.slice(0, -1);
    }
    
    return result;
  }



}
