import { LADashboardContactsDTO } from './../../Models/LAContacts/LADashboardContactsDTO';
import {
  LaDashboardUserActionsModel,
  LAIncedentsModel,
  LAIncedentsUserActionsModel,
  LAIncidentDashboardModel,
} from "./../../Models/LAIncedenceDashboard/LAIncidentDashboardModel";
import { HomeDashboardService } from "./home-dashboard.service";
import { LoadScriptService } from "./../load-script.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { AuthenticatedUserForGrouping } from 'src/app/Models/Members/AuthenticatedUserGroups';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { BCURegionDTO } from 'src/app/Models/BCURegionDTO.Model';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: "app-home-dashboard",
  templateUrl: "./home-dashboard.component.html",
  styleUrls: ["./home-dashboard.component.css"],
})
export class HomeDashboardComponent implements OnInit, OnDestroy {
  showIncidenceDetail: boolean = false;
  isDataLoading = true;
  currentlyLoggedInUserId : string;

  isdashBoardIncedenceDataLoading: boolean = true;
  dashBoardIncedenceData: LAIncidentDashboardModel;

  // isdashboardUserActionsDataLoading: boolean = true;
  // dashboardUserActionsData: LaDashboardUserActionsModel;

  isdashboardActionsDataLoading: boolean = true;
  dashboardActionsData: LaDashboardUserActionsModel;

  isdashboardTeamActionsDataLoading: boolean = true;
  dashboardTeamActionsData: LaDashboardUserActionsModel;

  dashBoardContacts: LADashboardContactsDTO[] = [];
  isDashboardContactLoading = true;

  usersRegionsAndGroups: AuthenticatedUserForGrouping;
  selectedRegion: BCURegionDTO;

  dataLoadedEventSubscription: Subscription;
  dataLoadedEvent = new Subject<boolean>();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _router: Router,
    private _loadScriptSerivice: LoadScriptService,
    private _homeDashboardService: HomeDashboardService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    private _userProfileService: UserProfileService
  ) {

    //this._cognitoService.GetUser().then((response) => console.log("from home component")).catch((err) => console.log(err));

    //Auth.currentAuthenticatedUser().then(() => console.log('asdas')).catch((err) => console.log(err));

      // _cognitoService.GetUser()
      // .then(user => {
         
      // })
      // .catch(() => {
      //   //this._router.navigate(['sign-in'])
      // });
    }

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    this.dataLoadedEventSubscription = this.dataLoadedEvent.subscribe((x) => {
      if (x) {
        let timerId = setInterval(() => {
          if (!this.isDataLoading){
            if (this._loadScriptSerivice.ShouldToggleScriptLoad()){
              this._loadScriptSerivice.loadScript("home-dashboardScriptTag");
              clearInterval(timerId);
            }
          }
        }, 100);
      }
    });

    // this._cognitoService
    //   .GetUser()
    //   .then((user) => {
    //     //console.log(user);
    //     if (user != null && user != undefined) {
    //       this.currentlyLoggedInUserId = user.username;
    //     }
    //   })
    //   .catch(() => {
    //     this._router.navigate(['sign-in'])
    //   });

    let timerId = setInterval(() => {
      const userWithRegion = this._userProfileService.GetAuthenticatedUserGroups();
      if (userWithRegion){
        this.usersRegionsAndGroups =  userWithRegion;
        if (this.usersRegionsAndGroups && this.usersRegionsAndGroups.region) {
          this.selectedRegion = this.usersRegionsAndGroups.region;
        }

        this.currentlyLoggedInUserId = this.usersRegionsAndGroups.id;
        if (this.selectedRegion){
          clearInterval(timerId);
        }
      }
    }, 100);

    this._homeDashboardService.loadAlldata().subscribe(
      (response) => {
        this.loadingAllData(response);
      },
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          // this.toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          // this.toastr.error("Something went wrong!");
        }
      }
    );
  }

  loadingAllData(response) {
    if (response != null && response != undefined && response.length > 0){

      if (response[0] != null && response[0] != undefined && response[0].statusCode == 200){
        //console.log(response[0]);
        this.dashBoardIncedenceData = response[0].data as LAIncidentDashboardModel;
        const insidents = this.dashBoardIncedenceData.incidents.filter(x => x.incidentStatus == 0 || x.incidentStatus == 1 || x.incidentStatus == 2);
        //console.log(insidents.filter(x => x.incidentStatus == 2));
        // const incidentsToBePushed = insidents.map((incident) => {
        //   if (incident.incidentStatus == 2){
        //     incident.incidentStatus = 1
        //   }
        //   return incident;
        // });
        this.dashBoardIncedenceData.incidents = this._homeDashboardService.SortIncedenceData(insidents);
        this.isdashBoardIncedenceDataLoading = false;

        //console.log(this.dashBoardIncedenceData.incidents.filter(x => x.incidentStatus == 2));
      }
      
      if (response[1] != null && response[1] != undefined && response[1].statusCode == 200){
        //console.log(response[1]);
        this.dashboardActionsData = response[1].data as LaDashboardUserActionsModel;
        
        const incidentsToBeAssign =  this.dashboardActionsData.incidentTotals.map((incident) => {
          if (incident.incidentStatus == 2){
            incident.incidentStatus = 1
          }
          return incident;
        });

        this.dashboardActionsData.incidentTotals = this._homeDashboardService.sortYourActionArrayBasedOnDateTime(incidentsToBeAssign);
        //this.dashboardActionsData.incidentTotals = incidentsToBeAssign;

        this.isdashboardActionsDataLoading = false;
      }

      if (response[2] != null && response[2] != undefined && response[2].statusCode == 200){
        this.dashboardTeamActionsData = response[2].data as LaDashboardUserActionsModel;

        const incidentsToBeAssign =  this.dashboardTeamActionsData.incidentTotals.map((incident) => {
          if (incident.incidentStatus == 2){
            incident.incidentStatus = 1
          }
          return incident;
        });

        this.dashboardTeamActionsData.incidentTotals = this._homeDashboardService.sortYourActionArrayBasedOnDateTime(incidentsToBeAssign);
        //this.dashboardTeamActionsData.incidentTotals = incidentsToBeAssign

        this.isdashboardTeamActionsDataLoading = false;
         //console.log(response[2]);

      }

      if (response[3] != null && response[3] != undefined && response[3].statusCode == 200){
         this.dashBoardContacts =  response[3].data as LADashboardContactsDTO[];
        this.isDashboardContactLoading = false;
        //console.log(response[3]);
      }

      this.isDataLoading = false;
      this.dataLoadedEvent.next(true);
    }
  }

  // loadingAllData(){
  //   let getDashBoardPromis = this._homeDashboardService.getDashBoard().subscribe(response => {
  //     if (response!= null && response != undefined && response.statusCode == 200) {
  //       this.dashBoardIncedenceData = response.data as LAIncidentDashboardModel;
  //       this.dashBoardIncedenceData.incidents = this._homeDashboardService.SortIncedenceData(this.dashBoardIncedenceData.incidents);
  //       this.isdashBoardIncedenceDataLoading = false;
  //     }
  //   }, (error => {
  //     console.log('Error:' +JSON.stringify(error));
  //     this.toastr.error(error.error.message, 'Error');
  //   }));

  //   let getDashboardUserActionsPromis = this._homeDashboardService.getDashboardUserActions().subscribe(response => {
  //     if (response!= null && response != undefined && response.statusCode == 200){
  //       this.dashboardUserActionsData = response.data as LaDashboardUserActionsModel;
  //       this.isdashboardUserActionsDataLoading = false;
  //     }
  //   }, (error => {
  //     console.log('Error:' +JSON.stringify(error));
  //     this.toastr.error(error.error.message, 'Error');
  //   }));

  //   let getDashboardTeamActionsPromis = this._homeDashboardService.getDashboardTeamActions().subscribe(response => {
  //     if (response!= null && response != undefined && response.statusCode == 200){
  //       this.dashboardTeamActionsData = response.data as LaDashboardUserActionsModel;
  //       this.isdashboardTeamActionsDataLoading = false;
  //     }
  //   }, (error => {
  //     console.log('Error:' +JSON.stringify(error));
  //     this.toastr.error(error.error.message, 'Error');
  //   }));

  //   let promis4 = this._homeDashboardService.getIncedentTask().subscribe(response => {
  //     if (response!= null && response != undefined && response.statusCode == 200){
  //       // this.dashboardTeamActionsData = response.data as LaDashboardUserActionsModel;
  //       // this.isdashboardTeamActionsDataLoading = false;
  //     }
  //   }, (error => {
  //     console.log('Error:' +JSON.stringify(error));
  //     this.toastr.error(error.error.message, 'Error');
  //   }));

  //   // Promise.all([getDashBoardPromis, getDashboardUserActionsPromis, getDashboardTeamActionsPromis])
  //   // .then(results => {
  //   //   // This function will be called when all three API calls have completed
  //   //   this.isDataLoading = false;
  //   //   this._loadScriptSerivice.loadScript("home-dashboardScriptTag");
  //   // });

  // }

  dashBoardIncedenceTrClassResolver(incedenceStatus: number): string {
    return this._homeDashboardService.dashBoardIncedenceTrClassResolver(incedenceStatus);
  }

  yourActionTrClicked(category: string, incident: LAIncedentsUserActionsModel) {
    // if (incident.id != '1FBEE8CB-A5F2-4BCA-E563-08DACF0DF750'){
    //   return;
    // }
     this._router.navigate(["/immediate-response", incident.id], {
      queryParams: {
        category: category,
        member: this.currentlyLoggedInUserId
      }});
    //this._router.navigate(["/immediate-response", incident.id]);
  }

  yourTeamTitalClicked(id: string) {
    // this._router.navigate(["/protocol-actions-post-protocol-launch"]);
    this._router.navigate([`/incident-detail/${id}`]);
    // this._router.navigate(["/incident-summary-pre-protocol-launch", id]); 
  }

  incidentSummaryPreProtocolLaunchClicked(id: string) {
    this._router.navigate([
      "/incident-detail", id
    ]);
  }

  YourTeamRowDiscClicked(category: string, id: string){
    this._router.navigate([
      `/cst-protocol/${id}`
    ], {
      queryParams: {
        category: category,
        //member: this.currentlyLoggedInUserId
      }});
  }

  IncidentsPendingTagClicked(incident: LAIncedentsModel){
    this._router.navigate([
      `/incident-detail/${incident.id}`
    ]);
  }


  IncidentsEmergencyTagClicked(incident: LAIncedentsModel){
    this._router.navigate([
      `/immediate-response/${incident.id}`
    ]);
  }

  IncidentsCSTTagClicked(incident: LAIncedentsModel){
    this._router.navigate([
      `/cst-protocol/${incident.id}`
    ]);
  }

  YourActionsEmergencyClicked(incident : LAIncedentsUserActionsModel){
    this._router.navigate([
      `/immediate-response/${incident.id}`
    ], {
      queryParams: {
        member: this.currentlyLoggedInUserId
      }});
  }

  YourActionsCSTClicked(incident : LAIncedentsUserActionsModel){
    this._router.navigate([
      `/cst-protocol/${incident.id}`
    ], {
      queryParams: {
        member: this.currentlyLoggedInUserId
      }});
  }

  YourTeamsActionsEmergencyClicked(incident : LAIncedentsUserActionsModel){
    this._router.navigate([
      `/immediate-response/${incident.id}`
    ]);
  }

  YourTeamsActionsCSTClicked(incident : LAIncedentsUserActionsModel){
    this._router.navigate([
      `/cst-protocol/${incident.id}`
    ]);
  }


  ngOnDestroy(): void {
    this._loadScriptSerivice.removeScript("home-dashboardScriptTag");

    if (this.dataLoadedEventSubscription){
      this.dataLoadedEventSubscription.unsubscribe();
    }
  }

}
