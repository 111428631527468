import { Subscription } from "rxjs";
import { AddTeamMemberService } from "./../add-team-member/add-team-member.service";
import { MatDialog } from "@angular/material/dialog";
import { OnDestroy } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TeamMembersService } from "./team-members.service";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { MemberProfile } from "src/app/Models/Members/MemberProfile";
import { AddTeamMemberComponent } from "../add-team-member/add-team-member.component";
import { UserRole } from "src/app/Enum/UserRole.enum";
import { LATeamMember } from "src/app/Models/Team-member/LATeamMemberDTO";
import { ApiErrorService } from "src/app/core/services/api-error.service";
import { UrlHelperService } from "src/app/core/services/url-helper.service";
import { UserProfileService } from "src/app/core/services/user-profile.service";

@Component({
  selector: "app-team-members",
  templateUrl: "./team-members.component.html",
  styleUrls: ["./team-members.component.css"],
})
export class TeamMembersComponent implements OnInit, OnDestroy {
  // teamMemberForm: FormGroup;
  // isSubmitted: boolean;
  teamMembers: MemberProfile[];
  isMembersLoading: boolean = true;
  teamMemberSubjectSubscription: Subscription;
  

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _teamMembersService: TeamMembersService,
    private _toastr: ToastrService,
    private _dialog: MatDialog,
    private _addTeamMemberSevice: AddTeamMemberService,
    private _apiErrorService: ApiErrorService,
    public _urlHelperService: UrlHelperService,
    private _userProfileService: UserProfileService,
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    this.teamMemberSubjectSubscription =
      this._addTeamMemberSevice.teamMemberSubject.subscribe((teamMember) => {
        let newTeamMember = new MemberProfile();
        newTeamMember.employer = teamMember[0].employer;
        newTeamMember.familyName = teamMember[0].familyName;
        //newTeamMember.Groups = teamMember[0].groups;
        newTeamMember.id = teamMember[0].id;
        newTeamMember.IsEmailVerified = teamMember[0].isEmailVerified;
        newTeamMember.IsPhoneNoVerified = teamMember[0].isPhoneNoVerified;
        newTeamMember.jobTitle = teamMember[0].jobTitle;
        newTeamMember.MemberCategoryId = teamMember[0].MemberCategoryId;
        newTeamMember.memberType = teamMember[0].memberType;
        //newTeamMember.Statutory = teamMember[0].Statutory;
        //newTeamMember.User = teamMember[0].User;
        newTeamMember.avatar = teamMember[0].avatar;
        newTeamMember.dashboardContact = teamMember[0].dashboardContact;
        newTeamMember.email = teamMember[0].email;
        newTeamMember.firstName = teamMember[0].firstName;
        newTeamMember.givenName = teamMember[0].familyName;
        newTeamMember.incidentAlert = teamMember[0].incidentAlert;
        newTeamMember.incidentLaunchIfNotTeam =
        teamMember[0].IncidentLaunchIfNotTeam;
        newTeamMember.locationAlert = teamMember[0].locationAlert;
        //newTeamMember.organisationGroupid = teamMember[0].organisationGroupid;
        newTeamMember.partnerMessage = teamMember[0].PartnerMessage;
        newTeamMember.phone = teamMember[0].phoneNo;
        newTeamMember.role = UserRole[+teamMember[0].roles];
        newTeamMember.section35Alert = teamMember[0].section35Alert;
        newTeamMember.section60Alert = teamMember[0].section60Alert;

        if (teamMember[1]) {
          this.teamMembers.map((item, index) => {
            if (item.id === newTeamMember.id) {
              this.teamMembers.splice(index, 1, newTeamMember);
            }
          });
        } else {
          this.teamMembers.push(newTeamMember);
        }
      });

    this._teamMembersService.GetTeamMembersDashboard().subscribe(
      (response) => {
        if (
          response == undefined ||
          response == null ||
          response.data == undefined ||
          response.data == null ||
          response.statusCode != 200
        ) {
          this._toastr.error("Something went wrong");
          return;
        }

        this.teamMembers = response.data;
        this.isMembersLoading = false;
        console.log(response);
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      }
    );
  }

  // InitForm() {
  //   this.teamMemberForm = this._fb.group({
  //     firstName: [null, Validators.required],
  //     givenName: [null, Validators.required],
  //     role: [null, Validators.required],
  //     employer: [null, Validators.required],
  //     phone: [null, [Validators.required, Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]],
  //     email: [null, [Validators.required, Validators.email]],
  //     alertPreferences: [null, Validators.required]
  //   });
  // }

  // onSubmit(){
  //   this.isSubmitted = true;
  // }

  teamMemberEditClicked(memberProfile: MemberProfile) {
    this._dialog.open(AddTeamMemberComponent, {
      data: { memberProfile: memberProfile },
    });
  }

  GrayRoleIconVisibility(role: string): boolean {
    if (
      [UserRole.SystemAdmin, UserRole.SuperAdmin, UserRole.GroupAdmin].includes(
        UserRole[role]
      )
    ) {
      return true;
    }
    return false;
  }


  teamMemeberCheckboxChnaged(checkboxName: string, memberProfile : MemberProfile, event: Event, innerIndex: number){
    const updateMemberSpinner = document.getElementById('update-member-spinner' + innerIndex);
    const editButtonDiv = document.getElementById('update-member-spinner' + innerIndex);
    if (updateMemberSpinner){
      document.getElementById('update-member-spinner' + innerIndex).style.display = 'block';
    }
    if (editButtonDiv){
      document.getElementById('edit-button-div' + innerIndex).style.display = 'none';
    }
    

    console.log(event);
    const checkbox = event.target as HTMLInputElement;
    if (checkboxName === 'incidentAlert'){
      memberProfile.incidentAlert = checkbox.checked;
    }
    else if (checkboxName === 'locationAlert'){
      memberProfile.locationAlert = checkbox.checked;
    }
    else if (checkboxName === 'section60Alert'){
      memberProfile.section60Alert = checkbox.checked;
    }
    else if (checkboxName === 'section35Alert'){
      memberProfile.section35Alert = checkbox.checked;
    }
    else if (checkboxName === 'partnerMessage'){
      memberProfile.partnerMessage = checkbox.checked;
    }
    else if (checkboxName === 'incidentLaunchIfNotTeam'){
      memberProfile.incidentLaunchIfNotTeam = checkbox.checked;
    }
    else if (checkboxName === 'dashboardContact'){
      memberProfile.dashboardContact = checkbox.checked;
    }

    if (memberProfile.id == null || memberProfile.id == undefined || memberProfile.id == ''){
      return;
    }

    let teamMember = new LATeamMember();
    teamMember.familyName = memberProfile.givenName;
    teamMember.firstName = memberProfile.firstName;
    teamMember.email = memberProfile.email;
    teamMember.phoneNo = memberProfile.phone;
    teamMember.employer = memberProfile.employer;
    teamMember.memberType = memberProfile.memberType;
    teamMember.roles = UserRole[memberProfile.role];
    teamMember.incidentAlert = memberProfile.incidentAlert;
    teamMember.locationAlert = memberProfile.locationAlert;
    teamMember.PartnerMessage = memberProfile.partnerMessage;
    teamMember.section60Alert = memberProfile.section60Alert;
    teamMember.section35Alert = memberProfile.section35Alert;
    teamMember.jobTitle = memberProfile.jobTitle;
    teamMember.IncidentLaunchIfNotTeam = memberProfile.incidentLaunchIfNotTeam;
    teamMember.avatar = memberProfile.avatar;
    teamMember.dashboardContact = memberProfile.dashboardContact;
    teamMember.groups = this._userProfileService.GetAuthenticatedUserGroup()?.organizationGroupId;
    teamMember.MemberCategoryId = memberProfile.MemberCategoryId;
    teamMember.id = memberProfile.id;

    this._addTeamMemberSevice.EditTeamMember(teamMember).subscribe(x => {
      this._addTeamMemberSevice.teamMemberSubject.next([teamMember, true]);

      const updateMemberSpinner = document.getElementById('update-member-spinner' + innerIndex);
      const editButtonDiv = document.getElementById('update-member-spinner' + innerIndex);
      if (updateMemberSpinner){
        document.getElementById('update-member-spinner' + innerIndex).style.display = 'none';
      }
      if (editButtonDiv){
        document.getElementById('edit-button-div' + innerIndex).style.display = 'block';
      }
    
    }, 
    (error) => {

      checkbox.checked = !checkbox.checked;

      const updateMemberSpinner = document.getElementById('update-member-spinner' + innerIndex);
      const editButtonDiv = document.getElementById('update-member-spinner' + innerIndex);
      if (updateMemberSpinner){
        document.getElementById('update-member-spinner' + innerIndex).style.display = 'none';
      }
      if (editButtonDiv){
        document.getElementById('edit-button-div' + innerIndex).style.display = 'block';
      }
      
      this._apiErrorService.ShowError(error);

    });
  }

  ngOnDestroy() {
    if (this.teamMemberSubjectSubscription){
      this.teamMemberSubjectSubscription.unsubscribe();
    }
  }
}
