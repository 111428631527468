import { LoadScriptService } from './../home-dashboard/load-script.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Accordion, CommonType, CreateIncidentDTO, IncidentDTO, Markers, MarkersForIncidentDetails, OrganizationGroup } from '../Models/incident.modal';
import { IncidentService } from '../initial-incident-form/incident.service';
import { BCURegionDTO } from '../Models/BCURegionDTO.Model';
import { NavbarService } from '../nav-menu/navbar.service';
import { IncidentFormLocationEntity } from '../Models/IncidentFormLocationEntity';
import { ApiErrorService } from '../core/services/api-error.service';
import { UrlHelperService } from '../core/services/url-helper.service';
import { DateService } from '../core/services/date.service';

@Component({
  selector: 'app-incident-summary-pre-protocol-launch',
  templateUrl: './incident-summary-pre-protocol-launch.component.html',
  styleUrls: ['./incident-summary-pre-protocol-launch.component.css']
})
export class IncidentSummaryPreProtocolLaunchComponent implements OnInit {

//#region  Incident Tab
IncidentDTO: IncidentDTO = new IncidentDTO();
CreateIncidentDTO: CreateIncidentDTO = new CreateIncidentDTO();
Accordion: Accordion = new Accordion();
incidentForm: NgForm;
incidentIsSubmit: boolean = false;
Days: CommonType[] = []
Months: CommonType[] = []
Years: CommonType[] = []
OrganizationGroup: OrganizationGroup[] = []
subOrganizationGroup: OrganizationGroup[] = []
lng: any;
lat: any;
markers: Markers[] = []
IncidentMapShow: boolean = false;
loadIncident: boolean = false;
// google maps zoom level
zoom: number = 14;
scroll: boolean = false;
BCURegionDTO: BCURegionDTO = new BCURegionDTO();
IsPolicecordon: boolean = false;
IsCrimescene: boolean = false;
IsRendezvouspoint: boolean = false;
MapShow: boolean = false;
//#region  Mapshow
LocationMainmarkers: MarkersForIncidentDetails[] = []
LocationMainmarkersFirst: MarkersForIncidentDetails[] = []
LocationMainmarkersSecond: MarkersForIncidentDetails[] = []

shouldIncidentMarkerVisible = true;
shouldIncidentLocationShow = true;
//#endregion
//#endregion


CrimeSceneLocations: IncidentFormLocationEntity[] = [];
  PoliceCordonLocations: IncidentFormLocationEntity[] = [];
  RendezvousPointLocations: IncidentFormLocationEntity[] = [];
  Section60Locations: IncidentFormLocationEntity[] = [];
  Section35Locations: IncidentFormLocationEntity[] = [];

  CrimeSceneLocationsToShow: IncidentFormLocationEntity[] = [];
  PoliceCordonLocationsToShow: IncidentFormLocationEntity[] = [];
  RendezvousPointLocationsToShow: IncidentFormLocationEntity[] = [];
  Section60LocationsToShow: IncidentFormLocationEntity[] = [];
  Section35LocationsToShow: IncidentFormLocationEntity[] = [];

  MainLocationPolygon: { id: string, path: any; color: string, name: string }[] = [];
  MainLocationPolygonToShow: { id: string, path: any; color: string, name: string }[] = [];

  MainLocationRVPMarker: { id: string, path: any; color: string, name: string, icon: string, label: string, lat: number, lng: number }[] = [];
  MainLocationRVPMarkerToShow: { id: string, path: any; color: string, name: string, icon: string, label: string, lat: number, lng: number }[] = [];

  shouldMapShow: boolean = true;

constructor(
  private _incidentService: IncidentService, 
  private _activatedRoute: ActivatedRoute, 
  private _toastr: ToastrService, 
  private _titleService: Title,
  private _loadScriptService: LoadScriptService,
  private _router: Router,
  private _apiErrorService: ApiErrorService,
  private _dateService: DateService,
  public _urlHelperService: UrlHelperService
  ) {
    
  if (this._activatedRoute.snapshot.paramMap != null && this._activatedRoute.snapshot.paramMap.get('id') != null) {
    this.IncidentDTO.incidentid = this._activatedRoute.snapshot.paramMap.get('id');
    if (this.IncidentDTO.incidentid != null && this.IncidentDTO.incidentid != undefined) {
      this.getIncidentDetails(this.IncidentDTO.incidentid);
      
    }
  } else {
    this.IncidentDTO.incidentid = '0';
  }
}

ngOnInit(): void {
  this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
  this._loadScriptService.loadScript('incident-summary-pre-protocolScriptTag');
  
}


getIncidentDetails(incidentid) {
  const self = this;
  self.loadIncident = true;
  self._incidentService.getIncidentDetails(incidentid).pipe(finalize(() => {
    self.loadIncident = false;
  })).subscribe(res => {
    console.log(res);
    this._loadScriptService.loadScript('jquerySliderScriptTag', 'jquerySlider.js');
    if (res != null && res.statusCode == 200) {
      this.IncidentDTO = res.data;
      //const dateUTC = new Date(this.IncidentDTO.incidentDate);
      this.IncidentDTO.incidentDate = this._dateService.RetrieveDate(this.IncidentDTO.incidentDate);
      console.log(this.IncidentDTO);
      if (this.IncidentDTO.latitude != null && this.IncidentDTO.longitude){
        this.lat = +this.IncidentDTO.latitude;
        this.lng = +this.IncidentDTO.longitude;
      }
      if (res.data.bcuLocationData?.locations || res.data.section60 || res.data.section35){
        this.GetAllLocationDetails(res.data.bcuLocationData?.locations, res.data.section60, res.data.section35);
      }

      // this._incidentService.getLocationDetails(this.IncidentDTO.bcuLocationData.id).subscribe(response => {
      //   console.log(response);
      // });



      if (this.IncidentDTO != null && this.IncidentDTO.bcuLocationData != null) {
        if (this.IncidentDTO.bcuLocationData.locations != null && this.IncidentDTO.bcuLocationData.locations.length > 0) {
          let Policecordon = this.IncidentDTO.bcuLocationData.locations.filter(x => x.type == 0)//Police cordon
          if (Policecordon != null && Policecordon.length > 0) {
            this.IsPolicecordon = true;
          }
          let Rendezvouspoint = this.IncidentDTO.bcuLocationData.locations.filter(x => x.type == 1)//Rendezvous point
          if (Rendezvouspoint != null && Rendezvouspoint.length > 0) {
            this.IsRendezvouspoint = true;
          }
          let Crimescene = this.IncidentDTO.bcuLocationData.locations.filter(x => x.type == 2)//Crime scene
          if (Crimescene != null && Crimescene.length > 0) {
            this.IsCrimescene = true;
          }

        }
        if (this.IncidentDTO.latitude != null && this.IncidentDTO.longitude) {
          this.lat = +this.IncidentDTO.latitude;
          this.lng = +this.IncidentDTO.longitude;
          this.LocationMainmarkers.push({
            lat: Number(this.IncidentDTO.latitude),
            lng: Number(this.IncidentDTO.longitude),
            label: 'Incident location',
            visible: true,
            opacity: 0.7,
            icon: '../../assets/images/red-marker.png',
            ispolygon: false,
            path: []
          })


        }
        if (this.IncidentDTO.bcuLocationData != null && this.IncidentDTO.bcuLocationData.locations != null) {
          let indexPolicecordon = 1;
          let indexRendezvouspoint = 1;
          let indexCrimescene = 1;
          this.IncidentDTO.bcuLocationData.locations.forEach(element => {
            switch (element.type) {
              case 0://Police cordon //Polygoan
                if (this.lat == undefined || this.lat == null || this.lng == undefined || this.lng == null){
                  let latArray : Array<number> = [];
                  let lngArray : Array<number> = [];
                  for (const iterator of element.locationPoints) {
                    latArray.push(iterator.lat);
                    lngArray.push(iterator.lng);
                  }
                  this.lat= Math.max(...latArray); 
                  this.lng= Math.max(...lngArray);
                }  

                this.LocationMainmarkers.push({
                  lat: 0,
                  lng: 0,
                  label: 'Police cordon ' + indexPolicecordon,
                  visible: true,
                  opacity: 0.7,
                  icon: '../../assets/images/circle-lightblue.png',
                  ispolygon: true,
                  path: element.locationPoints,
                  color: '#80cff4'
                })
                indexPolicecordon++;
                break;
              case 1://Rendezvous point  //drop pin
                if (this.lat == undefined || this.lat == null || this.lng == undefined || this.lng == null){
                  let latArray : Array<number> = [];
                  let lngArray : Array<number> = [];
                  for (const iterator of element.locationPoints) {
                    latArray.push(iterator.lat);
                    lngArray.push(iterator.lng);
                  }
                  this.lat= Math.max(...latArray); 
                  this.lng= Math.max(...lngArray);
                }
              
                this.LocationMainmarkers.push({
                  lat: Number(element.locationPoints[0].lat),
                  lng: Number(element.locationPoints[0].lng),
                  label: 'Rendezvous point ' + indexRendezvouspoint,
                  visible: true,
                  opacity: 0.7,
                  icon: '../../assets/images/maps-and-flags-lighblue.png',
                  ispolygon: false,
                  path: []
                })
                indexRendezvouspoint++;
                break;
              case 2://Crime scene //Polygoan
                if (this.lat == undefined || this.lat == null || this.lng == undefined || this.lng == null){
                  let latArray : Array<number> = [];
                  let lngArray : Array<number> = [];
                  for (const iterator of element.locationPoints) {
                    latArray.push(iterator.lat);
                    lngArray.push(iterator.lng);
                  }
                  this.lat= Math.max(...latArray); 
                  this.lng= Math.max(...lngArray);
                }  

                this.LocationMainmarkers.push({
                  lat: 0,
                  lng: 0,
                  label: 'Crime scene ' + indexCrimescene,
                  visible: true,
                  opacity: 0.7,
                  icon: '../../assets/images/circle-purple.png',
                  ispolygon: true,
                  path: element.locationPoints,
                  color: '#c480b5'
                })
                indexCrimescene++;
                break;

              default:
                break;
            }

          });
        }
        if (this.IncidentDTO.section60 != null && this.IncidentDTO.section60.locality != null) {
          this.LocationMainmarkers.push({
            lat: 0,
            lng: 0,
            label: 'Section 60 ',
            visible: true,
            opacity: 0.7,
            icon: '../../assets/images/red-line.png',
            ispolygon: true,
            path: this.IncidentDTO.section60.locality,
            color: '#E52A12'
          })
        }
        if (this.IncidentDTO.section35 != null && this.IncidentDTO.section35.locality != null) {
          this.LocationMainmarkers.push({
            lat: 0,
            lng: 0,
            label: 'Section 35 ',
            visible: true,
            opacity: 0.7,
            icon: '../../assets/images/red-line.png',
            ispolygon: true,
            path: this.IncidentDTO.section35.locality,
            color: '#E52A12'
          })
        }
      }
      // const middleIndex = Math.ceil(this.LocationMainmarkers.length / 2);
      // let array = this.LocationMainmarkers
      // this.LocationMainmarkersFirst = array.slice().splice(0, middleIndex);
      // this.LocationMainmarkersSecond = array.slice().splice(-middleIndex);


      this.SetIncidentAsViewed();
    }
  }, (error => {
    this.IncidentDTO.incidentid = '0'
    this.IncidentDTO = new IncidentDTO();

    if (error && error.error && error.error.message){
      console.log("Error:" + JSON.stringify(error));
      this._apiErrorService.ShowErrorModal("Error", error.error.message);
      //this.toastr.error(error.error.message, "Error");
    }
    else{
      this._apiErrorService.ShowErrorModal();
      //this.toastr.error("Something went wrong!");
    }
  }))
}

ConvertUTCDateToLocalDate(date: Date): Date {
  const offset = date.getTimezoneOffset() * 60000;
  const localTime = date.getTime() - offset;
  const localDate = new Date(localTime);

  return localDate;
}

SetIncidentAsViewed(){
  this._incidentService.SetIncidentAsViewed(this.IncidentDTO.id).subscribe(response => {
    
  }, (error => {
    this.IncidentDTO.incidentid = '0'
    this.IncidentDTO = new IncidentDTO();

    if (error && error.error && error.error.message){
      console.log("Error:" + JSON.stringify(error));
      this._apiErrorService.ShowErrorModal("Error", error.error.message);
      //this.toastr.error(error.error.message, "Error");
    }
    else{
      this._apiErrorService.ShowErrorModal();
      //this.toastr.error("Something went wrong!");
    }
  }));
}

GetAllLocationDetails(locations:any[], section60: any, section35: any) {
        if (locations && locations.length > 0){
          for (const location of locations) {
            let entity = new IncidentFormLocationEntity();

            entity.description = location.description
            entity.id = location.id
            entity.name = location.name;
            entity.pathList = location.locationPoints;
            entity.visible = true;
            entity.draggable = true;
            entity.lat = this.lat;
            entity.lng = this.lng;
            entity.opacity = 1;
            entity.label = '';
            entity.icon = '../../assets/images/blue-big-marker.png';
            
            if (location.type == 0){
              if (this.PoliceCordonLocations.findIndex(x => x.id == location.id) == -1){
                entity.icon = '../../assets/images/blue-big-marker.png';
                this.PoliceCordonLocations.push(entity);
                this.PoliceCordonLocationsToShow.push(entity);
                this.MainLocationPolygon.push({ id: location.id, path: location.locationPoints, color: '#9fc6e7', name: location.name });
                this.MainLocationPolygonToShow.push({ id: location.id, path: location.locationPoints, color: '#9fc6e7', name: location.name });
              }
            }
            else if (location.type == 2){
              if (this.CrimeSceneLocations.findIndex(x => x.id == location.id) == -1){
                this.CrimeSceneLocations.push(entity);
                this.CrimeSceneLocationsToShow.push(entity);
                this.MainLocationPolygon.push({ id: location.id, path: location.locationPoints, color: '#b086ac', name: location.name });
                this.MainLocationPolygonToShow.push({ id: location.id, path: location.locationPoints, color: '#b086ac', name: location.name });
              }
            }
            else if (location.type == 1){
              if (this.MainLocationRVPMarker.findIndex(x => x.id == location.id) == -1){

                entity.icon = '../../assets/images/blue-big-marker.png';
                entity.lat = location.locationPoints[0].lat;
                entity.lng = location.locationPoints[0].lng;
                this.RendezvousPointLocations.push(entity);
                this.RendezvousPointLocationsToShow.push(entity);
                this.MainLocationRVPMarker.push(
                  { 
                    id: location.id, 
                    path: location.locationPoints, 
                    color: '#9fc6e7', 
                    name: location.name, 
                    icon: '../../assets/images/blue-big-marker.png',
                    label: '',
                    lat: location.locationPoints[0].lat,
                    lng: location.locationPoints[0].lng
                  }
                );
                this.MainLocationRVPMarkerToShow.push({ 
                  id: location.id, 
                  path: location.locationPoints, 
                  color: '#9fc6e7', 
                  name: location.name, 
                  icon: '../../assets/images/blue-big-marker.png',
                  label: '',
                  lat: location.locationPoints[0].lat,
                  lng: location.locationPoints[0].lng
                });
              }
            }

            

          //this.CrimeSceneLocations = response.data.locations.filter(x => x.type == 2);
          }

          if (section60 && section60.locality && section60.locality.length > 0){
            if (this.MainLocationRVPMarker.findIndex(x => x.id == section60.id) == -1){
              let entity = new IncidentFormLocationEntity();
              entity.description = section60.description
              entity.id = section60.id
              entity.name = section60.name;
              entity.pathList = section60.locality;
              entity.visible = true;
              entity.draggable = true;
              entity.lat = this.lat;
              entity.lng = this.lng;
              entity.opacity = 1;
              entity.label = '';
              entity.icon = '../../assets/images/blue-big-marker.png';

              this.Section60Locations.push(entity);
              this.Section60LocationsToShow.push(entity);
              this.MainLocationPolygon.push({ id: entity.id, path: entity.pathList, color: 'Red', name: entity.name });
              this.MainLocationPolygonToShow.push({ id: entity.id, path: entity.pathList, color: 'Red', name: entity.name });
            }
          }


          if (section35 && section35.locality && section35.locality.length > 0){

            if (this.MainLocationRVPMarker.findIndex(x => x.id == section35.id) == -1){
            let entity = new IncidentFormLocationEntity();
            entity.description = section35.description
            entity.id = section35.id
            entity.name = section35.name;
            entity.pathList = section35.locality;
            entity.visible = true;
            entity.draggable = true;
            entity.lat = this.lat;
            entity.lng = this.lng;
            entity.opacity = 1;
            entity.label = '';
            entity.icon = '../../assets/images/blue-big-marker.png';

            this.Section35Locations.push(entity);
            this.Section35LocationsToShow.push(entity);
            this.MainLocationPolygon.push({ id: entity.id, path: entity.pathList, color: 'Red', name: entity.name });
            this.MainLocationPolygonToShow.push({ id: entity.id, path: entity.pathList, color: 'Red', name: entity.name });
            }
          }
          
        }

}




//#region locations checkbox changes


ChkIncidentLocationChnaged(event: any){
  const ckecked = (event.target as HTMLInputElement).checked;
  if (!ckecked) {
    this.shouldIncidentMarkerVisible = false;
    this.shouldIncidentLocationShow = false;
  } else {
    this.shouldIncidentMarkerVisible = true;
    this.shouldIncidentLocationShow = true;
  }
}

ChkCrimeSceneChnaged(event: any, crimeScene: IncidentFormLocationEntity, index: number) {
  const ckecked = (event.target as HTMLInputElement).checked;

  const CrimeSceneLocationIndex = this.CrimeSceneLocations.findIndex(x => x.id === crimeScene.id);
  const MainLocationPolygonIndex = this.MainLocationPolygon.findIndex(x => x.id === crimeScene.id);
  
  if (!ckecked) {
    const deletedCrimeScene = this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationPolygonToShow.splice(MainLocationPolygonIndex, 1, null);
    document.getElementById('crimeScene' + index).style.display = 'none';
  } else {
    let existingCrimescene = this.CrimeSceneLocations[CrimeSceneLocationIndex]
    this.CrimeSceneLocationsToShow[CrimeSceneLocationIndex] = existingCrimescene

    let existingMainLocationPolygon = this.MainLocationPolygon[MainLocationPolygonIndex]
    this.MainLocationPolygonToShow[MainLocationPolygonIndex] = existingMainLocationPolygon;
    document.getElementById('crimeScene' + index).style.display = 'flex';
  }
}

ChkPoliceCordonChnaged(event: any, policeCordon: IncidentFormLocationEntity, index: number) {
  const ckecked = (event.target as HTMLInputElement).checked;
  //this.mapVisibilityDecider = "CrimeScene" + index;
  const PoliceCordonLocationIndex = this.PoliceCordonLocations.findIndex(x => x.id === policeCordon.id);
  const MainLocationPolygonIndex = this.MainLocationPolygon.findIndex(x => x.id === policeCordon.id);
  if (!ckecked) {
    const deletedCrimeScene = this.PoliceCordonLocationsToShow.splice(PoliceCordonLocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationPolygonToShow.splice(MainLocationPolygonIndex, 1, null);
    document.getElementById('policeCordon' + index).style.display = 'none';
  } else {
    let existingCrimescene = this.PoliceCordonLocations[PoliceCordonLocationIndex]
    this.PoliceCordonLocationsToShow[PoliceCordonLocationIndex] = existingCrimescene
    // this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 0, existingCrimescene);

    let existingMainLocationPolygon = this.MainLocationPolygon[MainLocationPolygonIndex]
    this.MainLocationPolygonToShow[MainLocationPolygonIndex] = existingMainLocationPolygon

    document.getElementById('policeCordon' + index).style.display = 'flex';
    // this.MainLocationPolygonToShow.splice(CrimeSceneLocationIndex, 0, existingMainLocationPolygon);
  }
}

ChkRendezvousPointChnaged(event: any, RendezvousPoint: IncidentFormLocationEntity, index: number) {
  const ckecked = (event.target as HTMLInputElement).checked;
  //this.mapVisibilityDecider = "CrimeScene" + index;
  const RendezvousPointLocationIndex = this.RendezvousPointLocations.findIndex(x => x.id === RendezvousPoint.id);
  const MainLocationRVPMarkerIndex = this.MainLocationRVPMarker.findIndex(x => x.id === RendezvousPoint.id);
  if (!ckecked) {
    const deletedRendezvousPoint = this.RendezvousPointLocationsToShow.splice(RendezvousPointLocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationRVPMarkerToShow.splice(MainLocationRVPMarkerIndex, 1, null);
    document.getElementById('rendezvousPoint' + index).style.display = 'none';
  } else {
    let existingCrimescene = this.PoliceCordonLocations[RendezvousPointLocationIndex]
    this.RendezvousPointLocationsToShow[RendezvousPointLocationIndex] = existingCrimescene;
    // this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 0, existingCrimescene);

    let existingMainLocationPolygon = this.MainLocationRVPMarker[MainLocationRVPMarkerIndex]
    this.MainLocationRVPMarkerToShow[MainLocationRVPMarkerIndex] = existingMainLocationPolygon;

    document.getElementById('rendezvousPoint' + index).style.display = 'flex';
    // this.MainLocationPolygonToShow.splice(CrimeSceneLocationIndex, 0, existingMainLocationPolygon);
  }
}

ChkSection60Chnaged(event: any, Section60: IncidentFormLocationEntity) {
  const ckecked = (event.target as HTMLInputElement).checked;
  //this.mapVisibilityDecider = "CrimeScene" + index;
  const Section60LocationIndex = this.Section60Locations.findIndex(x => x.id === Section60.id);
  const MainLocationPolygonIndex = this.MainLocationPolygon.findIndex(x => x.id === Section60.id);
  if (!ckecked) {
    const deletedSection60 = this.Section60LocationsToShow.splice(Section60LocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationPolygonToShow.splice(MainLocationPolygonIndex, 1, null);
  } else {
    let existingSection60 = this.Section60Locations[Section60LocationIndex]
    this.Section60LocationsToShow[Section60LocationIndex] = Section60
    // this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 0, existingCrimescene);

    let existingMainLocationPolygon = this.MainLocationPolygon[MainLocationPolygonIndex]
    this.MainLocationPolygonToShow[MainLocationPolygonIndex] = existingMainLocationPolygon
    // this.MainLocationPolygonToShow.splice(CrimeSceneLocationIndex, 0, existingMainLocationPolygon);
  }
}

ChkSection35Chnaged(event: any, Section35: IncidentFormLocationEntity) {
  const ckecked = (event.target as HTMLInputElement).checked;
  //this.mapVisibilityDecider = "CrimeScene" + index;
  const Section35LocationIndex = this.Section35Locations.findIndex(x => x.id === Section35.id);
  const MainLocationPolygonIndex = this.MainLocationPolygon.findIndex(x => x.id === Section35.id);
  if (!ckecked) {
    const deletedSection35 = this.Section35LocationsToShow.splice(Section35LocationIndex, 1, null);
    const deletedMainLocationPolygon = this.MainLocationPolygonToShow.splice(MainLocationPolygonIndex, 1, null);
  } else {
    let existingSection35 = this.Section35Locations[Section35LocationIndex]
    this.Section35LocationsToShow[Section35LocationIndex] = Section35
    // this.CrimeSceneLocationsToShow.splice(CrimeSceneLocationIndex, 0, existingCrimescene);

    let existingMainLocationPolygon = this.MainLocationPolygon[MainLocationPolygonIndex]
    this.MainLocationPolygonToShow[MainLocationPolygonIndex] = existingMainLocationPolygon
    // this.MainLocationPolygonToShow.splice(CrimeSceneLocationIndex, 0, existingMainLocationPolygon);
  }
}

//#endregion


LaunchCSTProtocolClicked(){
  this._router.navigate([`/cst-protocol-launch/${this.IncidentDTO.id}`]);
}


ImmediateTagClicked(){
  this._router.navigate(['immediate-response', this.IncidentDTO.id]);
}

CSTTagClicked(){
  this._router.navigate(['cst-protocol', this.IncidentDTO.id]);
}


  // #region LoadScript
  
  loadJquerySlider(){
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/js/jquerySlider.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    script.id = 'jquerySliderScriptTag'
  }

  ngOnDestroy(): void {
    this._loadScriptService.removeScript('incident-summary-pre-protocolScriptTag');
    this._loadScriptService.removeScript('jquerySliderScriptTag');
  }
  //#endregion

}
