import { Injectable } from '@angular/core';
import { IncidentDTO, Markers, MessageDTO, OrganizationGroup, Section35DTO, Section60DTO } from 'src/app/Models/incident.modal';
import { Location } from '@angular/common';
import { IncidentFormLocationEntity } from 'src/app/Models/IncidentFormLocationEntity';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IirService {

  constructor(
    private _location: Location,
    private _apiService: ApiService
  ) { }

  GetOrganizationGroupList() {
    let organizationGroup: OrganizationGroup[] = [];
    organizationGroup.push(
      { id: 'Homicide', name: 'Homicide' }, 
      { id: 'Violence with injury', name: 'Violence with injury' }, 
      { id: 'Violence without injury', name: 'Violence without injury' }, 
      { id: 'Other', name: 'Other' }, 
      { id: 'Emergency planning', name: 'Emergency planning' }, 
      { id: 'Unknown', name: 'Unknown' }
      )
    return organizationGroup;
  }

  GetSubOrganizationGroupList() {
    let subOrganizationGroup: OrganizationGroup[] = [];
    subOrganizationGroup.push(
      { id : 'Gun crime', name: 'Gun crime' }, 
      { id : 'Knife crime', name: 'Knife crime' }, 
      { id : 'Acid', name: 'Acid' }, 
      { id : 'Other', name: 'Other' }, 
      { id : 'Unknown', name: 'Unknown' }, 
      { id : 'Not applicable', name: 'Not applicable' }
      )
    return subOrganizationGroup;
  }

  ReplaceRoute(incidentId: string, tab: string){
    this._location.replaceState(`/BCU/initial-incident-form/${incidentId}/${tab}`);
  }
  
  InstanciateMainRedMarker(lat: number, lng: number, isVisible: boolean): Markers{
    const marker = new Markers();
    marker.lat = lat;
    marker.lng = lng;
    marker.label = "";
    marker.draggable = true;
    marker.visible = isVisible;
    marker.opacity = 0.7;
    marker.icon = "../../assets/images/red-marker.png";
    return marker;
  }

  InstanciateIncidentFormLocationEntity(response: any,locationPoints: Array<{lat: number, lng: number}>, isVisible: boolean, isDragable: boolean, lat: number, lng: number, icon: string){
    let entity = new IncidentFormLocationEntity();
    entity.description = response.description
    entity.id = response.id
    entity.name = response.name;
    entity.pathList = locationPoints;
    entity.visible = isVisible;
    entity.draggable = isDragable;
    entity.lat = lat;
    entity.lng = lng;
    entity.opacity = 1;
    entity.label = '';
    entity.closed = response.closed ? response.closed : false;
    entity.icon = icon;
    return entity;
  }

  CreatePointArrayForPolygon(path: any){
    let points : { lat: number; lng: number }[] = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      points.push(path.getAt(i).toJSON());
    }
    return points;
  }




  GetIncidentDetails(incidentid: any) {
    const url = "/BCU/GetBCUIncident/" + incidentid;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  SaveUpdateIncident(incident: IncidentDTO) {
    if (incident.incidentid == '0') {
      const url = "/BCU/Incident/Save";
      return this._apiService.post(url, incident).pipe(
        map((data) => {
          return data;
        })
      );
    } else {
      const url = "/BCU/Incident/Update";
      return this._apiService.put(url, incident).pipe(
        map((data) => {
          return data;
        })
      );
    }
  }

  SaveUpdateSection60Request(section60: Section60DTO) {
    const url = "/BCU/Section60/Save";
    return this._apiService.post(url, section60).pipe(
      map((data) => {
        return data;
      })
    );

  }

  GetSection60RequestDetails(section60Id: any) {
    const url = "/BCU/Section60/" + section60Id;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  FileUploadSection60(file) {
    const url = "/BCU/UploadMap";
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this._apiService.post(url, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }
  //#endregion
  //#region  Section 35 Request
  SaveUpdateSection35Request(section35: Section35DTO) {

    const url = "/BCU/Section35/Save";
    return this._apiService.post(url, section35).pipe(
      map((data) => {
        return data;
      })
    );

  }

  GetSection35RequestDetails(section35Id: any) {
    const url = "/BCU/Section35/" + section35Id;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  
  //#region  Partner Message
  SendPartnerMessage(message: MessageDTO) {
    const url = "/BCU/SendPartnerMessage";
    return this._apiService.put(url, message).pipe(
      map((data) => {
        return data;
      })
    );
  }
  //#endregion

}

export enum IIRAccordianNames{
  StartIncidentTab = "StartIncidentTab",
  LocationsTab = "LocationsTab",
  Section60RequestTab = "Section60RequestTab",
  Section35RequestTab = "Section35RequestTab",
  PartnerMessageTab = "PartnerMessageTab",
}
