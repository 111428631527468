import { Stakeholder, StakeholderProfileCreate } from './../../Models/Stakeholder/Stakeholder';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/interceptors/api.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddStakeholderService {

  StakeholderCommand = new Subject<[Stakeholder, boolean]>();

  constructor(
    private _apiService: ApiService
  ) { }

  AddStakeholder(stakeholder : StakeholderProfileCreate) {
    const url = `/Manager/Stakeholders/CreateStakeholder`;
    return this._apiService.post(url, stakeholder).pipe(
      map((data) => {
        return data;
      })
    );
  }

  EditTeamMember(stakeholder : StakeholderProfileCreate) {
    const url = `/Manager/Stakeholders/UpdateStakeholder`;
    return this._apiService.put(url, stakeholder).pipe(
      map((data) => {
        return data;
      })
    );
  }

}
