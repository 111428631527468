import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ApiErrorService } from '../core/services/api-error.service';
import { IncidentAction } from '../Models/IncidentforAction.model';
import { LocalAuthorityActionsResponseService } from './local-authority-actions-response.service';

@Component({
  selector: 'app-local-authority-actions-response',
  templateUrl: './local-authority-actions-response.component.html',
  styleUrls: ['./local-authority-actions-response.component.css']
})
export class LocalAuthorityActionsResponseComponent implements OnInit {
  IncidentAction: any[] = []
  constructor(
    private _localAuthorityActionsResponseService: LocalAuthorityActionsResponseService, 
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService
    ) { }
  IncidentListLoad: boolean = false;
  SelectAllAction: boolean = false
  UpdateMemberTaskStatusLoad: boolean = false;
  ngOnInit() {
    this.getIncidentListforAction();
  }
  getIncidentListforAction() {
    const self = this;
    self.IncidentListLoad = true;
    let userId='89178377-4F02-4680-BD56-08DAB1BA903F';
    self._localAuthorityActionsResponseService.getIncidentListforAction(userId).pipe(finalize(() => {
      self.IncidentListLoad = false;
    })).subscribe(res => {
      if (res != null && res.statusCode == 200) {
        this.IncidentAction = res.data;
        // console.table(this.IncidentAction)
        this.IncidentAction.forEach(element => {
          element.select = false;
        });
      }
    }, 
    (error) => {
      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this._apiErrorService.ShowErrorModal("Error", error.error.message);
        //this.toastr.error(error.error.message, "Error");
      }
      else{
        this._apiErrorService.ShowErrorModal();
        //this.toastr.error("Something went wrong!");
      }
    })
  }
  changeIncidentSelect(event: any, item: IncidentAction) {
    if (event) {
      item.select = true
    } else {
      item.select = false
    }
    let getSelectLength = this.IncidentAction.filter(x => x.select)
    if (getSelectLength.length == this.IncidentAction.length) {
      this.SelectAllAction = true;
    } else {
      this.SelectAllAction = false;
    }
  }
  changeSelectAll(event) {
    this.IncidentAction.forEach(element => {
      if (event) {
        element.select = true;
      } else {
        element.select = false;
      }
    });
  }
  IncidentAccept() {
    let SelectItem = this.IncidentAction.filter(x => x.select)
    if (SelectItem.length == 0) {
      this._toastr.warning("Please select incident");
      return;
    }
    //Accepted = 1,
    let taskMemberId =Array.prototype.map.call(SelectItem, function(item) { return item.taskMemberId; }).join(",");
    this.UpdateMemberTaskStatus(taskMemberId, 1)
  }
  IncidentDecline() {
    let SelectItem = this.IncidentAction.filter(x => x.select)
    if (SelectItem.length == 0) {
      this._toastr.warning("Please select incident");
      return;
    }
    //Declined  = 2,
    let taskMemberId =Array.prototype.map.call(SelectItem, function(item) { return item.taskMemberId; }).join(",");
    this.UpdateMemberTaskStatus(taskMemberId, 2)
  }
  IncidentComplete() {
    let SelectItem = this.IncidentAction.filter(x => x.select)
    if (SelectItem.length == 0) {
      this._toastr.warning("Please select incident");
      return;
    }
    //Complete   = 4,
    let taskMemberId =Array.prototype.map.call(SelectItem, function(item) { return item.taskMemberId; }).join(",");
    this.UpdateMemberTaskStatus(taskMemberId, 4)
  }
  UpdateMemberTaskStatus(taskMemberId: any, taskStatus: any) {
    const self = this;
    self.UpdateMemberTaskStatusLoad = true;
    self._localAuthorityActionsResponseService.UpdateMemberTaskStatus(taskMemberId, taskStatus).pipe(finalize(() => {
      self.UpdateMemberTaskStatusLoad = false
    })).subscribe(res => {
      if (res != null && res.statusCode == 200) {
        this.getIncidentListforAction();
      }
    }, 
    (error) => {
      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this._apiErrorService.ShowErrorModal("Error", error.error.message);
        //this.toastr.error(error.error.message, "Error");
      }
      else{
        this._apiErrorService.ShowErrorModal();
        //this.toastr.error("Something went wrong!");
      }
    })
  }
}
