import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/interceptors/api.service';
import { CommonType, IncidentDTO, IncidentStatus, LocationSectionDTO, LocationType, MessageDTO, OrganizationGroup, Section35DTO, Section60DTO } from '../Models/incident.modal';
import { Months } from '../Enum/Months.enum';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  IncidentStatus: IncidentStatus[] = [];
  LocationType: LocationType[] = [];
  Days: CommonType[] = []
  Months: CommonType[] = []
  Years: CommonType[] = []
  OrganizationGroup: OrganizationGroup[] = []
  subOrganizationGroup: OrganizationGroup[] = []
  constructor(private _apiService: ApiService) { }

  //#region  Incident 
  getIncidentStatus(): IncidentStatus[] {
    return this.IncidentStatus = [{ id: 0, name: "Open" }, { id: 1, name: "Active" }, { id: 2, name: "Scaled Down" }, { id: 3, name: "Archived" }]
  }
  getLocationType(): LocationType[] {
    return this.LocationType = [{ id: 0, name: "PoliceCordon" }, { id: 1, name: "RendezvousPoint" }, { id: 2, name: "CrimeScene" }]
  }
  getDays() {
    let tottalDay = 31
    for (let index = 1; index <= tottalDay; index++) {
      this.Days.push({ 'id': index, 'name': index.toString() });
    }
    return this.Days;
  }
  getMonths() {
    let tottalMonth = 12
    for (let index = 1; index <= tottalMonth; index++) {
      this.Months.push({ 'id': index, 'name': this.GetMonthNameFromMonthIndex(index) });
    }
    return this.Months;
  }
  getYears() {
    let currentDate = new Date();
    currentDate.setFullYear(2010)
    let tottalYear = Number(currentDate.getFullYear()) + 20
    for (let index = 2010; index <= tottalYear; index++) {
      this.Years.push({ 'id': index, 'name': index.toString() });
    }
    return this.Years;
  }

  GetMonthNameFromMonthIndex(monthIndex: number){
    const monthNames = Object.keys(Months).filter((key) => isNaN(Number(key))) as Array<keyof typeof Months>;
  
    const month = monthNames.find((month) => Months[month] === monthIndex);

    if (month) {
      return month;
    }

    return ''; // or handle invalid month index case as per your requirements
  }

  getOrganizationGroup() {
    this.OrganizationGroup=[];
    this.OrganizationGroup.push(
      { 'id': 'Homicide', 'name': 'Homicide' }, 
      { 'id': 'Violence with injury', 'name': 'Violence with injury' }, 
      { 'id': 'Violence without injury', 'name': 'Violence without injury' }, 
      { 'id': 'Other', 'name': 'Other' }, 
      { 'id': 'Emergency planning', 'name': 'Emergency planning' }, 
      { 'id': 'Unknown', 'name': 'Unknown' }
      )
    return this.OrganizationGroup
  }
  getsubOrganizationGroup() {
    this.subOrganizationGroup=[]
    this.subOrganizationGroup.push(
      { 'id': 'Gun crime', 'name': 'Gun crime' }, 
      { 'id': 'Knife crime', 'name': 'Knife crime' }, 
      { 'id': 'Acid', 'name': 'Acid' }, 
      { 'id': 'Other', 'name': 'Other' }, 
      { 'id': 'Unknown', 'name': 'Unknown' }, 
      { 'id': 'Not applicable', 'name': 'Not applicable' }
      )
    return this.subOrganizationGroup
  }

  getRegionList() {
    const url = "/BCU/RegionList";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  saveUpdateIncident(IncidentDTO: IncidentDTO) {
    if (IncidentDTO.incidentid == '0') {
      const url = "/BCU/Incident/Save";
      return this._apiService.post(url, IncidentDTO).pipe(
        map((data) => {
          return data;
        })
      );
    } else {
      const url = "/BCU/Incident/Update";
      return this._apiService.put(url, IncidentDTO).pipe(
        map((data) => {
          return data;
        })
      );
    }
  }
  getIncidentDetails(incidentid: any) {
    const url = "/BCU/GetBCUIncident/" + incidentid;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }


  SetIncidentAsViewed(incidentId: string){
    const url = `/BCU/SetIncidentAsViewed/${incidentId}`;
      return this._apiService.put(url).pipe(
        map((data) => {
          return data;
        })
      );
  }
  //#endregion

  //#region  Location
  saveUpdateLocation(LocationSectionDTO: LocationSectionDTO) {
    const url = "/BCU/Location/Save";
    return this._apiService.post(url, LocationSectionDTO).pipe(
      map((data) => {
        return data;
      })
    );

  }
  getLocationDetails(locationId: any) {
    const url = "/BCU/Location/" + locationId;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  //#endregion
  //#region  Section 60 Request
  saveUpdateSection60Request(Section60DTO: Section60DTO) {
    const url = "/BCU/Section60/Save";
    return this._apiService.post(url, Section60DTO).pipe(
      map((data) => {
        return data;
      })
    );

  }
  getSection60RequestDetails(section60Id: any) {
    const url = "/BCU/Section60/" + section60Id;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  fileUploadSection60(file) {
    const url = "/BCU/UploadMap";
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this._apiService.post(url, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }
  //#endregion
  //#region  Section 35 Request
  saveUpdateSection35Request(Section35DTO: Section35DTO) {

    const url = "/BCU/Section35/Save";
    return this._apiService.post(url, Section35DTO).pipe(
      map((data) => {
        return data;
      })
    );

  }
  getSection35RequestDetails(section35Id: any) {
    const url = "/BCU/Section35/" + section35Id;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  //#endregion

  //#region  Partner Message
  sendPartnerMessage(MessageDTO:MessageDTO) {
    const url = "/BCU/SendPartnerMessage";
    return this._apiService.put(url, MessageDTO).pipe(
      map((data) => {
        return data;
      })
    );
  }
  //#endregion
}
