<app-home-nav-menu [componentName]="'team-members'"></app-home-nav-menu>

<div class="team-protocol-section pt-15 local-auth-10 second-incident-sec">
  <div class="container cst-protocol Team_protocol_action">

    <h2 class="sec-title">Team</h2>

    <div class="back-to-dashboard">
      <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png"
          alt="left-angle-primary"> Back to dashboard</a>
    </div>

    <div class="w-100" *ngIf="!isMembersLoading">
      <div class="accordion tableaccordian  " id="contactaccordian">
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading1">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord1" aria-expanded="true" aria-controls="contactaccord1">
            </button>
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>First name</th>
                      <th>Given name</th>
                      <th>Job Title</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th><img src="./assets/images/Police-iir.png" alt="Police-iir"></th>
                      <th><img src="./assets/images/location-black.png" alt="location-black"></th>
                      <th><img src="./assets/images/partner-msg.png" alt="partner-msg"></th>
                      <th><img src="./assets/images/search-person.png" alt="search-person"></th>
                      <th><img src="./assets/images/ection-35.png" alt="ection-35"></th>
                      <th><img src="./assets/images/rocket-black.png" alt="rocket-black"></th>
                      <th>
                        <div class="dropdown actions update position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actionupdate"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="./assets/images/update-icon.png" alt="update-icon">
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="actionupdate">
                            <li><a href="#">Police IIR</a></li>
                            <li><a href="#">Location</a></li>
                            <li><a href="#">Partner Message</a></li>
                            <li><a href="#">Section 60</a></li>
                            <li><a href="#">Section 35</a></li>
                            <li><a href="#">Protocol launch</a></li>
                            <li><a href="#">SIM Updates</a></li>
                          </ul>
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let teamMember of teamMembers; index as ii">
                      <td>
                        <div class="team-member-name">
                          <img class="avtar-img" [src]="teamMember.avatar ? teamMember.avatar : './assets/images/profile-blank-icon.png'" alt="rd-icon">
                          <span>{{teamMember.firstName}}</span>
                          <img class="admin-img" *ngIf="GrayRoleIconVisibility(teamMember.role)" src="./assets/images/profile.png" alt="profile">
                        </div>
                      </td>
                      <td>{{teamMember.givenName}}</td>
                      <td class="text-wrap" style="padding: 3px 10px;">{{teamMember.jobTitle}}</td>
                      <td>
                        <a [href]="'mailto:' + teamMember.email" *ngIf="teamMember.email != null && teamMember.email != undefined && teamMember.email != ''">
                          <img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new">
                        </a>
                        <img *ngIf="teamMember.email == null || teamMember.email == undefined || teamMember.email == ''" 
                          class="message" src="./assets/images/mail-icon-unable.png" alt="">
                      </td>
                      <td>
                        <a [href]="'tel:' + teamMember.phone" *ngIf="teamMember.phone != null && teamMember.phone != undefined && teamMember.phone != ''">
                          <img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new">
                        </a>
                        <img *ngIf="teamMember.phone == null || teamMember.phone == undefined || teamMember.phone == ''" 
                          class="message" src="./assets/images/phone-icon-unable.png" alt="">
                      </td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" [checked]="teamMember.incidentAlert" (change)="teamMemeberCheckboxChnaged('incidentAlert', teamMember, $event, ii)"></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" [checked]="teamMember.locationAlert" (change)="teamMemeberCheckboxChnaged('locationAlert', teamMember, $event, ii)"></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" [checked]="teamMember.section60Alert" (change)="teamMemeberCheckboxChnaged('section60Alert', teamMember, $event, ii)"></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" [checked]="teamMember.section35Alert" (change)="teamMemeberCheckboxChnaged('section35Alert', teamMember, $event, ii)"></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" [checked]="teamMember.partnerMessage" (change)="teamMemeberCheckboxChnaged('partnerMessage', teamMember, $event, ii)"></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" [checked]="teamMember.incidentLaunchIfNotTeam" (change)="teamMemeberCheckboxChnaged('incidentLaunchIfNotTeam', teamMember, $event, ii)"></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" [checked]="teamMember.dashboardContact" (change)="teamMemeberCheckboxChnaged('dashboardContact', teamMember, $event, ii)"></td>
                      <td>
                        <div class="d-flex align-items-center justify-content-center" >
                          <mat-spinner [id]="'update-member-spinner' + ii" style="display: none;" diameter="20"></mat-spinner>
                        </div>
                        <div class="dropdown actions team-member position_unset" [id]="'edit-button-div' + ii" style="display: block;">
                          <button class="btn dropdown-toggle" type="button" [id]="'actiondropdown' + ii"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu showHand" [attr.aria-labelledby]="'actiondropdown' + ii" (click)="teamMemberEditClicked(teamMember)" >
                            <li><div class="test">Edit</div></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>
                        <div class="team-member-name">
                          <img src="./assets/images/rd-icon.png" alt="rd-icon">
                          <img src="./assets/images/profile.png" alt="profile">
                          <span>Dave</span>
                        </div>
                      </td>
                      <td>Thornton</td>
                      <td>Communication</td>
                      <td><img class="message" src="./assets/images/mail-icon-new.png" alt="mail-icon-new"></td>
                      <td><img class="call" src="./assets/images/phone-icon-new.png" alt="phone-icon-new"></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" checked></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" checked></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" checked></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" checked></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" checked></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" checked></td>
                      <td><input class="table-checkbox form-check-input border-style" type="checkbox" value="" checked></td>
                      <td>
                        <div class="dropdown actions team-member position_unset">
                          <button class="btn dropdown-toggle" type="button" id="actiondropdown1"
                            data-bs-toggle="dropdown" aria-expanded="false"></button>
                          <ul class="dropdown-menu showHand" aria-labelledby="actiondropdown1" (click)="teamMemberEditClicked(null)" >
                            <li><div class="test">Edit</div></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                     -->
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<div class="divloader" *ngIf="isMembersLoading">
  <mat-spinner diameter="50"></mat-spinner>
</div>