import { LocationDTO } from './../../Models/incident.modal';
import { DrawMapService } from './draw-map.service';
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Markers } from "src/app/Models/incident.modal";
import { MapForm } from "src/app/Models/MapForm";
import { IncidentFormLocationEntity } from 'src/app/Models/IncidentFormLocationEntity';
import { MapsAPILoader, Marker, MouseEvent } from "@agm/core";
import { ToastrService } from 'ngx-toastr';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
declare var google: any;

@Component({
  selector: "app-draw-map",
  templateUrl: "./draw-map.component.html",
  styleUrls: ["./draw-map.component.css"],
})
export class DrawMapComponent implements OnInit {
  @ViewChild('mapForm', { static: true }) mapForm: NgForm;

  //#region input fields and labels
  drawingType: string;
  //#endregion

  //#region form
  mapData: MapForm = new MapForm();
  isDuplicateName : boolean;
  isEdit : boolean;
  id : string;

  isInvalidMap: boolean = false;

  isMapReady = false;
  isSubmitted = false;

  incidentId: string;
  //#endregion

  //#region map
  InitialLatitude: number;
  InitialLongitude: number;
  InitialZoom: number;
  markerIconUrl: string;
  color: string;
  closed: boolean;

  marker: Markers;

  drawingManager: any;

  pointList: any[] = [];
  existingLocations : IncidentFormLocationEntity[] = [];
  existingRVPMarkers : IncidentFormLocationEntity[] = [];
  LocationToBeUpdate : IncidentFormLocationEntity;

  editable = false;
  polygonDrawn: boolean = false;

  RVPLatitude : number;
  RVPLongitude : number;

  HeadingText: string = "";
  
  //#endregion

  constructor(
    private _dialogRef: MatDialogRef<DrawMapComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _drawMapService: DrawMapService,
    private _apiloader: MapsAPILoader,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService
  ) {}

  ngOnInit(): void {
    this.incidentId = this._initialInfo.dialogInfo.incidentId;
    if (!this.incidentId){
      this._dialogRef.close();
    }
    this.InitialLatitude = this._initialInfo.dialogInfo.latitude;
    this.InitialLongitude = this._initialInfo.dialogInfo.longitude;
    this.InitialZoom = this._initialInfo.dialogInfo.zoom;
    this.drawingType = this._initialInfo.dialogInfo.drawingType;

    if (this.drawingType == "CrimeScene"){
      this.HeadingText = "Crime scene"
    }
    else if (this.drawingType == "PoliceCordon") {
      this.HeadingText = "Police Cordon"
    }
    else if (this.drawingType == "RendezvousPoint"){
      this.HeadingText = "RVP"
    }
    else if (this.drawingType == "section60Location") {
      this.HeadingText = "Section 60"
    }
    else if (this.drawingType == "section35Location") {
      this.HeadingText = "Section 35"
    }

    this.color = this._initialInfo.dialogInfo.color;
    this.existingLocations = this._initialInfo.dialogInfo.existingLocations
    this.existingRVPMarkers = this._initialInfo.dialogInfo.existingRVPMarkers
    this.isEdit = this._initialInfo.dialogInfo.isEdit;
    this.LocationToBeUpdate = this._initialInfo.dialogInfo.LocationToBeUpdate;
    this.id = this._initialInfo.dialogInfo.id;
    this.closed = this._initialInfo.dialogInfo.closed ?? false;
    this.marker = this._initialInfo.dialogInfo.marker;

    if (this.marker){
      this.mapData.Name = this.LocationToBeUpdate?.name;
      this.mapData.Description = this.LocationToBeUpdate?.description;
      let cordinatesArray = [];
      cordinatesArray.push({lat: this.marker.lat, lng: this.marker.lng});
      this.pointList.push(cordinatesArray);
    }

    else {
      if (this.isEdit){
        this.mapData.Name = this.LocationToBeUpdate?.name;
        this.mapData.Description = this.LocationToBeUpdate?.description;
        const array = [...this.LocationToBeUpdate?.pathList];
        this.pointList.push(array);
      }
    }

    this.isMapReady = true;
  }


  //#region polygon

  onMapReadyForCrimeSceneOnlyShowMap($event: any) {
    this.initDrawingManagerOnlyShowMapCrimeScene($event);
  }

  initDrawingManagerOnlyShowMapCrimeScene = (map: any) => {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(paths.getAt(p), "set_at", () => {
              if (!event.overlay.drag) {
                self.updatePointListCrimeScene(event.overlay.getPath());
                map.drawingMode = null;
              }
            });
            google.maps.event.addListener(paths.getAt(p), "insert_at", () => {
              self.updatePointListCrimeScene(event.overlay.getPath());
              map.drawingMode = null;
            });
            google.maps.event.addListener(paths.getAt(p), "remove_at", () => {
              self.updatePointListCrimeScene(event.overlay.getPath());
              map.drawingMode = null;
            });
          }
          self.updatePointListCrimeScene(event.overlay.getPath());
        }
      }
    );
  };

  updatePointListCrimeScene(path) {
    let latLngArray: { lat: number; lng: number }[] = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      latLngArray.push(path.getAt(i).toJSON());
    }


    this.pointList.push(latLngArray);

    this.editable = false;

    this.isInvalidMap = false;
    
  }

  //#endregion

 


 //#region RVP marker

 mapClicked($event: MouseEvent) {
  (this.RVPLatitude = $event.coords.lat), (this.RVPLongitude = $event.coords.lng);
    this._apiloader.load().then(() => {
      let geocoder = new google.maps.Geocoder();
      let latlng = {
        lat: this.RVPLatitude,
        lng: this.RVPLongitude,
      };
    });
  }


  markerDragEnd(m: Marker, $event: MouseEvent) {
    const self = this;
    
    this.pointList[0][0].lat = $event.coords.lat;
    this.pointList[0][0].lng = $event.coords.lng;

    this.isInvalidMap = false;
  }



   //#endregion

  markerDragEndLocation($event: MouseEvent) {
    let obj = $event.coords;
    this.updateLocationPointList(obj);
  }

  updateLocationPointList(path) {
    
    this.pointList.forEach((element) => {
      element.LocationDTOItem.forEach((sub) => {
        if (sub.mapShow) {
          sub.selected = true;
          sub.locationPoints = [];
          sub.locationPoints.push(path);
        }
      });
    });
  }

  
  

  nameChange(event: any){
    if (event != null && event != undefined && event != ''){
      if (this.isEdit){
        if (!this.existingLocations.find(x => x.name == null || x.name == undefined)){
          const isPolyNameDuplicate = this.existingLocations.filter(x => x.name.toLowerCase() === event.toLowerCase() && x.id != this.id).length > 0 ? true : false;
          const isRVPMarkerDuplicate = this.existingRVPMarkers.filter(x => x.name.toLowerCase() === event.toLowerCase() && x.id != this.id).length > 0 ? true : false;
          this.isDuplicateName = isPolyNameDuplicate || isRVPMarkerDuplicate
        }
      }
      else{
        if (!this.existingLocations.find(x => x.name == null || x.name == undefined)){
          const isPolyNameDuplicate = this.existingLocations.findIndex(x => x.name.toLowerCase() === event.toLowerCase()) == -1 ? false : true;
          const isRVPMarkerDuplicate = this.existingRVPMarkers.findIndex(x => x.name.toLowerCase() === event.toLowerCase()) == -1 ? false : true;
          this.isDuplicateName = isPolyNameDuplicate || isRVPMarkerDuplicate
        }
      }
      if (this.isDuplicateName){
        this.mapForm.form.setErrors({ 'invalid': true });
      }
    }
  }

  RemoveLocations(){
    this.pointList = [];
    this.isInvalidMap = true
  }

  onSubmit(formData: MapForm) {
    this.isSubmitted = true;
    if (!this.pointList || this.pointList.length == 0){
      this.isInvalidMap = true;
      return;
    }
    let randomId : string
    // if (!this.isEdit){
    //   randomId = this._drawMapService.generateRandomId();
    // }
    let result = {
      id: this.isEdit ? this.id : randomId,
      name: formData.Name,
      description: formData.Description,
      locations: this.pointList[0],
      color: this.color,
      locationDTO: new LocationDTO()
    };

    if (this.isEdit){
      result.id = this.id;
    }

    let locationDTO = new LocationDTO();
    locationDTO.type =  this._drawMapService.LocationTypeDesider(this.drawingType);
    locationDTO.name = result.name;
    locationDTO.closed = false;
    locationDTO.description = result.description;
      locationDTO.locationPoints =  this.pointList[0];
    if (this.isEdit){
      locationDTO.id = this.id;
      locationDTO.closed = this.closed;
    }
    result.locationDTO = locationDTO;

    this._drawMapService.saveUpdateLocation(locationDTO, this.incidentId).subscribe(response => {
      if (response && response.statusCode == 200 && response.data){
        result.locationDTO.id = response.data;
        result.id = response.data;
        this._dialogRef.close(result);
      }
    }, 
    (error) => {
      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this._apiErrorService.ShowErrorModal("Error", error.error.message);
        //this.toastr.error(error.error.message, "Error");
      }
      else{
        this._apiErrorService.ShowErrorModal();
        //this.toastr.error("Something went wrong!");
      }
    });

   
  }

  closeMapClicked(){
    this._dialogRef.close();
  }

}
