<!-- <app-home-nav-menu [componentName]="'team-members'"></app-home-nav-menu> -->

<div class="py-50 message-form member_add">
  <div class="container">
    <div class="accordion tableaccordian" id="addmember">
      <div class="accordion-item">
        <h2 class="accordion-header" id="addmemberheading1">
          <!-- <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#addmember1"
            aria-expanded="true" aria-controls="addmember1">
            Add team member 
          </button> -->
          <div class="accordion-button" type="button">
            Add team member 
          </div>
        </h2>
        <form [formGroup]="teamMemberForm" (submit)="onSubmit()">
          <div id="addmember1" class="accordion-collapse collapse show" aria-labelledby="addmemberheading11"
          data-bs-parent="#addmember">
            <div class="accordion-body">
              <div class="form-style">
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input select">
                          <img class="hs_icon" src="assets/images/user.png" alt="user">
                          <select name="AllTeamMembers" formControlName="role" id="teammembers">
                            <option [ngValue]="null">Select team member role</option>
                            <option ngValue="GroupAdmin">Admin</option>
                            <option ngValue="GroupMember">Team Member</option>
                            <option ngValue="GroupCommunicator">Team member Communicator</option>

                            <!-- <ng-container *ngFor="let option of options">
                              <option [value]="option">{{ option }}</option>
                            </ng-container> -->
                            <!-- <option value="role1">role1</option>
                            <option value="role1">role1</option> -->
                          </select>
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              role.touched) && role.hasError('required')">
                              Role is required</span>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input select">
                          <!-- <img class="hs_icon" src="assets/images/user.png" alt="user">
                          <select name="AllTeamMembers" id="teammembers">
                            <option value="Select team member role">Select team member role</option>
                            <option value="role1">role1</option>
                            <option value="role1">role1</option>
                          </select> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user.png" alt="user">
                          <input class="form-control" type="text" id="firstName" placeholder="First name"
                            formControlName="firstName">
                          
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              firstName.touched) && firstName.hasError('required')">
                              First name is required</span>
                      </div>
                      
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user.png" alt="user">
                          <input class="form-control" type="text" id="givenName" placeholder="Given name" 
                            formControlName="givenName">
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              givenName.touched) && givenName.hasError('required')">
                              Given name is required</span>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user-rol.png" alt="user-rol">
                          <input class="form-control" type="text" id="jobTitle" placeholder="Job Title"
                            formControlName="jobTitle">       <!--formControlName="role"-->
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              jobTitle.touched) && jobTitle.hasError('required')">
                              Job Title is required</span>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/user-id.png" alt="user-id">
                          <input class="form-control" type="text" id="employer" placeholder="Employer"
                           formControlName="employer">
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              employer.touched) && employer.hasError('required')">
                              Employer is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/Local_Authority_blue.png" alt="Local_Authority_blue">
                          <input class="form-control" type="tel" id="phone" placeholder="Phone" 
                            formControlName="phone">
                        </div>
                       
                            <div class="text-danger"
                              *ngIf="(isSubmitted ||
                              phone.touched) && phone.hasError('required')">
                              Phone is required</div>
                            <div class="text-danger"
                              *ngIf="(isSubmitted ||
                              phone.touched) && phone.hasError('pattern')">
                              Invalid phone number (formate example: '+1234567890')</div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="hs_icon_input">
                          <img class="hs_icon" src="./assets/images/blue-mail-icon.png" alt="blue-mail-icon">
                          <input class="form-control" type="mail" id="Email" placeholder="Email"
                            formControlName="email">
                        </div>
                        
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              email.touched) && email.hasError('required')">
                              Email is required</span>
                            <span class="text-danger"
                              *ngIf="(isSubmitted ||
                              email.touched) && email.hasError('email')">
                              Invalid Email id</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset">
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                        <div class="margin-left">
                          <!-- <img class="hs_icon" src="./assets/images/blue-mail-icon.png" alt="blue-mail-icon"> -->
                          <!-- <input class="form-control" type="tel" id="alertpreferences" name="alertPreferences" placeholder="Alert preferences" required> -->
                          <span>Alert preferences</span>
                        </div>
                        <div class="checkbox-square">
                          <!-- <div class="checkbox_each">
                            <input class="table-checkbox border-check form-check-input" 
                            type="checkbox" value="">
                            <label for="rollover">(rollover)</label>
                          </div> -->
                          <div class="checkbox_each">
                            <!-- <input type="checkbox" name="rollover" id="rollover" checked> -->
                            <input class="table-checkbox border-check form-check-input" 
                              type="checkbox" value="" 
                              [checked]="_data?.memberProfile?.incidentAlert"
                              formControlName="policeIIR">
                            <label for="rollover">Police IIR</label>
                          </div>

                          <div class="checkbox_each">
                            <input type="checkbox"
                            [checked]="_data?.memberProfile?.locationAlert"
                            formControlName="location">

                            <label for="rollover">Location</label>
                          </div>

                          <div class="checkbox_each">
                            <input type="checkbox"
                            [checked]="_data?.memberProfile?.partnerMessage"
                            formControlName="partnerMessage">

                            <label for="rollover">Partner Message</label>
                          </div>

                          <div class="checkbox_each">
                            <input type="checkbox"
                            [checked]="_data?.memberProfile?.section60Alert"
                            formControlName="section60">

                            <label for="rollover">Section 60</label>
                          </div>

                          <div class="checkbox_each">
                            <input type="checkbox"
                            [checked]="_data?.memberProfile?.section35Alert"
                            formControlName="section35">

                            <label for="rollover">Section 35</label>
                          </div>

                          <div class="checkbox_each">
                            <input type="checkbox"
                            [checked]="_data?.memberProfile?.incidentLaunchIfNotTeam"
                            formControlName="protocolLaunch">

                            <label for="rollover">Protocol launch</label>
                          </div>

                          <div class="checkbox_each">
                            <input type="checkbox"
                            [checked]="_data?.memberProfile?.dashboardContact"
                            formControlName="dashboardContact">
                          
                            <label for="rollover">Dashboard Contact</label>
                          </div>
                          
                          
                        </div>

                      </div>
                      <div class="col-12 col-sm-6 d-flex align-items-end justify-content-end">
                        <div class="hs-submit p-0">
                          <button type="submit" class="primary-btn">Save</button>  <!--[disabled]="teamMemberForm.invalid"-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
