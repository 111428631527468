export class BCURegionDTO{
    id:any;
    name:string;
    locationDescription:string;
    selected:boolean=false;
    groups: OrganizationGroup[];
}

export class OrganizationGroup{
    organizationGroupId: number;
    organizationGroupName: string;
    isAuthenticatedUserGroup: boolean;
}