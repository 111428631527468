import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { HttpTokenInterceptor } from "./interceptors/http.token.interceptor";

// import { MovieTypePipe, TimerformatPipe } from "./pipes";

// import { TimeFormat } from "./pipes/convertFrom24To12Format.pipe";
import { ApiService } from "./interceptors/api.service";
import { SorterStringPipe } from './pipes/sorter-string.pipe';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
        ApiService,

    ],
    declarations: [ SorterStringPipe],
    exports: [ SorterStringPipe],
})
export class CoreModule { }
