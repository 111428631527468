import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BCURegionDTO } from '../Models/BCURegionDTO.Model';
import { LADashboardDTO } from '../Models/dashboard.model';
import { DashboardService } from './dashboard.service';
import { ApiErrorService } from '../core/services/api-error.service';
import { UserProfileService } from '../core/services/user-profile.service';
import { AuthenticatedUserForGrouping, UserRegions } from '../Models/Members/AuthenticatedUserGroups';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dashboardLoad: boolean = false;
  //BCURegionDTO: BCURegionDTO[] = [];

  usersRegionsAndGroups: AuthenticatedUserForGrouping;
  selectedRegion: BCURegionDTO;
  isUserLoaded = false;
  
  // SelectedBCURegionDTO: BCURegionDTO = new BCURegionDTO()
  //OrganizationGroup: OrganizationGroup[] = []
  LADashboardDTO: LADashboardDTO[] = []

  
  constructor(
    private _dashboardService: DashboardService, 
    private _toastr: ToastrService,
    private _titleService:Title,
    private _activatedRoute: ActivatedRoute,
    private _apiErrorService: ApiErrorService,
    private _userProfileService: UserProfileService) { }

    ngOnInit() {
      this._titleService.setTitle(this._activatedRoute.snapshot.data.title)
      this.GetUserRegionsAndGroups();
      // this.getRegionList();
      // this.getBUCRegion();
      //this.GetDashboradDetails();
      //this.getSelectedBCURegionDTO()
      //this.getOrganizationGroup();
      interval(10000).subscribe(() => {
        this.getRefreshDashboardData();
      });
    }


    GetUserRegionsAndGroups(){

      let timerId = setInterval(() => {
        const userWithRegion = this._userProfileService.GetAuthenticatedUserGroups();
        if (userWithRegion){
          this.usersRegionsAndGroups =  userWithRegion;
          if (this.usersRegionsAndGroups && this.usersRegionsAndGroups.region) {
            this.selectedRegion = this.usersRegionsAndGroups.region;
          }
    
          if (this.selectedRegion){
            clearInterval(timerId);
            this.GetDashboradDetails();
  
            this.isUserLoaded = true;
          }
        }
      }, 100);
    }

    // getRegionList() {
    //   this.NavbarService.getRegionList().subscribe(res => {
    //     if (res != null && res.statusCode == 200) {
    //       this.BCURegionDTO = res.data;
    //       this.BCURegionDTO.forEach(element => {
    //         element.selected = false
    //       });
    //       if (this.BCURegionDTO != null && this.BCURegionDTO.length > 0) {
    //         if (this.SelectedBCURegionDTO == null || this.SelectedBCURegionDTO.id == null) {
    //           this.BCURegionDTO[0].selected = true
    //           this.setBCURegionDTO(this.BCURegionDTO[0])
    //           this.SelectedBCURegionDTO = this.BCURegionDTO[0];
    //         } else {
    //           this.BCURegionDTO.forEach(element => {
    //             if (element.id == this.SelectedBCURegionDTO.id)
    //               element.selected = true
    //           });
    //         }
    //       }
    //     }
    //   }, 
    //   (error) => {
    //     if (error && error.error && error.error.message){
    //       console.log("Error:" + JSON.stringify(error));
    //       this._apiErrorService.ShowErrorModal("Error", error.error.message);
    //       //this.toastr.error(error.error.message, "Error");
    //     }
    //     else{
    //       this._apiErrorService.ShowErrorModal();
    //       //this.toastr.error("Something went wrong!");
    //     }
    //   })
    // }
    // getBUCRegion() {
    //   this.SelectedBCURegionDTO = this.NavbarService.getSelectedBCURegionDTO();
    // }
    // getOrganizationGroup() {
    //   this.OrganizationGroup = this.IncidentService.getOrganizationGroup()
    // }
    getRefreshDashboardData() {
      const self = this;
      self._dashboardService.getDashBoard(this.selectedRegion.id).subscribe(res => {
        if (res != null && res.statusCode == 200) {
          this.LADashboardDTO = res.data
        }
      }, 
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this.toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this.toastr.error("Something went wrong!");
        }
      });
    }
    GetDashboradDetails() {
      const self = this;
      self.dashboardLoad = true;
      self._dashboardService.getDashBoard(this.selectedRegion.id).pipe(finalize(() => {
        self.dashboardLoad = false;
      })).subscribe(res => {
        if (res != null && res.statusCode == 200) {
          this.LADashboardDTO = res.data
        }
      }, 
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this.toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this.toastr.error("Something went wrong!");
        }
      })
    }
    getTypeOfIncident(type) {
      if (type === '0') return 'Early Alert';
      else if (type === '1') return 'Active';
      else if (type === '2') return 'Active';
      else if (type === '3') return 'Scaled Down';
      else if (type === '4') return 'Archived';
      return type;
    }
    DateConverttoUTC(date: Date) {
      return new Date(date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      );
    }
    // getSelectedBCURegionDTO() {
    //   this.SelectedBCURegionDTO = this.NavbarService.getSelectedBCURegionDTO()
    // }
    changeBUCRegion(event, selectedRegion: UserRegions) {

      if (event) {
        // selectedRegion.selected=true
        // // this.SelectedRegion = { id : item.id, name: item.name, locationDescription: item.locationDescription };
        // this.usersRegionsAndGroups.region.forEach(region => {
        //   if (region.regionId == selectedRegion.regionId){
        //     region.selected = true;
        //   }
        //   else{
        //     region.selected = false;
        //   }
        // });
        // this.SetAuthenticatedUserGropus(this.usersRegionsAndGroups);
      } 
      else {
        // this.SelectedRegion = { id : this.BCURegionDTO[0].id, name: this.BCURegionDTO[0].name, locationDescription: this.BCURegionDTO[0].locationDescription };
        // this.usersRegionsAndGroups.region.forEach((region, index) => {
        //   if (index == 0){
        //     region.selected = true;
        //   }
        // });
        // this.SetAuthenticatedUserGropus(this.usersRegionsAndGroups);
  
        // this.BCURegionDTO[0].selected=true;
        // this.setBCURegionDTO(this.BCURegionDTO[0])
        // this.SelectedRegion = this.BCURegionDTO[0];
      }

      this.GetDashboradDetails();


      // this.BCURegionDTO.forEach(element => {
      //   element.selected = false
      // });
      // if (event) {
      //   item.selected=true
      //   this.SelectedBCURegionDTO = item;
      //   this.setBCURegionDTO(item);
      // } else {
      //   this.BCURegionDTO[0].selected=true;
      //   this.setBCURegionDTO(this.BCURegionDTO[0])
      //   this.SelectedBCURegionDTO = this.BCURegionDTO[0];
      // }
      // window.location.reload();
    }

    SetAuthenticatedUserGropus(authenticatedUserGroup: AuthenticatedUserForGrouping){
      this._userProfileService.SetAuthenticatedUserGroups(authenticatedUserGroup);
    }

    // setBCURegionDTO(SelectedBCURegionDTO: BCURegionDTO) {
    //   this.NavbarService.setSelectedBCURegionDTO(SelectedBCURegionDTO)
    // }

}
