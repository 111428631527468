import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringHelperService {

  IsNullOrWhiteSpace(str) {
    return str === null || str === undefined || str.trim() === '';
  }
}
