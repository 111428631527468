import { CognitoService } from "src/app/Authentication/cognito.service";
import { Router } from "@angular/router";
import { Component, OnDestroy } from "@angular/core";
import { BCURegionDTO } from "../Models/BCURegionDTO.Model";
import { NavbarService } from "./navbar.service";
import { ApiErrorService } from '../core/services/api-error.service';
import { UserProfileService } from '../core/services/user-profile.service';
import { AuthenticatedUserForGrouping } from '../Models/Members/AuthenticatedUserGroups';
import { Subscription } from 'rxjs';
import { MemberProfileDetailsDTO } from '../Models/Messages/MessageTemplateDTO';
import { StringHelperService } from '../core/services/string-helper.service';
import { AppStartService } from "../core/services/app-start.service";
import { UrlHelperService } from "../core/services/url-helper.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.css"],
})
export class NavMenuComponent implements OnDestroy {
  isExpanded = false;
  isLaUser: boolean = false;
  isBCUUser: boolean = false;

  
  simHelpUrl: string;

  usersRegionsAndGroups: AuthenticatedUserForGrouping;
  selectedRegion: BCURegionDTO;

  BCURegionDTO: BCURegionDTO;
  
  RouteEvenComplitionSubscrtiption = new Subscription();

  CurrentUrlChangesSubscription= new Subscription();
  CurrentUserChangesSubscription= new Subscription();

  currentPageHelpUrl: string;
  currentPageUrl: string;
  currentUser: MemberProfileDetailsDTO;

  navmenuClass: string;

  constructor(
    private _appStartService: AppStartService, 
    private _router: Router, 
    private _cognitoService: CognitoService,
    private _navbarService: NavbarService,
    private _apiErrorService: ApiErrorService,
    private _userProfileService: UserProfileService,
    private _stringHelperService: StringHelperService,
    public _urlHelperService: UrlHelperService
    ) {}

  async ngOnInit() {
    this.CurrentUrlChangesSubscription = this._appStartService.currentUrlChanged.subscribe((currentUrl) => {
      if (currentUrl){
        this.navbarClass(currentUrl);
        this.currentPageHelpUrl = this._navbarService.GetCurrentPageHelpURL(currentUrl);
        this.currentPageUrl = currentUrl;
      }
    });

    this.simHelpUrl = this._urlHelperService.GetSimHelpURL();
    let user: any;
    if (!this._appStartService.IsCognitoUserLoaded){
      user = await this._cognitoService.GetUser();
    }
    else{
      user = this._appStartService.currentCognitoUser;
    }
    if (this._stringHelperService.IsNullOrWhiteSpace(user.attributes['custom:roles'])){
      await this._cognitoService.SignOut();
    }
    else{
      this.isLaUser = this._cognitoService.IsLaUser(user.attributes['custom:roles']);
      this.isBCUUser = this._cognitoService.IsBCUUser(user.attributes['custom:roles']);
  
      this.GetUserRegionsAndGroups();
              
      this.GetRegion();
    }
    this.currentUser = this._appStartService.currentUser;
  }

  

  navbarClass(route: string){
    this.navmenuClass = this._navbarService.navbarClass(route);
  }


  GetUserRegionsAndGroups(){

    let timerId = setInterval(() => {
      const userWithRegion = this._userProfileService.GetAuthenticatedUserGroups();
      if (userWithRegion){
        this.usersRegionsAndGroups = userWithRegion;
        if (this.usersRegionsAndGroups && this.usersRegionsAndGroups.region) {
          this.selectedRegion = this.usersRegionsAndGroups.region;
        }
  
        if (this.selectedRegion){
          clearInterval(timerId);
        }
      }
    }, 100);
  }

  collapse() {
    this.isExpanded = false;
  }
  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  GetRegion() {
    this._navbarService.getRegionList().subscribe(res => {
      if (res != null && res.statusCode == 200) {
        this.BCURegionDTO = res.data;
        this.setBCURegionDTO(this.BCURegionDTO)
        // this.BCURegionDTO.forEach(element => {
        //   element.selected = false
        // });
        // if (this.BCURegionDTO != null && this.BCURegionDTO.length > 0) {
        //   if (this.SelectedBCURegionDTO == null || this.SelectedBCURegionDTO.id == null) {
        //     this.BCURegionDTO[0].selected = true
        //     this.setBCURegionDTO(this.BCURegionDTO[0])
        //     this.SelectedBCURegionDTO = this.BCURegionDTO[0];
        //   } else {
        //     this.BCURegionDTO.forEach(element => {
        //       if (element.id == this.SelectedBCURegionDTO.id)
        //         element.selected = true
        //     });
        //   }
        // }
      }
    }, 
    (error) => {
      this._apiErrorService.ShowError(error);
    })
  }

  setBCURegionDTO(SelectedBCURegionDTO: BCURegionDTO) {
    this._navbarService.setSelectedBCURegionDTO(SelectedBCURegionDTO)
  }

  // $('.humburg').click(function(){
  //   $('.header-dashboard').toggleClass('active');
  //   // $('.header-dashboard').toggleClass('active');
  
  // })
  
  // $(".sidebar_closebtn").click(function(){
  //     $('.header-dashboard').removeClass('active');
  // })

  async btnSignoutClicked() {
    await this._cognitoService.SignOut();
  }

  // btnSignInClicked() {
  //   this.isSignedIn = true;
  // }

  HamburgToggle(event, headerDiv: HTMLDivElement){
    event.stopPropagation();
    headerDiv.classList.toggle('active');
  }

  SidebarCloseBtnClicked(headerDiv: HTMLDivElement){
    headerDiv.classList.remove('active');
  }

  closeSideBar(){
    const headerDivs = document.getElementsByClassName('header-dashboard');
    if (headerDivs && headerDivs.length > 0){
      headerDivs[0]?.classList.remove('active');
    }
  }

  ChangePasswordClicked(){
    this._router.navigate([this._urlHelperService.GetChangePasswordUrl()]);
    this.closeSideBar();
  }


  BCUDashboardClicked(){
    this._router.navigate([this._urlHelperService.BcuUserDashboardUrl()]);
    this.closeSideBar();
  }

  LaHomeDashboardClicked(){
    this._router.navigate([this._urlHelperService.LaUserDashboardUrl()]);
    this.closeSideBar();
  }

  LaDashboardClicked(){
    this._router.navigate([this._urlHelperService.La2UserDashboardUrl()]);
    this.closeSideBar();
  }

  HelpFromHamburgerClicked(){
    if (this.isBCUUser){
      
    }
  }


  ngOnDestroy(): void {

    if (this.CurrentUrlChangesSubscription){
      this.CurrentUrlChangesSubscription.unsubscribe();
    }

    if (this.CurrentUserChangesSubscription){
      this.CurrentUserChangesSubscription.unsubscribe();
    }
    
  }
}
