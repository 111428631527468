import { CstProtocolLaunchComponent } from './cst-template/cst-protocol-launch/cst-protocol-launch.component';
import { AddTeamMemberComponent } from './team/add-team-member/add-team-member.component';
// import { SignInComponent } from "./Authentication/sign-in/sign-in.component";
import { NotesOverlayComponent } from "./overlays/notes-overlay/notes-overlay.component";
import { CreateCstTemplateComponent } from "./cst-template/create-cst-template/create-cst-template.component";
import { CstTemplateComponent } from "./cst-template/cst-template/cst-template.component";
import { StakeholderMembersComponent } from "./Stakeholder/stakeholder-members/stakeholder-members.component";
import { NewIncidentComponent } from "./new-incident/new-incident.component";
import { FinaliseProtocolActionsPostProtocolLaunchComponent } from "./home-dashboard/finalise-protocol-actions-post-protocol-launch/finalise-protocol-actions-post-protocol-launch.component";
import { TeamMembersComponent } from "./team/team-members/team-members.component";
import { TeamMessagingDashboardComponent } from "./team/team-messaging-dashboard/team-messaging-dashboard.component";
import { IncidentSummaryPreProtocolLaunchComponent } from "./incident-summary-pre-protocol-launch/incident-summary-pre-protocol-launch.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HomeDashboardComponent } from "./home-dashboard/home-dashboard/home-dashboard.component";
import { CstProtocolComponent } from "./home-dashboard/cst-protocol/cst-protocol.component";
//import { EmergencyPlanningComponent } from "./home-dashboard/emergency-planning/emergency-planning.component";
// import { IncidentDetailNewComponent } from "./incident-detail-new/incident-detail-new.component";
import { IncidentLocationDetailsComponent } from "./incident-location-details/incident-location-details.component";
import { LocalAuthorityActionsResponseComponent } from "./local-authority-actions-response/local-authority-actions-response.component";
import { LocalAuthorityCstProtocolComponent } from "./local-authority-cst-protocol/local-authority-cst-protocol.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { StakeholderMessagingComponent } from "./Stakeholder/stakeholder-messaging/stakeholder-messaging.component";
import { NewIncidentLaStoreUserComponent } from "./new-incident-la-store-user/new-incident-la-store-user.component";
import { AuthGuard } from "./Authentication/auth.guard/AuthGuard";
import { LoginReceiverComponent } from './Authentication/login-receiver/login-receiver.component';
import { BCUAuthGuard } from './bcu/Authentication/BCUAuthGuard';
import { SignUpComponent } from './Authentication/sign-up/sign-up.component';
import { EditCstTemplateComponent } from './cst-template/edit-cst-template/edit-cst-template.component';
import { ImmediateResponseComponent } from './home-dashboard/immediate-response/immediate-response.component';

const routes: Routes = [
  { path: "", component: LoginReceiverComponent },
  // { path: "account/login", redirectTo: "home-dashboard", pathMatch: "full" },
  { path: "account/login", component: LoginReceiverComponent },
  { path: "account/logout", redirectTo: "home-dashboard", pathMatch: "full" },
  { path: 'BCU', loadChildren: () => import('./bcu/bcu.module').then(m => m.BcuModule), canActivate: [BCUAuthGuard]},    
//
  // {
  //   path: "sign-in",
  //   component: SignInComponent,
  //   data: {
  //     title: "Sign in",
  //   },
  // },
  {
    path: "sign-up/:id",
    component: SignUpComponent,
    data: {
      title: "Sign Up",
    },
  },
  {
    path: "la-dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Local Authority Dashboard",
    },
  },
  {
    path: "home-dashboard",
    component: HomeDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Home-dashboard",
    },
  },
  {
    path: "incident-location-details/:id",
    component: IncidentLocationDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Incident Location Details",
    },
  },
  {
    path: "local-authority-cst-protocol",
    component: LocalAuthorityCstProtocolComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Local Authority CST Protocol",
    },
  },
  {
    path: "stakeholder-messaging",
    component: StakeholderMessagingComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Stakeholder Messaging",
    },
  },
  {
    path: "local-authority-actions-response",
    component: LocalAuthorityActionsResponseComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Local Authority Actions Response",
    },
  },
  // {
  //   path: "immediate-response/:id",
  //   component: EmergencyPlanningComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     title: "Immediate Response Actions",
  //   },
  // },
  {
    path: "immediate-response/:id",
    component: ImmediateResponseComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Immediate Response Actions",
    },
  },
  {
    path: "cst-protocol/:id?",
    component: CstProtocolComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Protocol-actions-post-protocol-launch",
    },
  },
  {
    path: "cst-protocol",
    component: CstProtocolComponent,
    canActivate: [AuthGuard],
    data: {
      title: "CST Protocol",
    },
  },
  {
    path: "finalise-protocol-actions-post-protocol-launch",
    component: FinaliseProtocolActionsPostProtocolLaunchComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Finalise-protocol-actions-post-protocol-launch",
    },
  },
  // {
  //   path: "incident-details-new/:id",
  //   component: IncidentDetailNewComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     title: "incident-details-new",
  //   },
  // },
  {
    path: "incident-detail/:id",
    component: IncidentSummaryPreProtocolLaunchComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Incident Detail",
    },
  },
  {
    path: "new-incident",
    component: NewIncidentLaStoreUserComponent,
    canActivate: [AuthGuard],
    data: {
      title: "New Incident",
    },
  },
  {
    path: "new-incident-test",
    component: NewIncidentComponent,
    canActivate: [AuthGuard],
    data: {
      title: "New Incident",
    },
  },
  {
    path: "team-messaging-dashboard",
    component: TeamMessagingDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Team Messaging Dashboard",
    },
  },
  {
    path: "team-members",
    component: TeamMembersComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Team Members",
    },
  },
  {
    path: "stakeholder-messaging",
    component: StakeholderMessagingComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Stakeholder Messaging",
    },
  },
  {
    path: "stakeholder-members",
    component: StakeholderMembersComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Stakeholder Members",
    },
  },
  {
    path: "cst-template",
    component: CstTemplateComponent,
    canActivate: [AuthGuard],
    data: {
      title: "CST Template",
    },
  },
  {
    path: "cst-protocol-launch/:id",
    component: CstProtocolLaunchComponent,
    canActivate: [AuthGuard],
    data: {
      title: "CST Protocol Template",
    },
  },
  {
    path: "cst-template-create",
    component: CreateCstTemplateComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Create CST Template",
    },
  },
  {
    path: "cst-template-edit/:id",
    component: EditCstTemplateComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Edit CST Template",
    },
  },
  {
    path: "notes-overlay",
    component: NotesOverlayComponent,
    canActivate: [AuthGuard],
    data: {
      title: "",
    },
  },
  {
    path: "add-team-member",
    component: AddTeamMemberComponent,
    canActivate: [AuthGuard],
    data: {
      title: "",
    },
  },
  { path: "404", component: PageNotFoundComponent },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 25], // cool option, or ideal option when you have a fixed header on top.
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
