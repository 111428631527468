<app-home-nav-menu></app-home-nav-menu>


<div class="pt-15 Team_protocol_action local-auth-6">
  <div class="container">
    <h2 class="sec-title">New incident</h2>

    <div class="back-to-dashboard">
      <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png"
          alt="left-angle-primary"> Back to dashboard</a>
    </div>

    <div class="accordion tableaccordian incident-accord full-width" id="startincident">
      <div class="accordion-item">
        <h2 class="accordion-header with-link" id="incidentheading1">
          <button class="accordion-button bg_skyblue collapsed" type="button"
            data-bs-toggle="collapse" success
            data-bs-target="#incidentaccord1" aria-expanded="true"
            aria-controls="incidentaccord1">
            New incident
          </button>
          <a class="redirect-page" [routerLink]="'/'"></a>
        </h2>
        <div *ngIf="isFormLoaded && isBCURegionLoaded">
          <form [formGroup]="newIncidentForm" (ngSubmit)="onSubmit()">
            <div id="incidentaccord1" class="accordion-collapse collapse show"
              aria-labelledby="incidentheading1"
              data-bs-parent="#startincident">
              <div class="accordion-body">
                <div class="form-style">
                  <div class="fieldset">
                    <div class="form-label">
                      <label for="cadNumber">Title</label>
                    </div>
                    <div class="hs-input">
                      <div class="row">
                        <div class="col-12">
                          <input class="form-control" type="text"
                            formControlName="title">
                          <div *ngIf="(isSubmitted || title.touched)">
                            <span class="text-danger"
                              *ngIf="title.hasError('required')">
                              Title is required</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fieldset">
                    <div class="form-label">
                      <label for="startTime">Time</label>
                    </div>
                    <div class="hs-input">
                      <div class="row w-331">
                        <div class="col-md-4">
                          <input class="form-control"
                            formControlName="time"
                            type="time">
                        </div>
                      </div>
                      <div *ngIf="(isSubmitted || time.touched)">
                        <span class="text-danger"
                          *ngIf="time.hasError('required')">
                          Time is required
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="fieldset">
                    <div class="form-label">
                      <label for="startTime">Date</label>
                    </div>
                    <div class="hs-input">
                      <div class="row w-331">
                        <div class="col-md-4">
                          <div class="select-style">

                          <select class="form-control" formControlName="day">
                            <option value="">DD</option>
                            <option [value]="day.id" *ngFor="let day of Days">{{day.name}}</option>
                          </select>

                          <div *ngIf="(isSubmitted || day.touched)">
                            <span class="text-danger"
                              *ngIf="day.hasError('required')">
                              Day is required
                            </span>
                          </div>

                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-style">

                            <select class="form-control" formControlName="month">
                              <option value="">MM</option>
                              <option [value]="month.id" *ngFor="let month of Months">{{month.name}}</option>
                            </select>
  
                            <div *ngIf="(isSubmitted || month.touched)">
                              <span class="text-danger"
                                *ngIf="month.hasError('required')">
                                Day is required
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-style">

                            <select class="form-control" formControlName="year">
                              <option value="">YYYY</option>
                              <option [value]="year.id" *ngFor="let year of Years">{{year.name}}</option>
                            </select>

                            <div *ngIf="(isSubmitted || year.touched)">
                              <span class="text-danger"
                                *ngIf="year.hasError('required')">
                                Year is required
                              </span>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="fieldset">
                    <div class="form-label">
                      <label for="Offence">Local authority</label>
                    </div>
                    <div class="hs-input">
                      <div class="row w-331">
                        <div class="col-12">
                          <div class="select-style">
                            <select class="form-control"
                              formControlName="localAuthority">
                              <option [value]="group.organizationGroupName" *ngFor="let group of this.selectedRegion.groups">
                                {{group.organizationGroupName}}
                              </option>
                            </select>
                            <div *ngIf="(isSubmitted ||
                            localAuthority.touched)">
                            <span class="text-danger"
                              *ngIf="localAuthority.hasError('required')">
                              Local Authority is required</span>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- <div class="fieldset">
                    <div class="form-label">
                      <label for="initialLocation">location</label>
                    </div>
                    <div class="hs-input">
                      <div class="row">
                        <div class="col-12">
                          <input class="form-control location"
                            type="text" formControlName="location"
                            placeholder="Postcode,
                            address,
                            W3W, ">
                          <div *ngIf="(isSubmitted ||
                            newIncidentForm.controls.location.touched)">
                            <span class="text-danger"
                              *ngIf="newIncidentForm.controls.location.hasError('required')">
                              Location is required</span>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class="fieldset">
                    <div class="form-label">
                      <label for="initialLocation">Location</label>
                    </div>
                    <div class="hs-input">
                      <div class="row">
                        <div class="col-12">
                          <div id="pac-container">
                            <input class="form-control location" formControlName="location" #search id="pac-input" type="text" placeholder="location">
                            <div *ngIf="(isSubmitted || newIncidentForm.controls.location.touched)">
                            <span class="text-danger"
                              *ngIf="newIncidentForm.controls.location.hasError('required')">
                              Location is required</span>
                          </div>
                          </div>

                          <div class="map-iframe mt-3 bordered-div p-0">
                            <agm-map [latitude]="lat" [longitude]="lng"
                              [zoom]="zoom" [zoomControl]="true"
                              (mapClick)="mapClicked($event)"
                              [fullscreenControl]='true'
                              [mapTypeControl]='true'>
  
                              <agm-marker *ngFor="let m of markers; let i=
                                index"
                                [latitude]="m.lat" [longitude]="m.lng"
                                [visible]="m.visible"
                                [label]="m.label"
                                [markerDraggable]="m.draggable"
                                (dragEnd)="markerDragEnd(m, $event)"
                                [iconUrl]="{url: m.icon}">
                              </agm-marker>
                            </agm-map>
                          </div>
                        </div>

                        

                      </div>
                    </div>
                  </div>

                  

                  <div class="fieldset">
                    <div class="form-label">
                      <label for="incidentDetails">Incident details </label>
                    </div>
                    <div class="hs-input">
                      <div class="row">
                        <div class="col-12">
                          <textarea class="form-control"
                            formControlName="incidentDetail"
                            placeholder="Please do not enter any personal details."></textarea>
                          <div *ngIf="(isSubmitted ||
                            incidentDetail.touched)">
                            <span class="text-danger"
                              *ngIf="incidentDetail.hasError('required')">
                              Incident Detail is required</span>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fieldset ">
                    <div class="form-label">
                      <label for="displaced">People displaced?</label>
                    </div>
                    <div class="hs-input">
                      <div class="row w-331">
                        <div class="col-12 col-md-8">
                          <input type="checkbox" class="form-control
                            form-check-input" formControlName="peopleDisplaced">
                          <!-- <div *ngIf="(isSubmitted ||
                          peopleDisplaced.touched)">
                            <span class="text-danger"
                              *ngIf="peopleDisplaced.hasError('required')">
                              People displaced is required</span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fieldset">
                    <div class="form-label">
                      <label for="Offence">Offence category</label>
                    </div>
                    <div class="hs-input">
                      <div class="row w-331">
                        <div class="col-12">
                          <div class="select-style">
                            <select class="form-control"
                              formControlName="OffenceCategory">
                              <option selected=""></option>
                              <option value="Homicide">Homicide</option>
                              <option value="Violence with injury">Violence with injury</option>
                              <option value="Violence without injury">Violence without injury</option>
                              <option value="Other">Other</option>
                              <option value="Emergency planning">Emergency planning</option>
                              <option value="Unknown">Unknown</option>
                            </select>
                            <div *ngIf="(isSubmitted ||
                            OffenceCategory.touched)">
                            <span class="text-danger"
                              *ngIf="OffenceCategory.hasError('required')">
                              Offence category is required</span>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fieldset">
                    <div class="form-label">
                      <label for="OffenceSubGroup">Method</label>
                    </div>
                    <div class="hs-input">
                      <div class="row w-331">
                        <div class="col-12">
                          <div class="select-style">
                            <select class="form-control"
                              formControlName="method">
                              <option selected=""></option>
                              <option value="Gun crime">Gun crime</option>
                              <option value="Knife crime">Knife crime</option>
                              <option value="Acid">Acid</option>
                              <option value="Other">Other</option>
                              <option value="Unknown">Unknown</option>
                              <option value="Not applicable">Not applicable</option>
                            </select>
                            <div *ngIf="(isSubmitted ||
                            method.touched)">
                            <span class="text-danger"
                              *ngIf="method.hasError('required')">
                              Method is required</span>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fieldset mb-0 d-flex align-content-end justify-content-end">
                    <div class="form-label">
                    </div>
                    <div class="hs-submit">
                      <div class="dropdown local-action-btn action-final">

                        <div class="d-flex align-items-center justify-content-center" >
                          <mat-spinner id="btn-save" style="display: none;" diameter="30"></mat-spinner>
                        </div>

                        <button class="m-0 hsr-btn" type="submit" style="display: block;">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
