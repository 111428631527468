<app-home-nav-menu></app-home-nav-menu>

<div class="team-protocol-section pt-15 pb-50 local-auth-17">
  <div class="container cst-protocol Team_protocol_action">
    <div class="w-100">
      <h2 class="sec-title">CST Template</h2>

      <div class="back-to-dashboard">
        <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png" alt="left-angle-primary"> Back to dashboard</a>
      </div>

      <div class="accordion tableaccordian" id="contactaccordian" *ngIf="isBasicTemplatesLoaded && incidentLoaded && isTeamMembersLoaded">
        <div class="teamprotocol-heading with-dropdown">
          <div class="t_h2 main-dropdown-js">
            <h1>
              <!-- Protocol Response template for <span class="rel each-dropdown-js" rel="template-ul"> -->
                CST template for <span class="rel each-dropdown-js" rel="template-ul">
                {{!selectedTemplateForFilter ? 'template not selected' : GetTemplateTitle(selectedTemplateForFilter)}} </span>
              for <span class="rel each-dropdown-js" rel="team-member-ul"> 
                {{!selectedTeamMemberForFilter ? 'everyone' : selectedTeamMemberForFilter.firstName + ' ' + selectedTeamMemberForFilter.familyName}} </span>
            </h1>
            <div class="dropdown tab-title ">
              <button class="dropdown-toggle hsr-btn" type="button" id="launchprotocol2" data-bs-toggle="dropdown" aria-expanded="false">+ NEW</button>
              <ul class="dropdown-menu" aria-labelledby="launchprotocol2">
                <li><a class="no-img">Incident</a></li>
                <li><a class="no-img" (click)="CopyThisOne()">Copy this one</a></li>
              </ul>
            </div>
            <ul class="t-dropdown each-dropdown-content-js" id="template-ul">
              <li *ngIf="incidentLaunchData?.taskGroups && incidentLaunchData?.taskGroups.length > 0">
                <button type="button" class="link" (click)="TemplateSelected(null)">all</button></li>
              <li *ngFor="let template of incidentLaunchData?.taskGroups">
                <button type="button" class="link" (click)="TemplateSelected(template)">{{GetTemplateTitle(template)}}</button>
              </li>
            </ul>
            <ul class="t-dropdown each-dropdown-content-js" id="team-member-ul">
              <li><button type="button" class="link" (click)="TeamMemberSelected(null)">Everyone</button></li>
              <li *ngFor="let teamMember of teamMemebers">
                <button type="button" class="link" (click)="TeamMemberSelected(teamMember)">{{teamMember.firstName + ' ' + teamMember.familyName}}</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="accrord-subtitle">
          <span class="t_h2 main-dropdown-js">
            <span>Incident template title &nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span rel="t_dropdownTemplates" class="rel each-dropdown-js"><img src="assets/images/pencil-icon.png" alt="pencil-icon"> 
              {{(selectedTemplate != undefined && selectedTemplate != null) ? selectedTemplate.templateName : 'No Template Selected'}}
            </span>
            <ul id="t_dropdownTemplates" class="t-dropdown each-dropdown-content-js">
              <li *ngFor="let template of templates">
                <button type="button" class="link text-nowrap" (click)="basicTemplateSelected(template)">
                  {{template.templateName}}
                </button>
              </li>
            </ul>
            <div class="date-wrap">
              <p>Created: <span>22:30, April 10</span></p>
            </div>
          </span>

          <!-- <p>Incident template title &nbsp;&nbsp;&nbsp;&nbsp;<a><img src="assets/images/pencil-icon.png" alt="pencil-icon"> &nbsp;&nbsp;<input class="form-control" type="text" placeholder="" value=""></a></p>
          <div class="date-wrap">
            <p>Created: <span>22:30, April 10</span></p>
          </div> -->

        </div>


        <div *ngIf="isIncidentLaunchdataLoaded == 2">
          <div class="accordion-item" *ngFor="let incidentLaunch of incidentLaunchDataDeepCopy.taskGroups; trackBy: trackByFn; index as i">
          <h2 class="accordion-header" [attr.id]="'contactheading' + i">
            <button class="accordion-button bg-yellow-tb" type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#contactaccord' + i" aria-expanded="false"
              [attr.aria-controls]="'contactaccord' + i">
              {{GetTemplateTitle(incidentLaunch)}}
            </button>
          </h2>
          <div [attr.id]="'contactaccord' + i" class="accordion-collapse collapse"
            [attr.aria-labelledby]="'contactheading' + i"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="border-end-0">Person</th>
                      <th class="border-start-0 border-end-0">Action</th>
                      <th class="border-start-0 border-end-0 text-end">Timeframe</th>
                      <th class="border-start-0">
                        <!-- <input class="table-checkbox form-check-input border-style rounded-0" type="checkbox" value=""> -->
                        <input [id]="'taskCheckbox' + i" class="table-checkbox
                            border-check
                            form-check-input" [class]="'chkSame' + i"
                            type="checkbox" value=""
                            (change)="allCheckChanged($event, i,
                            incidentLaunch)">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let task of incidentLaunch.tasks; index as ii">
                      <td>
                        <div class="dropdown profile-dd not-required">
                          <button (click)="personClicked(teamMemebers, incidentLaunch, task, i, ii)" class="btn dropdown-toggle" type="button" id="incidentdropdown"> 
                            <img [src]="GetPersonImageSrc(task)" alt="user-profile" class="user-profile">
                            <span>{{GetStringOfTeamMembersList(task?.incidentTaskAssignees)}}</span>
                            <!-- <span>Not required</span> -->
                          </button>
                        </div>
                      </td>
                      <td class="border-start-0 border-end-0">{{task?.title}}</td>
                      <td class="text-end border-start-0 border-end-0">
                        <span *ngIf="task?.taskTimeFrame != undefined && task?.taskTimeFrame != null && task?.taskTimeFrame > 0" 
                        class="status-btn timeline">{{GetTimeFram(task?.taskTimeFrame)}}</span>
                      </td>
                      <td class="border-start-0">
                        <input class="table-checkbox border-check
                        form-check-input" [class]="'taskCheckbox' + i"
                        type="checkbox" (change)="checkboxChanged($event, task)">
                        <!-- <input class="table-checkbox form-check-input border-style" type="checkbox" value=""> -->
                      </td>
                      <!-- <td class="border-start-0">
                        <input class="table-checkbox form-check-input border-style" type="checkbox" value="">
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          
        </div>

        
      </div>
      <div *ngIf="isBasicTemplatesLoaded && isTeamMembersLoaded && isIncidentLaunchdataLoaded == 2">
        <div class="bottom-submit">
          <div class="dropdown  local-action-btn action-final">
            <button class="dropdown-toggle m-0 hsr-btn down-caret" type="button" id="launchprotocol2" data-bs-toggle="dropdown" aria-expanded="false">Edit</button>
            
            <ul class="dropdown-menu" aria-labelledby="launchprotocol2">
              <li *ngFor="let teamMember of teamMemebers">
                <div (click)="bulkUpdateClicked(teamMember)">
                  <a>
                    <img class="profile-image" [src]="teamMember.avatar ? teamMember.avatar : 'assets/images/profile-blank-icon.png'" alt="profile-icon">{{teamMember.firstName + ' ' + teamMember.familyName}}
                  </a>
                </div>
              </li>
            </ul>
          </div>

          <div class="top-margin">

            <div class="d-flex align-items-center justify-content-center right">
              <mat-spinner id="save-btn-spinner" style="display: none;" diameter="20"></mat-spinner>
            </div>

            <button id="btn-save" class="m-0 hsr-btn down-caret" type="button" (click)="SaveTemplate()">Save</button>
          </div>

        <!-- <div class="dropdown  local-action-btn action-final">
          <button class="dropdown-toggle m-0 hsr-btn down-caret" type="button" id="launchprotocol2" data-bs-toggle="dropdown" aria-expanded="false">Edit</button>
          <ul class="dropdown-menu" aria-labelledby="launchprotocol2">
            <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Mackenzie Thornton</a></li>
            <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Shania Barker</a></li>
            <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Romeo Foley</a></li>
            <li><a href="#"><img src="assets/images/pd-icon.png" alt="pd-icon">Carl Salazar</a></li>
          </ul>
        </div> -->
        </div>
      </div>
      
      <div class="divloader" *ngIf="isIncidentLaunchdataLoaded == 1 || !isBasicTemplatesLoaded || !incidentLoaded || !isTeamMembersLoaded">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</div>