import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core/interceptors/api.service';
import { LATeamMessagingDashboard } from 'src/app/Models/la-Team-messages/LATeamMessagingDashboard';

@Injectable({
  providedIn: 'root'
})
export class TeamMessagingDashboardService {

  constructor(
    private _apiService: ApiService
  ) { }

  getMessageQueueLogs() {
    const url = "/MessageQueue/logs";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getTeamMessagingDashboard() {
    const url = "/la/TeamMessaging/Dashboard";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  SortIncidents(incidents: LATeamMessagingDashboard[]){
    return incidents.sort((a, b) => {
      const aDate = new Date(a.dateTime);
      const bDate = new Date(b.dateTime);
      return bDate.getTime() - aDate.getTime();
    });
  }
  
}
