import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Accordion, CommonType, CreateIncidentDTO, IncidentDTO, Markers, MarkersForIncidentDetails, OrganizationGroup } from '../Models/incident.modal';
import { IncidentService } from '../initial-incident-form/incident.service';
import { BCURegionDTO } from '../Models/BCURegionDTO.Model';
import { ApiErrorService } from '../core/services/api-error.service';

@Component({
  selector: 'app-incident-location-details',
  templateUrl: './incident-location-details.component.html',
  styleUrls: ['./incident-location-details.component.css']
})
export class IncidentLocationDetailsComponent implements OnInit {
  
  //#region  Incident Tab
  IncidentDTO: IncidentDTO = new IncidentDTO();
  CreateIncidentDTO: CreateIncidentDTO = new CreateIncidentDTO();
  Accordion: Accordion = new Accordion();
  incidentForm: NgForm;
  incidentIsSubmit: boolean = false;
  Days: CommonType[] = []
  Months: CommonType[] = []
  Years: CommonType[] = []
  OrganizationGroup: OrganizationGroup[] = []
  subOrganizationGroup: OrganizationGroup[] = []
  lng: any;
  lat: any;
  markers: Markers[] = []
  IncidentMapShow: boolean = false;
  loadIncident: boolean = false;
  // google maps zoom level
  zoom: number = 11;
  scroll: boolean = false;
  BCURegionDTO: BCURegionDTO = new BCURegionDTO();
  IsPolicecordon: boolean = false;
  IsCrimescene: boolean = false;
  IsRendezvouspoint: boolean = false;
  MapShow: boolean = true;
  //#region  Mapshow
  LocationMainmarkers: MarkersForIncidentDetails[] = []
  LocationMainmarkersFirst: MarkersForIncidentDetails[] = []
  LocationMainmarkersSecond: MarkersForIncidentDetails[] = []
  //#endregion
  //#endregion
  constructor(
    private _incidentService: IncidentService, 
    private _activatedRoute: ActivatedRoute, 
    private _toastr: ToastrService, 
    private _titleService: Title, 
    private _apiErrorService: ApiErrorService) {
    if (this._activatedRoute.snapshot.paramMap != null && this._activatedRoute.snapshot.paramMap.get('id') != null) {
      this.IncidentDTO.incidentid = this._activatedRoute.snapshot.paramMap.get('id');
      if (this.IncidentDTO.incidentid != null && this.IncidentDTO.incidentid != undefined) {
        this.getIncidentDetails(this.IncidentDTO.incidentid)
      }
    } else {
      this.IncidentDTO.incidentid = '0';
    }
  }

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    this.getLocation();
  }
  getIncidentDetails(incidentid) {
    const self = this;
    self.loadIncident = true;
    self._incidentService.getIncidentDetails(incidentid).pipe(finalize(() => {
      self.loadIncident = false;
    })).subscribe(res => {
      if (res != null && res.statusCode == 200) {
        this.IncidentDTO = res.data;
        if (this.IncidentDTO != null && this.IncidentDTO.bcuLocationData != null) {
          if (this.IncidentDTO.bcuLocationData.locations != null && this.IncidentDTO.bcuLocationData.locations.length > 0) {
            let Policecordon = this.IncidentDTO.bcuLocationData.locations.filter(x => x.type == 0)//Police cordon
            if (Policecordon != null && Policecordon.length > 0) {
              this.IsPolicecordon = true;
            }
            let Rendezvouspoint = this.IncidentDTO.bcuLocationData.locations.filter(x => x.type == 1)//Rendezvous point
            if (Rendezvouspoint != null && Rendezvouspoint.length > 0) {
              this.IsRendezvouspoint = true;
            }
            let Crimescene = this.IncidentDTO.bcuLocationData.locations.filter(x => x.type == 2)//Crime scene
            if (Crimescene != null && Crimescene.length > 0) {
              this.IsCrimescene = true;
            }

          }
          if (this.IncidentDTO.latitude != null && this.IncidentDTO.longitude) {
            this.LocationMainmarkers.push({
              lat: Number(this.IncidentDTO.latitude),
              lng: Number(this.IncidentDTO.longitude),
              label: 'Incident location',
              visible: true,
              opacity: 0.7,
              icon: '../../assets/images/red-marker.png',
              ispolygon: false,
              path: []
            })
          }
          if (this.IncidentDTO.bcuLocationData != null && this.IncidentDTO.bcuLocationData.locations != null) {
            let indexPolicecordon = 1;
            let indexRendezvouspoint = 1;
            let indexCrimescene = 1;
            this.IncidentDTO.bcuLocationData.locations.forEach(element => {
              switch (element.type) {
                case 0://Police cordon //Polygoan
                  this.LocationMainmarkers.push({
                    lat: 0,
                    lng: 0,
                    label: 'Police cordon ' + indexPolicecordon,
                    visible: true,
                    opacity: 0.7,
                    icon: '../../assets/images/circle-lightblue.png',
                    ispolygon: true,
                    path: element.locationPoints,
                    color: '#80cff4'
                  })
                  indexPolicecordon++;
                  break;
                case 1://Rendezvous point  //drop pin
                let latArray : Array<number> = [];
                let lngArray : Array<number> = [];
                for (const iterator of element.locationPoints) {
                  latArray.push(iterator.lat);
                  lngArray.push(iterator.lng);
                }
                this.lat= Math.max(...latArray); 
                this.lng= Math.max(...lngArray);
                  this.LocationMainmarkers.push({
                    lat: Number(element.locationPoints[0].lat),
                    lng: Number(element.locationPoints[0].lng),
                    label: 'Rendezvous point ' + indexRendezvouspoint,
                    visible: true,
                    opacity: 0.7,
                    icon: '../../assets/images/maps-and-flags-lighblue.png',
                    ispolygon: false,
                    path: []
                  })
                  indexRendezvouspoint++;
                  break;
                case 2://Crime scene //Polygoan
                  this.LocationMainmarkers.push({
                    lat: 0,
                    lng: 0,
                    label: 'Crime scene ' + indexCrimescene,
                    visible: true,
                    opacity: 0.7,
                    icon: '../../assets/images/circle-purple.png',
                    ispolygon: true,
                    path: element.locationPoints,
                    color: '#c480b5'
                  })
                  indexCrimescene++;
                  break;

                default:
                  break;
              }

            });
          }
          if (this.IncidentDTO.section60 != null && this.IncidentDTO.section60.locality != null) {
            this.LocationMainmarkers.push({
              lat: 0,
              lng: 0,
              label: 'Section 60 ',
              visible: true,
              opacity: 0.7,
              icon: '../../assets/images/red-line.png',
              ispolygon: true,
              path: this.IncidentDTO.section60.locality,
              color: '#E52A12'
            })
          }
          if (this.IncidentDTO.section35 != null && this.IncidentDTO.section35.locality != null) {
            this.LocationMainmarkers.push({
              lat: 0,
              lng: 0,
              label: 'Section 35 ',
              visible: true,
              opacity: 0.7,
              icon: '../../assets/images/red-line.png',
              ispolygon: true,
              path: this.IncidentDTO.section35.locality,
              color: '#E52A12'
            })
          }
        }
        // const middleIndex = Math.ceil(this.LocationMainmarkers.length / 2);
        // let array = this.LocationMainmarkers
        // this.LocationMainmarkersFirst = array.slice().splice(0, middleIndex);
        // this.LocationMainmarkersSecond = array.slice().splice(-middleIndex);
      }
    }, (error => {
      this.IncidentDTO.incidentid = '0'
      this.IncidentDTO = new IncidentDTO();

      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this._apiErrorService.ShowErrorModal("Error", error.error.message);
        //this.toastr.error(error.error.message, "Error");
      }
      else{
        this._apiErrorService.ShowErrorModal();
        //this.toastr.error("Something went wrong!");
      }
    }))
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          if (this.lat == undefined || this.lat == null){
            this.lat = position.coords.latitude;
          }
          if (this.lng == undefined || this.lng == null){
            this.lng = position.coords.longitude;
          }
          this.markers = []

        }
      },
        (error: any) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
}
