import { Member } from './../Members/Member';
import { MemberCategoryDTO } from "../Messages/MessageTemplateDTO";

export class Stakeholder {

  public id: string;
  public firstName: string;
  public familyName: string;
  public email: string;
  public jobTitle: string;
  public employer: string;
  public phoneNo: string;
  public avatar: string;
  public organisationName: string;
  public partnerMessage: boolean | null;
  
}


export class StakeholderProfileCreate extends Stakeholder{
  public memberCategoryId : number;
  public groups : number;
}

export class StakeholderCategoryList extends MemberCategoryDTO {
  stakeholders: Stakeholder[] = [];
}

export class MemberCategoryListDTO extends MemberCategoryDTO {
  members: Member[] = [];
}
