import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/core/interceptors/api.service";
import { MessageQueueDTO } from "src/app/Models/incident.modal";

@Injectable({
  providedIn: "root",
})
export class IncidentMessageSendService {
  constructor(private _apiService: ApiService) {}

  GetIncidentTeam(id: string) {
    const url = `/la/IncidentTeam/${id}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetAllTeamMembers(){
    const url = "/La/TeamMembers";
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }


  GetStakeHolderCategories() {
    const url = `/Manager/Members/Categories/Stakeholders`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetStakeHolder() {
    const url = `/Manager/Members/Categories/Member`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetStakeHolderTeamMembers(groupId: number = 4) {
    const url = `/Manager/Stakeholders/ListStakeholdersByGroup/${groupId}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  

  getMessageQueueLogs(messageQueueDTO: MessageQueueDTO, memberType: number) {
    const url = `/la/enqueue/${memberType}`;
    let body;
    if (
      messageQueueDTO.toDeliveryAddresses == undefined ||
      messageQueueDTO.toDeliveryAddresses.length <= 0
    ) {
      body = {
        activeDateTime: messageQueueDTO.activeDateTime,
        from: messageQueueDTO.from,
        to: [...messageQueueDTO.to],
        subject: messageQueueDTO.subject,
        message: messageQueueDTO.message,
        relatedIncidentId: messageQueueDTO.relatedIncidentId,
        messageGroup: messageQueueDTO.messageGroup,
        deliveryChannel: messageQueueDTO.deliveryChannel,
        groupName: messageQueueDTO.groupName,
        logoUrl: messageQueueDTO.logoUrl,
      };

      
    } else {
      body = {
        activeDateTime: messageQueueDTO.activeDateTime,
        from: messageQueueDTO.from,
        to: [...messageQueueDTO.to],
        subject: messageQueueDTO.subject,
        message: messageQueueDTO.message,
        relatedIncidentId: messageQueueDTO.relatedIncidentId,
        messageGroup: messageQueueDTO.messageGroup,
        deliveryChannel: messageQueueDTO.deliveryChannel,
        groupName: messageQueueDTO.groupName,
        logoUrl: messageQueueDTO.logoUrl,
        toDeliveryAddresses: [...messageQueueDTO.toDeliveryAddresses],
      };
    }

    return this._apiService.post(url, body).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
