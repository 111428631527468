import { ToInformation } from './../../Models/la-Team-messages/LATeamMessagingDashboard';
import { MessageQueueDTO } from './../../Models/incident.modal';
import { IncidentMessageSendService } from './incident-message-send.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDeliveryChannelEnum, MessageGroupEnum } from 'src/app/Models/la-Team-messages/LATeamMessagingDashboard';
import { ToastrService } from 'ngx-toastr';
import { MemberProfile } from 'src/app/Models/Members/MemberProfile';
import { Member } from 'src/app/Models/Members/Member';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-incident-message-send',
  templateUrl: './incident-message-send.component.html',
  styleUrls: ['./incident-message-send.component.css']
})
export class IncidentMessageSendComponent implements OnInit {

  isSubmitted= false;
  sendMessageForm : FormGroup;
  messagedata : MessageQueueDTO;

  messsageToFromGroup : {name: string; memberId: string, avatar: string, jobTitle: string}[] = [];
  messsageToFromIndividuals : {name: string; memberId: string, avatar: string, jobTitle: string}[] = [];
  messsageTofinal: {name: string; memberId: string, avatar: string, jobTitle: string}[] = [];

  overlayData: {messageType : string, incidentId : string}
  currentlySignedInUser : any;
  currentlySignedInUserName : any;

  indivualMemeberList: {name: string; memberId: string, avatar: string, jobTitle: string}[] = [];
  
  toGroupOptions: {name: string; id: string}[] = [
    {id : '0', name : 'All team members'},
    {id : '1', name : 'All incident team members'}
  ];

  incidentTeamMembers : MemberProfile[];
  allTeamMembers : Member[];

  isIncidentTeamLoaded = false;
  isOrgenizationTeamLoaded = false;

  constructor(
    private _dialogRef: MatDialogRef<IncidentMessageSendComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fb : FormBuilder,
    private _incidentMessageSendService : IncidentMessageSendService,
    private _toastr : ToastrService,
    private _apiErrorService: ApiErrorService,
    private _appStartService: AppStartService
    ) { }

  ngOnInit(): void {
    this.overlayData = this._data.messageData;

      this._incidentMessageSendService.GetIncidentTeam(this.overlayData.incidentId)
      .subscribe(response => {
        //console.log(response);
        this.incidentTeamMembers = response.data;
        this.isIncidentTeamLoaded = true;
      },
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this._toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this._toastr.error("Something went wrong!");
        }
      });
  
      this._incidentMessageSendService.GetAllTeamMembers()
      .subscribe(response => {
        console.log(response);
        this.allTeamMembers = response.data;
        for (const teamMember of this.allTeamMembers) {
          this.indivualMemeberList.push(
            {
              name: `${teamMember.firstName} ${teamMember.familyName}`, 
              memberId: teamMember.id, 
              avatar: teamMember.avatar,
              jobTitle: teamMember.jobTitle
            })
        }
        this.isOrgenizationTeamLoaded = true;
      }, 
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this._toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this._toastr.error("Something went wrong!");
        }
      });

    this.initializForm();

    // this.sendMessageForm.controls["subSelector"].setValue('0');
    // this.sendMessageForm.controls["primeSelector"].setValue('0');

    // this._cognitoService.GetUser().then((response) => {
    //   this.currentlySignedInUser = response
    // });

    if (this.overlayData.messageType == "SMS"){
      this.sendMessageForm.controls.subject.clearValidators();
      this.sendMessageForm.controls.subject.updateValueAndValidity();
    }

    this.currentlySignedInUser = this._appStartService.currentCognitoUser;
    this.currentlySignedInUserName = `${this._appStartService.currentCognitoUser.attributes.given_name} ${this._appStartService.currentCognitoUser.attributes.family_name}`;

    // this._cognitoService.GetUser()
    // .then(user => {
    //   if (user){
    //     //console.log(user);
    //     this.currentlySignedInUser = user;
    //     this.currentlySignedInUserName = user.attributes.given_name + " " + user.attributes.family_name;
    //   }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });

    this.sendMessageForm.controls["toGroup"].valueChanges.subscribe(x => {
        if (x == '0'){
          this.sendMessageForm.controls["toGroup"].setValue('');

          for (const incidentTeamMeber of this.allTeamMembers) {
            if (this.messsageToFromGroup.findIndex(x => x.memberId == incidentTeamMeber.id) == -1){
              this.messsageToFromGroup.push(
                {
                  name: `${incidentTeamMeber.firstName} ${incidentTeamMeber.familyName}`, 
                  memberId: incidentTeamMeber.id, 
                  avatar: incidentTeamMeber.avatar,
                  jobTitle: incidentTeamMeber.jobTitle
                });

              this.indivualMemeberList = this.indivualMemeberList.filter((data) => {
                return data.memberId !== incidentTeamMeber.id;
              });
            }
          }

        }
        else if (x == '1'){
          this.sendMessageForm.controls["toGroup"].setValue('');

          for (const incidentTeamMeber of this.incidentTeamMembers) {
            if (this.messsageToFromGroup.findIndex(x => x.memberId == incidentTeamMeber.id) == -1){
              this.messsageToFromGroup.push(
                {
                  name: `${incidentTeamMeber.firstName} ${incidentTeamMeber.familyName}`,
                  memberId: incidentTeamMeber.id, 
                  avatar: incidentTeamMeber.avatar,
                  jobTitle: incidentTeamMeber.jobTitle
                });

              this.indivualMemeberList = this.indivualMemeberList.filter((data) => {
                return data.memberId !== incidentTeamMeber.id;
              });
            }
          }

        }
      
    });

    
  }

  initializForm(){
    this.sendMessageForm = this._fb.group({
      'subject' : [null, [Validators.required]],
      'messageContent' : [null, [Validators.required]],
      'toGroup' : [null],
    });
  }


  removeAllFromToGroupListClicked(){
    this.messsageToFromGroup = [];
    this.initializeIndivualMemeberList();
  }

  IsAvailableInIndiavidualListOrMesssageToFromIndividuals(member: {name: string; memberId: string, avatar: string, jobTitle: string}){
    return this.messsageToFromIndividuals.findIndex(x => x.memberId === member.memberId) === -1
      && this.indivualMemeberList.findIndex(x => x.memberId === member.memberId) == -1
  }


  removeFromToGroupListClicked(obj: {name: string; memberId: string, avatar: string, jobTitle: string}){
    this.messsageToFromGroup = this.messsageToFromGroup.filter(x => !(x.memberId === obj.memberId));
    // this.messsageToFromIndividuals = this.messsageToFromIndividuals.filter(x => !(x.memberId === obj.memberId));
    if (this.IsAvailableInIndiavidualListOrMesssageToFromIndividuals(obj)){
      this.indivualMemeberList.push(obj);
    }
  }

  removeAllFromToIndividualListClicked(){
    this.messsageToFromIndividuals = [];
    this.initializeIndivualMemeberList();
  }


  removeFromToIndividualListClicked(obj: {name: string; memberId: string, avatar: string, jobTitle: string}){
    this.messsageToFromIndividuals = this.messsageToFromIndividuals.filter(x => !(x.memberId === obj.memberId));
    this.indivualMemeberList.push(obj);
  }

  initializeIndivualMemeberList(){
    for (const teamMember of this.allTeamMembers) {
      if (this.IsAvailableInIndiavidualListOrMesssageToFromIndividuals(
        {
          name: `${teamMember.firstName} ${teamMember.familyName}`, 
          memberId: teamMember.id, 
          avatar: teamMember.avatar, 
          jobTitle: teamMember.jobTitle
        })){
        this.indivualMemeberList.push(
          {
            name: `${teamMember.firstName} ${teamMember.familyName}`, 
            memberId: teamMember.id, 
            avatar: teamMember.avatar,
            jobTitle: teamMember.jobTitle
          });
      }
    }
  }

  IndividualMemeberSelected(memeber: {name: string; memberId: string, avatar: string, jobTitle: string}){
    if (!memeber){
      return;
    }

    const member = this.indivualMemeberList.find(data => data.memberId == memeber.memberId);
    if (!member){
      return;
    }

    if (this.messsageToFromIndividuals.findIndex(x => x.memberId == member.memberId) == -1){
      this.messsageToFromIndividuals.push(member);

      this.indivualMemeberList = this.indivualMemeberList.filter((data) => {
        return data.memberId !== memeber.memberId;
      });
    }
  }

  onSubmit(){
    this.isSubmitted = true;

    

    if (this.sendMessageForm.invalid){
      //this.isSubmitted = false;
      return;
    }
    
    if ((this.messsageToFromGroup.length + this.messsageToFromIndividuals.length) <= 0){
      //this.isSubmitted = false;
      return;
    }

    if (this.messsageToFromGroup.length > 0){
      for (const member of this.messsageToFromGroup) {
        if (this.messsageTofinal.findIndex(x => x.memberId === member.memberId) == -1){
          this.messsageTofinal.push(member);
        }
      } 
    }

    if (this.messsageToFromIndividuals.length > 0){
      for (const member of this.messsageToFromIndividuals) {
        if (this.messsageTofinal.findIndex(x => x.memberId === member.memberId) == -1){
          this.messsageTofinal.push(member);
        }
      } 
    }

    if (this.messsageTofinal.length  <= 0){
      return;
    }

    const sendMessageSpinner = document.getElementById('send-message-spinner');
    if (sendMessageSpinner){
      sendMessageSpinner.style.display = 'block'
    }
    const btnSend = document.getElementById('btn-send');
    if (btnSend){
      btnSend.style.display = 'none'
    }

    this.messagedata = new MessageQueueDTO();
    // this.messagedata.to = this.messsageTo;
    
    // this.messagedata.to = ['3fa85f64-5717-4562-b3fc-2c963f66afa6'];
    
    this.messagedata.to = this.messsageTofinal.map(x => x.memberId);
    this.messagedata.message = this.sendMessageForm?.controls?.messageContent.value;
    this.messagedata.subject = this.sendMessageForm?.controls?.subject.value;
    if(this.overlayData.messageType == 'Mail'){
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.Email
    }
    else if (this.overlayData.messageType == 'SMS'){
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.SMS
    }
    else{
      this.messagedata.deliveryChannel = MessageDeliveryChannelEnum.None
    }
    this.messagedata.from = this.currentlySignedInUser.username;
    this.messagedata.messageGroup = MessageGroupEnum.Team;
    this.messagedata.relatedIncidentId = this.overlayData.incidentId;

    // this.messagedata.groupName = 'asd';
    // this.messagedata.logoUrl = 'asdad';
    // this.messagedata.toDeliveryAddresses = ['asd', 'xcv'];
    // this.messagedata.activeDateTime = new Date();
    // this.messagedata.relatedIncidentId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";

      this._incidentMessageSendService.getMessageQueueLogs(this.messagedata, 1)
      .subscribe((response) => {

        const sendMessageSpinner = document.getElementById('send-message-spinner');
        if (sendMessageSpinner){
          sendMessageSpinner.style.display = 'none'
        }
        const btnSend = document.getElementById('btn-send');
        if (btnSend){
          btnSend.style.display = 'block'
        }

        if (response.data == true){

          let toInfo : ToInformation[] = [];
          for (const messageTo of this.messsageTofinal) {
            const to = new ToInformation();
            to.avatar = messageTo.avatar;
            if (this.overlayData.messageType === "SMS"){
              to.to = this.allTeamMembers.find(x => x.id == messageTo.memberId)?.phoneNo
            }
            else{
              to.to = this.allTeamMembers.find(x => x.id == messageTo.memberId)?.email
            }
            toInfo.push(to);
          }

          const fromInfo = `${this.currentlySignedInUser.attributes?.given_name} ${this.currentlySignedInUser.attributes?.family_name}`;

         // = this.messsageTo.map(x => { to: x.memberId; avatar: x.avatar});
          const result = {...this.messagedata, toInfo:toInfo, fromInfo: fromInfo }
          this._dialogRef.close(result);
        }
        else{
          this._toastr.error("Something went wrong!");
          console.log(response);
        }
      }, 
      (error) => {
        const sendMessageSpinner = document.getElementById('send-message-spinner');
        if (sendMessageSpinner){
          sendMessageSpinner.style.display = 'none'
        }
        const btnSend = document.getElementById('btn-send');
        if (btnSend){
          btnSend.style.display = 'block'
        }

        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this._toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this._toastr.error("Something went wrong!");
        }
      });
    
  }

}
