import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeNavMenuService {

  dashboardLoadedEventEmmiter = new EventEmitter<string>();
  constructor() { }
}
