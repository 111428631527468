<app-home-nav-menu [componentName]="'team-messaging-dashboard'"></app-home-nav-menu>



<div class="team-protocol-section pt-15 local-auth-9">
  <div class="container cst-protocol Team_protocol_action">

    <h2 class="sec-title">Team Messges</h2>

    <div class="back-to-dashboard">
      <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png"
          alt="left-angle-primary"> Back to dashboard</a>
    </div>

    <div class="w-80" *ngIf="!isLoading">
      <div class="row">
        <div class="msg-indicat">
          <span class="Team-Messges-dot"> Team Messges</span>
          <span class="Partner-Messages-dot"> Partner Messages</span>
          <span class="IIR-Messages-dot"> IIR Messagess</span>
        </div>
      </div>
      <div class="accordion tableaccordian  " id="contactaccordian">


        <div class="accordion-item" *ngFor="let teamMessagingIncident of teamMessagesIncedents; index as i">
          <h2 class="accordion-header" [id]="'contactheading' + i">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#contactaccord' + i" [attr.aria-expanded]="i === 0 ? true : false" [attr.aria-controls]="'contactaccord' + i">
              {{teamMessagingIncident.title}} <span>{{teamMessagingIncident.location}}, {{ teamMessagingIncident.dateTime | date: 'HH:mm, MMMM d' }}</span>
              <div class="message-icon">
                <!-- <a href=""><img (click)="mailClicked()" src="assets/images/blue-mail-icon.png" alt=""> </a> -->
                <span (click)="sendMessageClicked('Mail', teamMessagingIncident.incidentId)">
                  <img src="assets/images/blue-mail-icon.png" alt=""> 
                </span>
                <span (click)="sendMessageClicked('SMS', teamMessagingIncident.incidentId)">
                  <img src="assets/images/Green-mail-icon.png" alt=""> 
                </span>
              </div>
            </button>
          </h2>
          <div [id]="'contactaccord' + i" class="accordion-collapse collapse" [class]="i === 0 ? 'show' : ''"
          [attr.aria-labelledby]="'contactheading' + i"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover team-messeging-dashboard-table">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <!-- <th>To</th> -->
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="noMessagesTr" *ngIf="teamMessagingIncident.messages.length === 0; else elseBlock">
                      <i>No messsages sent yet.</i>
                    </tr>
                    <ng-template #elseBlock>
                      <tr *ngFor="let message of teamMessagingIncident.messages" class="IIR-Messages showHand" (click)="IncidentMessageClicked(message)">
                        <td class="messageContent"><strong>{{message.type === "1" ? "Mail": "SMS"}}</strong> &nbsp; <span>{{message.content | sorterString}}</span></td>
                        <!-- <td>{{messageToStringBuilder(message.to)}}</td> -->
                        <td>{{message.sent | date: 'HH:mm, MMMM d'}}</td>
                      </tr>
                    </ng-template>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>



        <!-- <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading1">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord1" aria-expanded="true" aria-controls="contactaccord1">
              Gang Violence <span>Outside 218 Francis Road E10, 22:30, April 10</span>
              <div class="message-icon">
                 <a href=""><img (click)="mailClicked()" src="assets/images/blue-mail-icon.png" alt=""> </a> 
                <span (click)="sendMessageClicked('Mail')"><img src="assets/images/blue-mail-icon.png" alt=""> </span>
                <span (click)="sendMessageClicked('SMS')"><img src="assets/images/Green-mail-icon.png" alt=""> </span>
              </div>
            </button>
          </h2>
          <div id="contactaccord1" class="accordion-collapse collapse show" aria-labelledby="contactheading1"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <th>To</th>
                      <th>Time</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr class="IIR-Messages" (click)="IncidentMessageClicked(message)">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="IIR-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading2">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord2" aria-expanded="false" aria-controls="contactaccord2">
              Non-fatal multiple stabbing <span>Outside 218 Francis Road E10, 22:30, April 10</span>
              <div class="message-icon">
                <a href=""><img src="assets/images/blue-mail-icon.png" alt=""> </a>
                <a href=""><img src="assets/images/Green-mail-icon.png" alt=""> </a>
              </div>
            </button>
          </h2>
          <div id="contactaccord2" class="accordion-collapse collapse " aria-labelledby="contactheading2"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <th>To</th>
                      <th>Time</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr class="IIR-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="IIR-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading3">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord3" aria-expanded="false" aria-controls="contactaccord3">
              Attempted Murder/firearms <span>Outside 218 Francis Road E10, 22:30, April 10</span>
              <div class="message-icon">
                <a href=""><img src="assets/images/blue-mail-icon.png" alt=""> </a>
                <a href=""><img src="assets/images/Green-mail-icon.png" alt=""> </a>
              </div>
            </button>
          </h2>
          <div id="contactaccord3" class="accordion-collapse collapse " aria-labelledby="contactheading3"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <th>To</th>
                      <th>Time</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr class="IIR-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="IIR-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="contactheading4">
            <button class="accordion-button  " type="button" data-bs-toggle="collapse"
              data-bs-target="#contactaccord4" aria-expanded="false" aria-controls="contactaccord4">
              Multiple fatal stabbing <span>Outside 218 Francis Road E10, 22:30, April 10</span>
              <div class="message-icon">
                <a href=""><img src="assets/images/blue-mail-icon.png" alt=""> </a>
                <a href=""><img src="assets/images/Green-mail-icon.png" alt=""> </a>
              </div>
            </button>
          </h2>
          <div id="contactaccord4" class="accordion-collapse collapse " aria-labelledby="contactheading4"
            data-bs-parent="#contactaccordian">
            <div class="accordion-body">
              <div class="table-responsive local-action-table accord-table">
                <table class="table table-hover">
                  <thead>
                    <tr class="active">
                      <th>Messages</th>
                      <th>To</th>
                      <th>Time</th>

                    </tr>
                  <tbody>
                    <tr class="IIR-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="IIR-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Partner-Messages">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>SMS</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                    <tr class="Team-Messges">
                      <td><strong>Email</strong> &nbsp; Lorem ipsum dolor, sit amet consectetur adipisicing elit.</td>
                      <td>Incident Team +</td>
                      <td>22:30, April 10</td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</div>
<div class="divloader" *ngIf="isLoading">
  <mat-spinner diameter="50"></mat-spinner>
</div>


<!-- <div class=" py-50 second-incident-sec team_messaging">
  <div class="container">
    <div class="table-responsive table-style local-authority-accord">
      <table class="table table-hover table-bordered">
        <thead class="incident_toggle-view-js active">
          <tr>
            <th class="incident_toggle incident_toggle-js">
              <span class="text-down-angle">
                Gang Violence
              </span>
            </th>
            <th>
              <span class="inc-title">IIR <br> Messages</span>
              <span class="text-circle pending">2</span>
            </th>
            <th>
              <span class="inc-title">Partner <br> Messages</span>
              <span class="text-circle response_in_progress">2</span>
            </th>
            <th>
              <span class="inc-title">Team <br> Messages</span>
              <span class="text-circle protocol_in_progress">2</span>
            </th>
            <th class="align-middle">
              <span class="big-blue">Send SMS</span>
            </th>
            <th class="align-middle">
              <span class="big-blue">Send Email</span>
            </th>
          </tr>
        </thead>
        <tbody class="incident_toggle-content-js show-content">
          <tr class="pending-line">
            <td colspan="2">
              <strong>Gang violence</strong> Outside 218 Francis Road E10
              <p>Lorem ipsum dolor sit amet, consectetuer <br>
                adipiscing elit, sed diam nonummy nibh</p>
            </td>
            <td colspan="2" class="align-top">
              Incident Team +
            </td>
            <td colspan="2" class="align-top">
              Needs approval
            </td>
          </tr>
          <tr class="pending-line">
            <td colspan="2">
              <strong>Fatal Shooting</strong> Peter May House, London E17 6JG
            </td>
            <td colspan="2" class="align-top">
              Incident Team +
            </td>
            <td colspan="2" class="align-top">
              Needs approval
            </td>
          </tr>
          <tr class="response_inprogress-line">
            <td colspan="2">
              <strong>Non-fatal multiple stabbing</strong> Blackthorn Court E15
            </td>
            <td colspan="2" class="align-top">
              Incident Team +
            </td>
            <td colspan="2" class="align-top">
              22:30, April 10
            </td>
          </tr>
          <tr class="response_inprogress-line">
            <td colspan="2">
              <strong>UME</strong> Valance Lane, E14
            </td>
            <td colspan="2" class="align-top">
              Incident Team +
            </td>
            <td colspan="2" class="align-top">
              22:30, April 10
            </td>
          </tr>
          <tr class="protocol_inprogress-line">
            <td colspan="2">
              <strong>Attempted Murder/firearms</strong> 51-89 Rochdale Rd, E17 8JW
            </td>
            <td colspan="2" class="align-top">
              Incident Team +
            </td>
            <td colspan="2" class="align-top">
              22:30, April 10
            </td>
          </tr>
          <tr class="protocol_inprogress-line">
            <td colspan="2">
              <strong>Multiple fatal stabbing</strong> 18 Navy Street Rd, E17 9EW
            </td>
            <td colspan="2" class="align-top">
              Incident Team +
            </td>
            <td colspan="2" class="align-top">
              22:30, April 10
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div> -->

<!-- <div class="py-50 pt_sm_0 message-form">
  <div class="container">
    <div class="accordion tableaccordian" id="sentmessage">
      <div class="accordion-item">
        <h2 class="accordion-header" id="sendheading1">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sentaccord1"
            aria-expanded="true" aria-controls="sentaccord1">
            Incident message
          </button>
        </h2>
        <div id="sentaccord1" class="accordion-collapse collapse show" aria-labelledby="sentheading11"
          data-bs-parent="#sentmessage">
          <div class="accordion-body">
            <div class="form-style">
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Email</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Sent</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Jan 21, 2022, 11:38:35 AM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>From</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="team-member-name">
                        <img src="./assets/images/rd-icon.png" alt="rd-icon">
                        <span>Peter Dickenson</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>(approved by Xxxx
                          Xxxxxx)</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>To</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-382">
                    <div class="col-12 col-sm-6 mb-4 mb-sm-0">
                      <div class="team-member-group">
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Peter Dickenson</span>
                          </div>

                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mary Jame</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Andrew Downie</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="team-member-group">
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Peter Dickenson</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mary Jame</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Andrew Downie</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Subject</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Attempted Murder/firearms discharge, <br> 51-89 Rochdale Rd, London E17 8JW, UK</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Message</strong></label>
                </div>
                <div class="hs-input mb-50">
                  <div class="row w-490">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                          euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                          minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis.</p>
                          <p>Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                            nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                            enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl
                            ut aliquip ex ea commodo consequat.</p>
                          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                            minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                            aliquip ex ea commodo consequat. Duis</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="py-50 pt_sm_0 message-form">
  <div class="container">
    <div class="accordion tableaccordian" id="aprovemessage">
      <div class="accordion-item">
        <h2 class="accordion-header" id="aproveheading1">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aproveaccord1"
            aria-expanded="true" aria-controls="aproveaccord1">
            Incident message
          </button>
        </h2>
        <div id="aproveaccord1" class="accordion-collapse collapse show" aria-labelledby="aproveheading11"
          data-bs-parent="#aprovemessage">
          <div class="accordion-body">
            <div class="form-style">
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Email</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Sent</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Jan 21, 2022, 11:38:35 AM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>From</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="team-member-name">
                        <img src="./assets/images/rd-icon.png" alt="rd-icon">
                        <span>Peter Dickenson</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>(pen)</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>To</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-382">
                    <div class="col-12 col-sm-6 mb-4 mb-sm-0">
                      <div class="team-member-group">
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Peter Dickenson</span>
                          </div>

                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mary Jame</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Andrew Downie</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="team-member-group">
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Peter Dickenson</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mary Jame</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Andrew Downie</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Subject</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Attempted Murder/firearms discharge, <br> 51-89 Rochdale Rd, London E17 8JW, UK</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset border-bottom-0">
                <div class="form-label">
                  <label for="sendmail"><strong>Message</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-490">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                          euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                          minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis.</p>
                          <p>Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                            nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                            enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl
                            ut aliquip ex ea commodo consequat.</p>
                          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                            minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                            aliquip ex ea commodo consequat. Duis</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset hs-submit mb-3">
                <div class="form-label">
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 text-end">
                      <button type="button" class="primary-btn">Approve</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="py-50 pt_sm_0 message-form">
  <div class="container">
    <div class="accordion tableaccordian" id="newmessage">
      <div class="accordion-item">
        <h2 class="accordion-header" id="newheading1">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#newaccord1"
            aria-expanded="true" aria-controls="newaccord1">
            Incident message
          </button>
        </h2>
        <div id="newaccord1" class="accordion-collapse collapse show" aria-labelledby="newheading11"
          data-bs-parent="#newmessage">
          <div class="accordion-body">
            <div class="form-style">
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Email</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>Sent</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="para-text">
                        <p>Jan 21, 2022, 11:38:35 AM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>From</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12">
                      <div class="team-member-name">
                        <img src="./assets/images/rd-icon.png" alt="rd-icon">
                        <span>Peter Dickenson</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>(pen)</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset">
                <div class="form-label">
                  <label for="sendmail"><strong>To</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-382">
                    <div class="col-12 col-sm-6 mb-4 mb-sm-0">
                      <div class="team-member-group">
                        <select name="AllTeamMembers" id="teammembers">
                          <option value=""></option>
                          <option value="Peter Dickenson">All team members</option>
                          <option value="Joanne Smith">All incident team members</option>
                        </select>
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Peter Dickenson</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mary Jame</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Joanne Smith</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Andrew Downie</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="team-member-group">
                        <select name="AllincidentTeamMembers" id="incidentTeamMembers">
                          <option value=""></option>
                          <option value="Mackenzie Thornton">Mackenzie Thornton</option>
                          <option value="Shania Barker">Shania Barker</option>
                          <option value="Romeo Foley">Romeo Foley</option>
                          <option value="Carl Salazar">Carl Salazar</option>
                          <option value="Marnie Schmidt">Marnie Schmidt</option>
                          <option value="Mitchell Lee">Mitchell Lee</option>
                          <option value="Sameer Carrillo">Sameer Carrillo</option>
                          <option value="Anish Hamilton">Anish Hamilton</option>
                          <option value="Karina Odom">Karina Odom</option>
                          <option value="Crystal Lindsay">Crystal Lindsay</option>
                          <option value="Arman Weaver">Arman Weaver</option>
                        </select>
                        <div class="team-member-item">
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Mackenzie Thornton</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Shania Barker</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Romeo Foley</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Carl Salazar</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                          <div class="team-member-name">
                            <img src="./assets/images/rd-icon.png" alt="rd-icon">
                            <span>Marnie Schmidt</span>
                            <span class="name-close">[ x ]</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset border-bottom-0">
                <div class="form-label">
                  <label for="sendmail"><strong>Subject</strong></label>
                </div>
                <div class="hs-input msg-subject">
                  <div class="row w-490">
                    <div class="col-12">
                      <textarea class="form-control" id="messagesubject" placeholder="Lorem." required=""></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset border-bottom-0">
                <div class="form-label">
                  <label for="sendmail"><strong>Message</strong></label>
                </div>
                <div class="hs-input">
                  <div class="row w-490">
                    <div class="col-12">
                      <textarea class="form-control" id="message" placeholder="Lorem." required=""></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fieldset hs-submit">
                <div class="form-label">
                </div>
                <div class="hs-input">
                  <div class="row">
                    <div class="col-12 text-end">
                      <button type="button" class="primary-btn">Send</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->