import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  ConvertDateToSend(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Construct the string representation in the format 'YYYY-MM-DDTHH:mm:ss'
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  }

  RetrieveDate(date: string) {
    const dateParts = date.split("T")[0].split("-");
    const timeParts = date.split("T")[1].split(".");

    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[2]);
    const hours = parseInt(timeParts[0].split(":")[0]);
    const minutes = parseInt(timeParts[0].split(":")[1]);
    const seconds = parseInt(timeParts[0].split(":")[2]);

    // Reconstruct the Date object with the extracted components
    return new Date(year, month, day, hours, minutes, seconds);
  }
}
