import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LAIncidentLaunchDTO, IncidentLaunchTask, IncidentTaskAssignee, SaveProtocolLaunchDTO, SaveProtocolLaunchItemDTO } from 'src/app/Models/LAIncedenceDashboard/LAIncidentDashboardModel';
import { MemberProfile } from 'src/app/Models/Members/MemberProfile';
import { BasicTemplateInfo } from 'src/app/Models/Templates/Template';
import { ProtocolTemplateDTO } from 'src/app/Models/cst-template/ProtocolTemplateDTO';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { LoadScriptService } from 'src/app/home-dashboard/load-script.service';
import { IncidentService } from 'src/app/initial-incident-form/incident.service';
import { AssignMemberPopupComponent } from 'src/app/overlays/assign-member-popup/assign-member-popup.component';
import { CstProtocolLaunchService } from '../cst-protocol-launch/cst-protocol-launch.service';
import { ImmediateResponseService } from 'src/app/home-dashboard/immediate-response/immediate-response.service';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';

@Component({
  selector: 'app-edit-cst-template',
  templateUrl: './edit-cst-template.component.html',
  styleUrls: ['./edit-cst-template.component.css']
})
export class EditCstTemplateComponent  implements OnInit, OnDestroy {

  incidentId: string;
  incident: any;
  templates: BasicTemplateInfo[] = [];
  selectedTemplate: BasicTemplateInfo;
  isBasicTemplatesLoaded = false;

  incidentLoaded = false;
  isLaunched = false;

  incidentLaunchData: LAIncidentLaunchDTO;
  incidentLaunchDataDeepCopy: LAIncidentLaunchDTO;
  isIncidentLaunchdataLoaded: number = 0;

  teamMemebers: MemberProfile[] = [];
  isTeamMembersLoaded: boolean = false;

  selectedCheckbox: IncidentLaunchTask[] = [];

  selectedTeamMemberForBulkUpdate: MemberProfile;

  dataLoadedEvent = new Subject<boolean>();
  dataLoadedEventSubscription: Subscription;

  selectedTeamMemberForFilter: MemberProfile;
  selectedTemplateForFilter: IncidentLaunchTask;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _loadScriptSerivice: LoadScriptService,
    private _toastr: ToastrService,
    private _cstProtocolLaunchService: CstProtocolLaunchService,
    private _router: Router,
    private _immediateResponseService: ImmediateResponseService,
    private _incidentService: IncidentService,
    private _dialog: MatDialog,
    private _apiErrorService: ApiErrorService,
    public _urlHelperService: UrlHelperService
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    //let sadfas = this._activatedRoute.snapshot.getCurrentNavigation().extras.state.myObject;

    this.dataLoadedEventSubscription = this.dataLoadedEvent.subscribe((x) => {
      if (x) {
        let timerId = setInterval(() => {
          if (this.isBasicTemplatesLoaded && this.incidentLoaded && this.isTeamMembersLoaded){
            if (this._loadScriptSerivice.ShouldDropdownScriptLoad()){
              this._loadScriptSerivice.loadScript("Edit-CSTProtocolScriptTag");
              clearInterval(timerId);
            }
          }
        }, 100);
      }
    });

    this._activatedRoute.params.subscribe((params) => {
      if (params.id != null && params.id != undefined) {
        this.incidentId = params.id;

        if (this.incidentId){

          if (!this.isTeamMembersLoaded) {
            this._immediateResponseService
              .GetTeamMembersForIncident(this.incidentId)
              .subscribe(
                (response) => {
                  if (response && response.data && response.statusCode == 200) {
                    this.isTeamMembersLoaded = true;
                    this.teamMemebers = response.data;
                    this.sortTeamMemberByName();
                    this.dataLoadedEvent.next(true);
                    //console.log(response.data);
                  }
                },
                (error) => {
                  if (error && error.error && error.error.message){
                    console.log("Error:" + JSON.stringify(error));
                    this._apiErrorService.ShowErrorModal("Error", error.error.message);
                    //this._toastr.error(error.error.message, "Error");
                  }
                  else{
                    this._apiErrorService.ShowErrorModal();
                    //this._toastr.error("Something went wrong!");
                  }
                }
              );
          }
  
          this._incidentService.getIncidentDetails(this.incidentId).subscribe(response => {
            if (response && response.data && response.statusCode == 200) {
              this.incident = response.data;
              this.incidentLoaded = true;
              this.dataLoadedEvent.next(true);
              //console.log(this.incident);
            }
          },
          (error) => {
            if (error && error.error && error.error.message){
              console.log("Error:" + JSON.stringify(error));
              this._apiErrorService.ShowErrorModal("Error", error.error.message);
              //this._toastr.error(error.error.message, "Error");
            }
            else{
              this._apiErrorService.ShowErrorModal();
              //this._toastr.error("Something went wrong!");
            }
          });
        }
        
      }
    });

    this._cstProtocolLaunchService.GetCSTTemplates().subscribe(
      (data) => {
        if (data.statusCode == 200) {
         
          //console.log(data);
          this.templates = data.data;
          let templateForEdit = this.templates.find(x => x.templateId === this.incidentId);
          this.isBasicTemplatesLoaded = true;
          this.dataLoadedEvent.next(true);

          // if (templateForEdit && !this.isNullOrWhiteSpace(templateForEdit.templateId)){
          //   this.selectedTemplate = templateForEdit;
          //   this.basicTemplateSelected(this.selectedTemplate);
          // }
          
          
        }
      },
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this._toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this._toastr.error("Something went wrong!");
        }
      }
    );
  }

  trackByFn(index: number, item: IncidentLaunchTask) {
    return index;
  }

  // templateSelected(selectedTemplate: BasicTemplateInfo) {
  //   this.isIncidentLaunchdataLoaded = 1;
  //   this.incidentLaunchData = null;
  //   this.selectedCheckbox = [];
    
  //   if (selectedTemplate == null && selectedTemplate == undefined) {
  //     return;
  //   }

  //   document.getElementById("t_dropdownTemplates").classList.remove("shown");

  //   this.selectedTemplate = selectedTemplate;

    

  //   this._cstProtocolLaunchService
  //     .GetCSTTemplatesActions(this.incidentId, selectedTemplate.templateId)
  //     .subscribe(
  //       (response) => {
  //         //console.log(response);
  //         if (response && response.data && response.statusCode == 200) {
  //           this.incidentLaunchData = response.data;

  //           if (this.incidentLaunchData && this.incidentLaunchData.taskGroups && this.incidentLaunchData.taskGroups.length > 0){
  //             this.sortByTitalAndTimeframe();
  
  //             console.log(response.data);
  //             // if (this.incidentLaunchData){
  //             //   for (const taskGroup of this.incidentLaunchData.taskGroups) {
  //             //     if (taskGroup && taskGroup.tasks && taskGroup.tasks.length > 0){
  //             //       for (const task of taskGroup.tasks) {
  //             //         if (task && task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0){
  //             //           for (const assignee of task.incidentTaskAssignees) {
  //             //             if (assignee && assignee.assigneeId && assignee.assigneeName){
  //             //               if (!this.teamMemebers.some(x => x.toLocaleLowerCase() === assignee.assigneeName.toLocaleLowerCase())){
  //             //                 this.teamMemebers.push(assignee.assigneeName);
  //             //               }
  //             //             }
  //             //           }
  //             //         }
  //             //       }
  //             //     }
  //             //   }
  //             // }
  //             this.isIncidentLaunchdataLoaded = 2;
  //           }
  //         }
  //       },
  //       (error) => {
  //         if (error && error.error && error.error.message){
  //           console.log("Error:" + JSON.stringify(error));
  //           this._apiErrorService.ShowErrorModal("Error", error.error.message);
  //           //this._toastr.error(error.error.message, "Error");
  //         }
  //         else{
  //           this._apiErrorService.ShowErrorModal();
  //           //this._toastr.error("Something went wrong!");
  //         }
  //       }
  //     );
  // }




  basicTemplateSelected(selectedTemplate: BasicTemplateInfo) {
    this.isIncidentLaunchdataLoaded = 1;
    this.incidentLaunchData = null;
    this.selectedCheckbox = [];
    
    if (selectedTemplate == null && selectedTemplate == undefined) {
      return;
    }

    document.getElementById("t_dropdownTemplates").classList.remove("shown");

    this.selectedTemplate = selectedTemplate;

    this._cstProtocolLaunchService
      .GetEditTemplate(this.incidentId)
      .subscribe(
        (response) => {
          //console.log(response);
          if (response && response.data && response.statusCode == 200) {
            console.log(response.data);

            this.incidentLaunchData = response.data;
            
            if (this.incidentLaunchData && this.incidentLaunchData.taskGroups && this.incidentLaunchData.taskGroups.length > 0){
              this.sortByTitalAndTimeframe();
              this.incidentLaunchDataDeepCopy = JSON.parse(JSON.stringify(this.incidentLaunchData));
  
              this.isIncidentLaunchdataLoaded = 2;
            }
          }
        },
        (error) => {
          if (error && error.error && error.error.message){
            console.log("Error:" + JSON.stringify(error));
            this._apiErrorService.ShowErrorModal("Error", error.error.message);
            //this._toastr.error(error.error.message, "Error");
          }
          else{
            this._apiErrorService.ShowErrorModal();
            //this._toastr.error("Something went wrong!");
          }
        }
      );
  }

  TemplateSelected(template: IncidentLaunchTask){
    document.getElementById("template-ul").classList.remove("shown");

    this.selectedTemplateForFilter = template;

    this.filterData();
  }


  TeamMemberSelected(teamMemebr: MemberProfile | null){
    document.getElementById("team-member-ul").classList.remove("shown");

    this.selectedTeamMemberForFilter = teamMemebr;

    this.filterData();
  }

  

  sortByTitalAndTimeframe(){
    this.incidentLaunchData.taskGroups.sort((a, b) => {
      // Sort by title first
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
    
      // If title are the same, sort by taskTimeFrame
      if (a.taskTimeFrame < b.taskTimeFrame) {
        return -1;
      }
      if (a.taskTimeFrame > b.taskTimeFrame) {
        return 1;
      }
    
      return 0; // If both name and number are equal
    });
  }

  sortTeamMemberByName(){
    this.teamMemebers.sort((a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    });
  }

  allCheckChanged(event: Event, index: number, incident: IncidentLaunchTask) {
    const tagClass = (event.target as HTMLElement).id;
    const tasks = incident?.tasks;
    if (tagClass != null && tagClass != undefined) {
      if (tasks != null && tasks != undefined && tasks.length > 0) {
        const chk = event.target as HTMLInputElement;
        if (chk.checked) {
          for (const task of tasks) {
            if (!this.selectedCheckbox.some((item) => item === task)) {
              this.selectedCheckbox.push(task);
            }
          }
        } else {
          this.selectedCheckbox = this.selectedCheckbox.filter(
            (item1) => !tasks.some((item2) => item1.id === item2.id)
          );
        }
      }

      const checkboxes = document.querySelectorAll(
        "." + tagClass
      ) as NodeListOf<HTMLInputElement>;
      if (
        checkboxes != null &&
        checkboxes != undefined &&
        checkboxes.length > 0
      ) {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = (event.target as HTMLInputElement).checked;
        });
      }
      //#region common checkbox logic
      // const commonCheckbox = document.querySelectorAll(
      //   "." + "chkSame" + index
      // ) as NodeListOf<HTMLInputElement>;
      // if (
      //   commonCheckbox != null &&
      //   commonCheckbox != undefined &&
      //   commonCheckbox.length > 0
      // ) {
      //   commonCheckbox.forEach((checkbox) => {
      //     checkbox.checked = (event.target as HTMLInputElement).checked;
      //   });
      // }
      //#endregion
    }
  }

  checkboxChanged(event, task: IncidentLaunchTask) {
    if (event.target.checked) {
      if (!this.selectedCheckbox.some((item) => item === task)) {
        this.selectedCheckbox.push(task);
      }
    } else {
      this.selectedCheckbox = this.selectedCheckbox.filter(
        (item) => item !== task
      );
    }
  }

  bulkUpdateClicked(teamMember: MemberProfile) {
    this.selectedTeamMemberForBulkUpdate = teamMember;

    this.incidentLaunchData.taskGroups.forEach((teamGroup) => {
      if (teamGroup.tasks != null && teamGroup.tasks.length > 0) {
        teamGroup.tasks.forEach((task) => {
          if (task != null && task.id) {
            if (this.selectedCheckbox.some((x) => x.id == task.id)) {
              if (task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0){
                task.incidentTaskAssignees[0].assigneeId = teamMember.id;
                task.incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
                task.incidentTaskAssignees[0].avatar = teamMember.avatar;
              }
              else{
                if (!task.incidentTaskAssignees){
                  task.incidentTaskAssignees = [];
                }
                const taskAssignee = new IncidentTaskAssignee();
                taskAssignee.assigneeId = teamMember.id;
                taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
                taskAssignee.avatar = teamMember.avatar;
                task.incidentTaskAssignees.push(taskAssignee);
              }
            }
          }
        });
      }
    });

    this.incidentLaunchDataDeepCopy.taskGroups.forEach((teamGroup) => {
      if (teamGroup.tasks != null && teamGroup.tasks.length > 0) {
        teamGroup.tasks.forEach((task) => {
          if (task != null && task.id) {
            if (this.selectedCheckbox.some((x) => x.id == task.id)) {
              if (task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0){
                task.incidentTaskAssignees[0].assigneeId = teamMember.id;
                task.incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
                task.incidentTaskAssignees[0].avatar = teamMember.avatar;
              }
              else{
                if (!task.incidentTaskAssignees){
                  task.incidentTaskAssignees = [];
                }
                const taskAssignee = new IncidentTaskAssignee();
                taskAssignee.assigneeId = teamMember.id;
                taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
                taskAssignee.avatar = teamMember.avatar;
                task.incidentTaskAssignees.push(taskAssignee);
              }
            }
          }
        });
      }
    });

    // const asdsad = this.incidentLaunchData.taskGroups.filter(x => this.selectedCheckbox.some(y => y.id == x.id));
    // console.log(asdsad);

    // if (!teamMember || !teamMember.id){
    //   return;
    // }

    // if (this.incidentId && this.incidentLaunchData && this.incidentLaunchData.templateId && this.selectedCheckbox && this.selectedCheckbox.length > 0){

    //   let saveProtocolLaunchDTO = new SaveProtocolLaunchDTO();
    //   saveProtocolLaunchDTO.incidentId = this.incidentId;
    //   saveProtocolLaunchDTO.TemplateIdUsed = this.incidentLaunchData.templateId;

    //   for (const task of this.selectedCheckbox) {

    //     let saveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
    //     saveProtocolLaunchItemDTO.TaskId = +task.id;
    //     saveProtocolLaunchItemDTO.Timeframe = task.taskTimeFrame;
    //     saveProtocolLaunchItemDTO.MemberId = teamMember.id;

    //     saveProtocolLaunchDTO.Items.push(saveProtocolLaunchItemDTO);
    //   }

    //   this._cstProtocolLaunchService.SaveCSTProtocolLaunchAssignments(saveProtocolLaunchDTO).subscribe(response => {
    //     this._router.navigate(['home-dashboard']);
    //   }, err => {
    //     console.log("Error:" + JSON.stringify(err));
    //     this._toastr.error(err?.error?.message, "Error");
    //   });
    // }
  }

  SaveAndLaunchTheProtocol() {
    // if (
    //   !this.selectedTeamMemberForBulkUpdate ||
    //   !this.selectedTeamMemberForBulkUpdate.id
    // ) {
    //   return;
    // }

    if (
      this.incidentId &&
      this.incidentLaunchData &&
      this.incidentLaunchData.templateId &&
      this.incidentLaunchData.taskGroups &&
      this.incidentLaunchData.taskGroups.length > 0
    ) {
      this.isLaunched = true;

      let saveProtocolLaunchDTO = new SaveProtocolLaunchDTO();
      saveProtocolLaunchDTO.incidentId = this.incidentId;
      saveProtocolLaunchDTO.TemplateIdUsed = this.incidentLaunchData.templateId;

      // for (const task of this.selectedCheckbox) {
      //   let saveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
      //   saveProtocolLaunchItemDTO.TaskId = +task.id;
      //   saveProtocolLaunchItemDTO.Timeframe = task.taskTimeFrame;
      //   saveProtocolLaunchItemDTO.MemberId =
      //     this.selectedTeamMemberForBulkUpdate.id;

      //   saveProtocolLaunchDTO.Items.push(saveProtocolLaunchItemDTO);
      // }
      
      for (const task of this.incidentLaunchData.taskGroups) {
        if(task.tasks && task.tasks.length > 0){
          for (const innerTask of task.tasks) {
            let saveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
            saveProtocolLaunchItemDTO.TaskId = +innerTask.id;
            saveProtocolLaunchItemDTO.Timeframe = innerTask.taskTimeFrame;
            if (innerTask.incidentTaskAssignees && innerTask.incidentTaskAssignees.length > 0){
              saveProtocolLaunchItemDTO.MemberId = innerTask.incidentTaskAssignees[0].assigneeId;
            }
          
            saveProtocolLaunchDTO.Items.push(saveProtocolLaunchItemDTO);
          }
        }
      }

      this._cstProtocolLaunchService
        .SaveCSTProtocolLaunchAssignments(saveProtocolLaunchDTO)
        .pipe(tap(() => this.isLaunched = true))
        .subscribe(
          (response) => {
            this._router.navigate(["home-dashboard"]);
          },
          (error) => {
            if (error && error.error && error.error.message){
              console.log("Error:" + JSON.stringify(error));
              this._apiErrorService.ShowErrorModal("Error", error.error.message);
              //this._toastr.error(error.error.message, "Error");
            }
            else{
              this._apiErrorService.ShowErrorModal();
              //this._toastr.error("Something went wrong!");
            }
          }
        );
    }
  }

  personClicked(teamMember: MemberProfile[], taskgroup: IncidentLaunchTask, task: IncidentLaunchTask, outerIndex: number, innerIndex: number){
    let memberArray : {name: string, avatar: string}[] = [];
    memberArray = teamMember.map(x => { 
      return {name: `${x.firstName} ${x.familyName}`, avatar: x.avatar}
    });

    //const memberStringArray = teamMember.map(x => `${x.firstName} ${x.familyName}`).sort();

    this._dialog.open(AssignMemberPopupComponent, {
      height: '80vh',
      data: {members: memberArray}
    }).afterClosed().subscribe(selectedMember => {
      if (selectedMember == null || selectedMember == undefined || selectedMember == ''){
        return;
      }

      const selectedTeamMember = teamMember.find(x => `${x.firstName} ${x.familyName}` === selectedMember);
      if (!selectedTeamMember){
        return;
      }

      this.AssignMember(selectedTeamMember, taskgroup, task, outerIndex, innerIndex);
    });
  }

  AssignMember(teamMember: MemberProfile, taskgroup: IncidentLaunchTask, task: IncidentLaunchTask, outerIndex: number, innerIndex: number) {
    const outerIndexForMainCollection = this.incidentLaunchData.taskGroups.findIndex(x => x.id === taskgroup.id);
    let innerIndexForMainCollection;
    if (outerIndexForMainCollection !== -1 && this.incidentLaunchData.taskGroups[outerIndexForMainCollection] && this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks && this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks.length > 0){
      innerIndexForMainCollection = this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks.findIndex(x => x.id === task.id);

      if (this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks[innerIndexForMainCollection].incidentTaskAssignees && this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks[innerIndexForMainCollection].incidentTaskAssignees.length > 0){
        this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks[innerIndexForMainCollection].incidentTaskAssignees[0].assigneeId = teamMember.id;
        this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks[innerIndexForMainCollection].incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
        this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks[innerIndexForMainCollection].incidentTaskAssignees[0].avatar = teamMember.avatar;
  
      }
      else{
        if (!this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks[innerIndexForMainCollection].incidentTaskAssignees){
          this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks[innerIndexForMainCollection].incidentTaskAssignees = [];
        }
        const taskAssignee = new IncidentTaskAssignee();
        taskAssignee.assigneeId = teamMember.id;
        taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
        taskAssignee.avatar = teamMember.avatar;
        this.incidentLaunchData.taskGroups[outerIndexForMainCollection].tasks[innerIndexForMainCollection].incidentTaskAssignees.push(taskAssignee);
  
      }
    }
    
    
    if (this.incidentLaunchDataDeepCopy.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees && this.incidentLaunchDataDeepCopy.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees.length > 0){
      this.incidentLaunchDataDeepCopy.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].assigneeId = teamMember.id;
      this.incidentLaunchDataDeepCopy.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
      this.incidentLaunchDataDeepCopy.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].avatar = teamMember.avatar;

    }
    else{
      if (!this.incidentLaunchDataDeepCopy.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees){
        this.incidentLaunchDataDeepCopy.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees = [];
      }
      const taskAssignee = new IncidentTaskAssignee();
      taskAssignee.assigneeId = teamMember.id;
      taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
      taskAssignee.avatar = teamMember.avatar;
      this.incidentLaunchDataDeepCopy.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees.push(taskAssignee);

    }
  }

  filterData() {
    this.incidentLaunchDataDeepCopy = JSON.parse(JSON.stringify(this.incidentLaunchData));
    this.selectedCheckbox = [];
    if (!this.selectedTeamMemberForFilter && !this.selectedTemplateForFilter){
      return;
    }

    if (this.selectedTemplateForFilter){
      this.incidentLaunchDataDeepCopy.taskGroups = this.incidentLaunchDataDeepCopy.taskGroups.filter(x => x.id === this.selectedTemplateForFilter.id);
    }
    
    let result: LAIncidentLaunchDTO = new LAIncidentLaunchDTO();
    result.incidentId = this.incidentLaunchDataDeepCopy.incidentId;
    result.incidentTitle = this.incidentLaunchDataDeepCopy.incidentTitle;
    result.template = this.incidentLaunchDataDeepCopy.template;
    result.templateId = this.incidentLaunchDataDeepCopy.templateId;

    if (!this.selectedTeamMemberForFilter){
      result.taskGroups = this.incidentLaunchDataDeepCopy.taskGroups;
      return;
    }
    else{

      for (let outterIndex = this.incidentLaunchDataDeepCopy.taskGroups.length - 1; outterIndex >= 0; outterIndex--) {
        const tasks = this.incidentLaunchDataDeepCopy.taskGroups[outterIndex].tasks;
        if (tasks && tasks.length > 0) {
          for (let innerIndex = tasks.length - 1; innerIndex >= 0; innerIndex--) {
            const assignee = tasks[innerIndex].incidentTaskAssignees;
            if (!assignee || assignee.length == 0 || !assignee[0] || assignee[0].assigneeId !== this.selectedTeamMemberForFilter.id){
              this.incidentLaunchDataDeepCopy.taskGroups[outterIndex].tasks.splice(innerIndex, 1);
            }
          }
        }
      }
    }
  }


  SaveTemplate(){
    if (!this.incidentLaunchDataDeepCopy.taskGroups || this.incidentLaunchDataDeepCopy.taskGroups.length <= 0){
      this._toastr.info("Nothing to be save");
    }

    document.getElementById('save-btn-spinner').style.display = 'block';
    document.getElementById('btn-save').style.display = 'none';

    let protocolTemplateDTO = new ProtocolTemplateDTO();

    protocolTemplateDTO.title = this.selectedTemplate.templateName;
    
    for (const taskGroup of this.incidentLaunchDataDeepCopy.taskGroups){
      if (!taskGroup.tasks || taskGroup.tasks.length <= 0){
        continue;
      }
      for (const task of taskGroup.tasks){
        const saveProtocolLaunchItemDTO: SaveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
        saveProtocolLaunchItemDTO.TaskId = +task.id;
        saveProtocolLaunchItemDTO.Timeframe = task.taskTimeFrame;

        if (task && task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0 && task.incidentTaskAssignees[0].assigneeId){
          saveProtocolLaunchItemDTO.MemberId = task.incidentTaskAssignees[0].assigneeId;
        }
        protocolTemplateDTO.items.push(saveProtocolLaunchItemDTO);
      }
    }

    if (this.selectedTemplate && this.selectedTemplate.templateId){

      const templateId = this.selectedTemplate.templateId;

      this._cstProtocolLaunchService.EditTemplateTemplate(templateId, protocolTemplateDTO).subscribe(response => {
        document.getElementById('save-btn-spinner').style.display = 'none';
        document.getElementById('btn-save').style.display = 'block';
  
        this._toastr.success("Saved successfully!", "Saved");
      });
    }
    // else{
    //   this._cstProtocolLaunchService.EditTemplateTemplate(templateId, protocolTemplateDTO).subscribe(response => {
    //     document.getElementById('save-btn-spinner').style.display = 'none';
    //     document.getElementById('btn-save').style.display = 'block';
  
    //     this._toastr.success("Saved successfully!", "Saved");
    //   });
    // }
  }

  CopyThisOne(){
    if (!this.incidentLaunchDataDeepCopy || !this.incidentLaunchDataDeepCopy.taskGroups || this.incidentLaunchDataDeepCopy.taskGroups.length <= 0){
      return;
    }

    this.incidentLaunchDataDeepCopy.templateId = '';
    this.selectedTemplate = null;

    this.incidentLaunchDataDeepCopy.taskGroups.forEach(taskGroup => {
      if (taskGroup && taskGroup.tasks && taskGroup.tasks.length > 0){
        taskGroup.tasks.forEach(task => {
          task.incidentTaskAssignees = [];
        });
      }
    });
  }



  // AssignMember(teamMember: MemberProfile, task: any, outerIndex: number, innerIndex: number) {

  //   if (this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees.length > 0){
  //     this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].assigneeId = teamMember.id;
  //     this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
  //   }
  //   else{
  //     const taskAssignee = new IncidentTaskAssignee();
  //     taskAssignee.assigneeId = teamMember.id;
  //     taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
  //     this.incidentLaunchData.taskGroups[outerIndex].tasks[innerIndex].incidentTaskAssignees.push(taskAssignee);
  //   }
    
  //   // this.incidentLaunchData.taskGroups.forEach((teamGroup) => {
  //   //   if (teamGroup.tasks != null && teamGroup.tasks.length > 0) {
  //   //     teamGroup.tasks.forEach((task) => {
  //   //       if (task != null && task.id) {
  //   //         if (this.selectedCheckbox.some((x) => x.id == task.id)) {
  //   //           if (task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0){
  //   //             task.incidentTaskAssignees[0].assigneeId = teamMember.id;
  //   //             task.incidentTaskAssignees[0].assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
  //   //           }
  //   //           else{
  //   //             const taskAssignee = new IncidentTaskAssignee();
  //   //             taskAssignee.assigneeId = teamMember.id;
  //   //             taskAssignee.assigneeName = `${teamMember.firstName} ${teamMember.familyName}`;
  //   //             task.incidentTaskAssignees.push(taskAssignee);
  //   //           }
  //   //         }
  //   //       }
  //   //     });
  //   //   }
  //   // });
  // }

  // SaveCSTProtocolLaunchAssignments(){

  //   if (this.incidentId && this.incidentLaunchData && this.incidentLaunchData.templateId && this.selectedCheckbox && this.selectedCheckbox.length > 0){

  //     let saveProtocolLaunchDTO = new SaveProtocolLaunchDTO();
  //     saveProtocolLaunchDTO.incidentId = this.incidentId;
  //     saveProtocolLaunchDTO.TemplateIdUsed = this.incidentLaunchData.templateId;

  //     for (const task of this.selectedCheckbox) {

  //       let saveProtocolLaunchItemDTO = new SaveProtocolLaunchItemDTO();
  //       saveProtocolLaunchItemDTO.TaskId = +task.id;
  //       saveProtocolLaunchItemDTO.Timeframe = task.taskTimeFrame;
  //       saveProtocolLaunchItemDTO.MemberId = '';

  //       saveProtocolLaunchDTO.Items.push(saveProtocolLaunchItemDTO);
  //     }

  //     this._cstProtocolLaunchService.SaveCSTProtocolLaunchAssignments(saveProtocolLaunchDTO).subscribe(response => {
  //       this._router.navigate(['home-dashboard']);
  //     }, err => {
  //       console.log("Error:" + JSON.stringify(err));
  //       this._toastr.error(err?.error?.message, "Error");
  //     });
  //   }

  // }

  GetTimeFram(index: number): string {
    return this._cstProtocolLaunchService.GetTimeFram(index);
  }

  GetTemplateTitle(template: IncidentLaunchTask){
    return template?.title + ' - ' + this.GetTimeFram(template.taskTimeFrame);
  }

  GetPersonImageSrc(task: IncidentLaunchTask){
    if (task && task.incidentTaskAssignees && task.incidentTaskAssignees.length > 0){
      return task?.incidentTaskAssignees[0]?.avatar ? task.incidentTaskAssignees[0].avatar : 'assets/images/profile-blank-icon.png';
    }
    else{
      return "assets/images/profile-blank-icon.png";
    }
  }

  GetStringOfTeamMembersList(teamMebers: IncidentTaskAssignee[]) {
    let result = "";
    if (teamMebers && teamMebers.length > 0) {
      for (const teamMember of teamMebers) {
        result += teamMember.assigneeName + ", ";
      }
    }
    if (result.charAt(result.length - 2) == ",") {
      result = result.slice(0, -2);
    }
    return result;
  }

  isNullOrWhiteSpace(str) {
    return str === null || str === undefined || str.trim() === '';
  }

  ngOnDestroy(): void {
    this._loadScriptSerivice.removeScript("Edit-CSTProtocolScriptTag");

    if (this.dataLoadedEventSubscription){
      this.dataLoadedEventSubscription.unsubscribe();
    }
  }

}
