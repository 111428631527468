<app-home-nav-menu></app-home-nav-menu>

<div class="pt-15 Team_protocol_action local-auth-5">
  <div class="container">
    <h2 class="sec-title">Incident Detail</h2>

    <div class="back-to-dashboard">
      <a [routerLink]="[_urlHelperService.LaUserDashboardUrl()]"><img src="assets/images/left-angle-primary.png"
          alt="left-angle-primary"> Back to dashboard</a>
    </div>
    <div class="accordion tableaccordian incident-detail-accord" id="startincident">
      <div class="accordion-item remove-accordian">
        <h2 class="accordion-header with-link" id="incidentheading1">
          <div class="accordion-button bg_skyblue collapsed"
            data-bs-toggle="collapse" success
            data-bs-target="" aria-expanded="true"
            aria-controls="incidentaccord1">
            <div>
              <p class="incidentTital">
                {{IncidentDTO.title}}
              </p>
            </div>

            <div class="AddressTagContainer">
              <span class="addressSpan">{{IncidentDTO.address}}</span>

              <span class="status-btn cst text-white border-white"
                *ngIf="IncidentDTO.status == 2">
                Scaled down
              </span>

              <span class="status-btn cst text-white border-white"
                *ngIf="IncidentDTO.status == 0">
                Pending
              </span>

              <span class="status-btn emergency align-items-end text-white border-white showHand"
                *ngIf="IncidentDTO.responseStatus == 0" (click)="ImmediateTagClicked()">
                Immediate
              </span>

              <span class="status-btn cst text-white border-white showHand"
                *ngIf="IncidentDTO.protocolStatus == 0" (click)="CSTTagClicked()">
                CST
              </span>
            </div>
            
          </div>
        </h2>
        <div id="incidentaccord1" class="accordion-collapse collapse show"
          aria-labelledby="incidentheading1"
          data-bs-parent="#startincident">
          <div class="accordion-body" *ngIf="!loadIncident">
            <div class="pe-sm-5">
              <div class="form-style p-4">
                <div class="form-title">
                  <div class="para-text">
                  </div>
                </div>
                <div class="fieldset only-text map-open-btn">
                  <div class="form-label">
                    <label>Category</label>
                  </div>
                  <div class="hs-input">
                    <div class="para-text">
                      <p class="initial-font">{{IncidentDTO.offencegroup}}</p>
                    </div>
                  </div>
                  <!-- <button type="button" (click)="MapShow=!MapShow" class="map-btn"
                    [ngClass]="{'active':MapShow}">
                    <img src="../../assets/images/left-arrow.png"
                      alt="left-arrow"> 
                  </button> -->
                  <!-- <button type="button" class="map-btn" rel="mapModal" (click)="MapShow=!MapShow">
                    <img src="assets/images/left-angle-black.png" alt="left-arrow">
                  </button> -->
                </div>
                <div class="modal map-modal-style height-fit"
                  [ngClass]="{'show':MapShow}">
                  <!-- <div class="modal map-modal-style " id="mapModal" [ngClass]="{'show':MapShow}"> -->
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="btn-close"
                          (click)="MapShow=!MapShow"></button>
                        <!-- <button type="button" class="btn-close" (click)="MapShow=!MapShow"></button> -->
                      </div>
                      <div class="modal-body d-flex align-items-center">
                        <div class="body-contain">
                          <div class="map-iframe">
                            <agm-map [latitude]="lat" [longitude]="lng"
                              [zoomControl]="true" [zoom]="zoom"
                              [fullscreenControl]='true' [mapTypeControl]='true'>
                              <div *ngFor="let m of LocationMainmarkers; let i=
                                index">
                                <agm-marker *ngIf="m.visible && !m.ispolygon"
                                  [iconUrl]="{
                                  url: m.icon
                                  }" [latitude]="m.lat" [longitude]="m.lng">
                                </agm-marker>
                                <agm-polygon [strokeColor]="m.color"
                                  *ngIf="m.visible && m.ispolygon"
                                  [paths]="m.path">
                                </agm-polygon>
                              </div>
                            </agm-map>
  
                          </div>
                          <div class="row map-checkboxs">
                            <div class="col-12">
                              <div class="map-checkbox-items">
                                <div class="row">
                                  <div class="map-checkbox-item col-lg-6 col-sm-12
                                    col-md-6"
                                    *ngFor="let item of LocationMainmarkers;let
                                    i=index">
                                    <label [for]="i">{{item.label}}</label>
                                    <input type="checkbox"
                                      (change)="item.visible=!item.visible"
                                      class="form-control form-check-input"
                                      [id]="i"
                                      [checked]="item.visible">
                                    <img [src]="item.icon">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-12 col-sm-6">
                              <ul class="map-checkbox-items">
                                <li class="map-checkbox-item"  *ngFor="let item of LocationMainmarkersSecond;let i=index">
                                  <label [for]="i">{{item.label}}</label>
                                  <input type="checkbox" (change)="item.visible=!item.visible"
                                    class="form-control form-check-input" [id]="i" [checked]="item.visible">
                                  <img [src]="item.icon">
                                </li>
                              </ul>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fieldset only-text">
                  <div class="form-label">
                    <label>Date</label>
                  </div>
                  <div class="hs-input">
                    <div class="para-text">
                      <p class="initial-font">{{IncidentDTO.incidentDate | date : "dd/MM/yyyy"}} &nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="initial-font">{{IncidentDTO.incidentDate | date : "HH:mm"}}</span></p>
                    </div>
                  </div>
                </div>
                <div class="fieldset only-text">
                  <div class="form-label">
                    <label>Address</label>
                  </div>
                  <div class="hs-input">
                    <div class="para-text">
                      <p class="initial-font">{{IncidentDTO.address}}</p>
                    </div>
                  </div>
                </div>
  
                <div class="fieldset bottom-margin-0">
                  <div class="form-label">
                    <label for="partnermessage">Incident details</label>
                  </div>
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12 height100">
                        <div class="bottom-margin-20" >   <!--*ngFor="let incidentDescription of IncidentDTO.incidentDescription"-->
                          <div class="m-slider-card">
                            <div class="message-date m-0">
                              <!-- <span>Sent on {{IncidentDTO.lastUpdatedAt | date:'HH:mm dd/MM/yy'}}</span> -->
                            </div>
                            <div class="message" *ngIf="IncidentDTO?.incidentDescription && IncidentDTO?.incidentDescription != ''">
                              <div class="incident-detail">
                                {{IncidentDTO.incidentDescription}}
                              </div>
                             
                              <!-- <textarea class="incident-detail" readonly rows="2">{{IncidentDTO.incidentDescription}}</textarea> -->
                              <!-- <p class="initial-font">{{IncidentDTO.incidentDescription}}</p> -->
                            </div>
                          </div>
  
                          <!-- <div class="m-slider-card">
                            <div class="message-date m-0">
                              <span>Last updated 22:30 28/07/22</span>
                            </div>
                            <div class="message">
                              <p>Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Ab quam doloremque fugiat
                                vitae cumque suscipit dolores tempora aliquid
                                eaque est, dolore cupiditate quo ullam
                                qui facere, dolor iure distinctio corporis?</p>
                            </div>
                          </div>
  
                          <div class="m-slider-card">
                            <div class="message-date m-0">
                              <span>Last updated 22:30 28/07/22</span>
                            </div>
                            <div class="message">
                              <p>Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Ab quam doloremque fugiat
                                vitae cumque suscipit dolores tempora aliquid
                                eaque est, dolore cupiditate quo ullam
                                qui facere, dolor iure distinctio corporis?</p>
                            </div>
                          </div> -->
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                
                <div class="fieldset">
                  <div class="form-label">
                  </div>
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12">
                        <ul class="check-round-style two-column initial-font-child-label">
                          <li class="check-round-each">
                            <!-- <input type="checkbox" class="form-control hideHand"> -->
                            <img class="img-width" [src]="IncidentDTO.peopledisplaced ? '../../assets/images/true-icon.png' : '../../assets/images/unchecked-icon.png'">
                            <label for="Peopledisplaced">People displaced?</label>
                          </li>
                          <li class="check-round-each">
                            <!-- <input type="checkbox" class="form-control"
                              id="Policecordon" required="" [checked]="IsPolicecordon"> -->
                            <img class="img-width" [src]="PoliceCordonLocationsToShow.length > 0 ? '../../assets/images/true-icon.png' : '../../assets/images/unchecked-icon.png'">
                            <label for="Policecordon">Police cordon</label>
                          </li>
                          <li class="check-round-each">
                            <!-- <input type="checkbox" class="form-control"
                              id="Crimescene" required="" [checked]="IsCrimescene"> -->
                            <img class="img-width" [src]="CrimeSceneLocationsToShow.length > 0 ? '../../assets/images/true-icon.png' : '../../assets/images/unchecked-icon.png'">
                            <label for="Crimescene">Crime scene</label>
                          </li>
                          <li class="check-round-each">
                            <!-- <input type="checkbox" class="form-control"
                              id="Peopledisplaced" required="" [checked]="IncidentDTO.section60 != null"> -->
                            <img class="img-width" [src]="RendezvousPointLocationsToShow.length > 0 ? '../../assets/images/true-icon.png' : '../../assets/images/unchecked-icon.png'">
                            <label>Rendezvous Point</label>
                          </li>
                          <li class="check-round-each">
                            <!-- <input type="checkbox" class="form-control"
                              id="Section35" required="" [checked]="IncidentDTO.section35 != null"> -->
                            <img class="img-width" [src]="Section35LocationsToShow.length > 0 ? '../../assets/images/true-icon.png' : '../../assets/images/unchecked-icon.png'">
                            <label for="Section35">Section 35</label>
                          </li>
                          <li class="check-round-each">
                            <!-- <input type="checkbox" class="form-control"
                              id="Peopledisplaced" required="" [checked]="IncidentDTO.section60 != null"> -->
                            <img class="img-width" [src]="Section60LocationsToShow.length > 0 ? '../../assets/images/true-icon.png' : '../../assets/images/unchecked-icon.png'">
                            <label for="Peopledisplaced">Section 60</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="fieldset">
                  <div class="form-label">
                    <label for="partnermessage">Locations</label>
                  </div>
                  <div class="hs-input">
                    <div class="row">
                      <!-- <div class="col-12">
                        <div class="d-flex justify-content-start">
                          <button type="button"
                            [ngClass]="{'active-incidnet-detail': shouldMapShow}"
                            (click)="shouldMapShow = !shouldMapShow" class="map-btn-incidnet-detail"
                            rel="locality-map">
                        <img src="../../../assets/images/up-arrow.png"
                          alt="left-arrow">
                      </button>
                        </div>
                      </div> -->
                      <div class="col-12" *ngIf="shouldMapShow">
                        <div class="body-contain inside-map">
                          <div class="map-iframe inner-map">
  
                            <div class="map-iframe">
                              <agm-map [latitude]="lat" [longitude]="lng"
                                [zoom]="zoom" [zoomControl]="true"
                                [fullscreenControl]='true' [mapTypeControl]='true'>
                                <agm-marker *ngIf="shouldIncidentMarkerVisible"
                                 [iconUrl]="{url: '../../assets/images/red-marker.png'}"
                                  [latitude]="lat" [longitude]="lng" 
                                  >
                                </agm-marker>
                                <agm-marker *ngFor="let m of MainLocationRVPMarkerToShow; let i=index" 
                                 [iconUrl]="{url: m?.icon}"
                                  [latitude]="m?.lat" [longitude]="m?.lng"
                                  [label]="m?.label"
                                  >
                                </agm-marker>
                                <agm-polygon *ngFor="let polygon of MainLocationPolygonToShow"
                                  [paths]="polygon?.path" [strokeColor]="polygon?.color">
                                </agm-polygon>
                              </agm-map>
                            </div>
  
  
                            <!-- <agm-map [latitude]="lat" [longitude]="lng"
                              [zoomControl]="true" [zoom]="zoom"
                              [fullscreenControl]='true' [mapTypeControl]='true'>
                              <div *ngFor="let m of LocationMainmarkers; let i=
                                index">
                                <agm-marker *ngIf="m.visible && !m.ispolygon"
                                  [iconUrl]="{
                                  url: m.icon
                                  }" [latitude]="m.lat" [longitude]="m.lng">
                                </agm-marker>
                                <agm-polygon [strokeColor]="m.color"
                                  *ngIf="m.visible && m.ispolygon"
                                  [paths]="m.path">
                                </agm-polygon>
                              </div>
                            </agm-map> -->
  
                          </div>
                          <div class="row map-checkboxs">
                            <div class="col-12">
                              <div class="map-checkbox-items">
                                <div class="row">
  
                                  <div class="map-checkbox-item col-lg-6 col-sm-12
                                  col-md-6" style="padding-left: 30px;"
                                  >
                                  <label for="chkIncidentLocation">Incident location</label>
                                  <input 
                                    type="checkbox" 
                                    class="form-control form-check-input" 
                                    id="chkIncidentLocation" checked (change)="ChkIncidentLocationChnaged($event)">
                                  <img style="margin-left: 2px;" src="../../assets/images/red-marker.png">
                                </div>
                                
                                <div class="map-checkbox-item col-lg-6 col-sm-12
                                  col-md-6"
                                  *ngFor="let crimeSceneLocationForName of CrimeSceneLocations; index as i">
                                  <label *ngIf="crimeSceneLocationForName.name" for="chkCrimeScene">{{crimeSceneLocationForName.name}}</label>
                                  <label *ngIf="!crimeSceneLocationForName.name" for="chkCrimeScene">{{'Crime Scene ' + (i + 1)}}</label>
                                  <input 
                                    type="checkbox" 
                                    class="form-control form-check-input" 
                                    id="chkCrimeScene" checked (change)="ChkCrimeSceneChnaged($event, crimeSceneLocationForName, i)">
                                    <span class="scene-cirlce"></span>
                                </div>
  
                                <div class="map-checkbox-item col-lg-6 col-sm-12
                                  col-md-6"
                                  *ngFor="let policeCordonLocationForName of PoliceCordonLocations; index as i">
                                  <label *ngIf="policeCordonLocationForName.name" for="chkPoliceCorden">{{policeCordonLocationForName.name}}</label>
                                  <label *ngIf="!policeCordonLocationForName.name" for="chkPoliceCorden">{{'Police Cordon ' + (i + 1)}}</label>
                                  <input 
                                    type="checkbox" 
                                    class="form-control form-check-input" 
                                    id="chkPoliceCorden" checked (change)="ChkPoliceCordonChnaged($event, policeCordonLocationForName, i)">
                                    <span class="scene-cirlce blue"></span>
                                </div>
  
                                <div class="map-checkbox-item col-lg-6 col-sm-12
                                  col-md-6"
                                  *ngFor="let RendezvousPointLocation of RendezvousPointLocations; index as i">
                                  <label *ngIf="RendezvousPointLocation.name" for="chkPoliceCorden">{{RendezvousPointLocation.name}}</label>
                                  <label *ngIf="!RendezvousPointLocation.name" for="chkPoliceCorden">{{'Rendezvous Point ' + (i + 1)}}</label>
                                  <input type="checkbox" 
                                    class="form-control form-check-input" 
                                    id="chkPoliceCorden" checked (change)="ChkRendezvousPointChnaged($event, RendezvousPointLocation, i)">
                                  <span class="scene-cirlce location"></span>
                                </div>
  
                                <div class="map-checkbox-item col-lg-6 col-sm-12
                                  col-md-6"
                                  *ngFor="let section60Location of Section60Locations; index as i">
                                  <label *ngIf="section60Location.name" for="chkSection60">{{section60Location.name}}</label>
                                  <label *ngIf="!section60Location.name" for="chkSection60">{{'Section 60 ' + (i + 1)}}</label>
                                  <input type="checkbox" 
                                    class="form-control form-check-input" 
                                    id="chkSection60" checked (change)="ChkSection60Chnaged($event, section60Location)">
                                  <span class="scene-cirlce remove-line"></span>
                                </div>
  
                                <div class="map-checkbox-item col-lg-6 col-sm-12
                                col-md-6"
                                *ngFor="let section35Location of Section35Locations; index as i">
                                <label *ngIf="section35Location.name" for="chkSection35">{{section35Location.name}}</label>
                                <label *ngIf="!section35Location.name" for="chkSection35">{{'Section 35 ' + (i + 1)}}</label>
                                <input type="checkbox" class="form-control form-check-input" id="chkSection35" checked (change)="ChkSection35Chnaged($event, section35Location)">
                                <span class="scene-cirlce remove-line"></span>
                              </div>
  
                                <!--old checkboxes-->
                                  <!-- <div class="map-checkbox-item col-lg-6 col-sm-12
                                    col-md-6" style="padding-left: 25px;"
                                    *ngFor="let item of LocationMainmarkers;let
                                    i=index">
                                    <label [for]="i">{{item.label}}</label>
                                    <input type="checkbox"
                                      (change)="item.visible=!item.visible"
                                      class="form-control form-check-input"
                                      [id]="i"
                                      [checked]="item.visible">
                                    <img [src]="item.icon">
                                  </div> -->
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-12 col-sm-6">
                              <ul class="map-checkbox-items">
                                <li class="map-checkbox-item"  *ngFor="let item of LocationMainmarkersSecond;let i=index">
                                  <label [for]="i">{{item.label}}</label>
                                  <input type="checkbox" (change)="item.visible=!item.visible"
                                    class="form-control form-check-input" [id]="i" [checked]="item.visible">
                                  <img [src]="item.icon">
                                </li>
                              </ul>
                            </div> -->
                          </div>
  
                          <div class="row">
                            <div class="map-update-message" *ngIf="shouldIncidentLocationShow">
                              <div class="messagebox-head d-flex">
                                <div class="message-title">
                                  <h6 class="description-child-label">Incident location</h6>
                                </div>
                                <div class="message-date">
                                  <span>Last updated {{IncidentDTO.lastUpdatedAt | date:'HH:mm dd/MM/yy'}}</span>
                                </div>
                              </div>
                              <div class="message">
                                <p class="initial-font">{{IncidentDTO.address}}</p>
                              </div>
                            </div>
                            
                            <div class="map-update-message" *ngFor="let crimeScene of CrimeSceneLocations; index as i;" [id]="'crimeScene' + i">
                                <div class="messagebox-head d-flex">
                                  <div class="message-title">
                                    <h6 class="description-child-label" *ngIf="crimeScene.name">{{crimeScene.name}}</h6>
                                    <h6 class="description-child-label" *ngIf="!crimeScene.name">{{'Crime Scene ' + (i + 1)}}</h6>
                                  </div>
                                  <div class="message-date">
                                    <span>Last updated 22:30 28/07/22</span>
                                  </div>
                                </div>
                                <div class="message">
                                  <p class="initial-font">{{crimeScene.description}}</p>
                                </div>
                            </div>
  
                            <div class="map-update-message" *ngFor="let policeCordon of PoliceCordonLocations; index as i;" [id]="'policeCordon' + i">
                              <div class="messagebox-head d-flex">
                                <div class="message-title">
                                  <h6 class="description-child-label" *ngIf="policeCordon.name">{{policeCordon.name}}</h6>
                                  <h6 class="description-child-label" *ngIf="!policeCordon.name">{{'Police Cordon ' + (i + 1)}}</h6>
                                </div>
                                <div class="message-date">
                                  <p>Last updated 22:30 28/07/22</p>
                                </div>
                              </div>
                              <div class="message">
                                <p class="initial-font">{{policeCordon.description}}</p>
                              </div>
                            </div>
  
                            <div class="map-update-message" *ngFor="let RendezvousPoint of RendezvousPointLocations; index as i;" [id]="'rendezvousPoint' + i">
                              <div class="messagebox-head d-flex">
                                <div class="message-title">
                                  <h6 class="description-child-label" *ngIf="RendezvousPoint.name">{{RendezvousPoint.name}}</h6>
                                  <h6 class="description-child-label" *ngIf="!RendezvousPoint.name">{{'Rendezvous Point ' + (i + 1)}}</h6>
                                </div>
                                <div class="message-date">
                                  <p>Last updated 22:30 28/07/22</p>
                                </div>
                              </div>
                              <div class="message">
                                <p class="initial-font">{{RendezvousPoint.description}}</p>
                              </div>
                            </div>
  
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
  
                <div class="fieldset">
                  <div class="form-label">
                    <label for="partnermessage">Partner Message</label>
                  </div>
                  <div class="hs-input">
                    <div class="row">
                      <div class="col-12">
                        <div class="message-slider">
                          <div *ngIf="IncidentDTO.partnerMessages && IncidentDTO.partnerMessages.length > 0">
                            <div class="m-slider-card" *ngFor="let partnermessage of IncidentDTO.partnerMessages">
                              <div class="message-date m-0">
                                <span>Sent on {{IncidentDTO.lastUpdatedAt | date:'dd/MM/yy HH:mm'}}</span>
                              </div>
                              <div class="message">
                                <p class="initial-font">{{partnermessage.message}}</p>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="!IncidentDTO.partnerMessages || IncidentDTO.partnerMessages.length <= 0">
                            <div class="m-slider-card">
                              <div class="message-date m-0">
                                <span></span>
                              </div>
                              <div class="message">
                                <p class="initial-font">No partner message has been sent for this incident</p>
                              </div>
                            </div>
                          </div>
                          
  
                          <!-- <div class="m-slider-card">
                            <div class="message-date m-0">
                              <span>Last updated 22:30 28/07/22</span>
                            </div>
                            <div class="message">
                              <p>Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Ab quam doloremque fugiat
                                vitae cumque suscipit dolores tempora aliquid
                                eaque est, dolore cupiditate quo ullam
                                qui facere, dolor iure distinctio corporis?</p>
                            </div>
                          </div>
  
                          <div class="m-slider-card">
                            <div class="message-date m-0">
                              <span>Last updated 22:30 28/07/22</span>
                            </div>
                            <div class="message">
                              <p>Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Ab quam doloremque fugiat
                                vitae cumque suscipit dolores tempora aliquid
                                eaque est, dolore cupiditate quo ullam
                                qui facere, dolor iure distinctio corporis?</p>
                            </div>
                          </div> -->
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
  
  
                <div class="fieldset hs-submit">
                  <div class="form-label">
                  </div>
                  <div class="hs-input ">
                    <div class="row">
                      <div class="col-12 text-end">
                        <div class="dropdown  local-action-btn action-final">
                          <button class="dropdown-toggle  hsr-btn down-caret" type="button" id="launchprotocol2"
                            data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                          <ul class="dropdown-menu bottom-border" aria-labelledby="launchprotocol2">
                            <!-- <li>
                              <a href="#">
                                <img src="assets/images/download-black.png" alt="download-black"> Save incident
                              </a>
                            </li> -->
                            <li *ngIf="IncidentDTO.protocolStatus == 0">
                              <a [routerLink]="['/cst-protocol', IncidentDTO.id]">
                                <img src="assets/images/view.png" alt="view"> View CST protocol
                              </a>
                            </li>
                            <li *ngIf="IncidentDTO.protocolStatus == 3">
                              <!-- [routerLink]="['/cst-protocol-launch', IncidentDTO.id]" -->
                              <div (click)="LaunchCSTProtocolClicked()">
                                <a><img src="assets/images/launch-icon1.png" alt="launch-icon1"> Launch CST protocol</a>
                                
                              </div>
                            </li>
                            <li *ngIf="IncidentDTO.responseStatus == 3">
                              <a [routerLink]="['/immediate-response', IncidentDTO.id]">
                                <img src="assets/images/launch-icon1.png" alt="launch-icon1"> Launch Immediate Response
                              </a>
                            </li>
                            <li *ngIf="IncidentDTO.responseStatus == 0">
                              <a [routerLink]="['/immediate-response', IncidentDTO.id]">
                                <img src="assets/images/view.png" alt="view"> View Immediate Response
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- <div class="fieldset hs-submit">
                  <div class="form-label">
                  </div>
                  <div class="hs-input ">
                    <div class="row">
                      <div class="col-12 text-end">
                        <div class="dropdown  local-action-btn action-final">
                          <button class="dropdown-toggle" type="button" id="launchprotocol2"
                            data-bs-toggle="dropdown" aria-expanded="false">CST Protocol Launch</button>
                          <ul class="dropdown-menu bottom-border" aria-labelledby="launchprotocol2">
                            <li><a href="#"><img src="assets/images/download-black.png" alt="download-black">Save incident</a></li>
                            <li><a href="#"><img src="assets/images/view.png" alt="view"> View protocol</a></li>
                            <li>
                              <a [routerLink]="['/finalise-protocol-actions-post-protocol-launch']">
                                <img src="assets/images/launch-icon1.png" alt="launch-icon1">Launch protocol
                              </a>
                            </li>
                            <li>
                              <a [routerLink]="['/immediate-response', IncidentDTO.id]">
                                <img src="assets/images/launch-icon1.png" alt="launch-icon1">Emergency Planning
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
  
              </div>
            </div>
           
          </div>
          <div class="divloader" *ngIf="loadIncident">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
          
        </div>
      </div>

    </div>

  </div>
</div>