import { AddStakeholderService } from './add-stakeholder.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StakeholderCategoryList, StakeholderProfileCreateDTO } from 'src/app/Models/Stakeholder/Stakeholder';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { IncidentMessageSendService } from 'src/app/team/incident-message-send/incident-message-send.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-stakeholder',
  templateUrl: './add-stakeholder.component.html',
  styleUrls: ['./add-stakeholder.component.css']
})
export class AddStakeholderComponent implements OnInit {

  stakeholderForm: FormGroup;
  isSubmitted: boolean;
  isEdit: boolean = false;
  stakeHoldersCategory: StakeholderCategoryList[] = [];
  isstakeHoldersCategoryLoaded = false;
  //options = Object.keys(UserRole).filter(f => {isNaN(Number(f)) && (UserRole.SuperAdmin || UserRole.SuperAdmin)});

  constructor(
    public _dialogRef: MatDialogRef<AddStakeholderComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: FormBuilder,
    private _addStakeholderService : AddStakeholderService,
    private _apiErrorService: ApiErrorService,
    private _toastr: ToastrService,
    private _incidentMessageSendService: IncidentMessageSendService,
    private _userProfileService: UserProfileService
  ) { }

  ngOnInit(): void {
    //console.log(this._data)
    // this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    // this.navbarService.setPageTitle(this._activatedRoute.snapshot.data.title);

    if (this._data.memberProfile == null){
      this.isEdit = false;
    }
    else{
      this.isEdit = true;
    }

    this._incidentMessageSendService.GetStakeHolderCategories().subscribe((response => {
      if (response && response.data && response.statusCode == 200){

        this.stakeHoldersCategory = response.data;
        this.isstakeHoldersCategoryLoaded = true;

        if (this.isEdit){
          const caterogy = this.stakeHoldersCategory.find(x => x.id == this._data.memberProfile?.memberCategoryId);

          this.stakeholderForm.patchValue({
            category: caterogy ? caterogy.id : null,
          });
        }
      }
    }));
    

    this.InitForm();

    if (this.isEdit){
      this.stakeholderForm.patchValue({
        firstName: this._data.memberProfile.firstName,
        givenName: this._data.memberProfile.familyName,
        //category: isIncluded ? this._data.memberProfile.role : null,
        employer: this._data.memberProfile.employer,
        phone: this._data.memberProfile.phoneNo,
        email: this._data.memberProfile.email,
        alertPreferences: this._data.memberProfile.alertPreferences,
        jobTitle: this._data.memberProfile.jobTitle
      });
    }
    
  }

  InitForm() {
    this.stakeholderForm = this._fb.group({
      firstName: [null, Validators.required],
      givenName: [null, Validators.required],
      category: [null, Validators.required],
      employer: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern(/^\+[1-9]\d{1,14}$/)]],
      email: [null, [Validators.required, Validators.email]],
      jobTitle: [null],
    });
  }

  public get firstName(){
    return this.stakeholderForm.controls.firstName as FormControl;
  }

  onSubmit(){
    this.isSubmitted = true;
    if (this.stakeholderForm.invalid){
      return;
    }

    let stakeholder = new StakeholderProfileCreateDTO();

    stakeholder.groups = { [this._userProfileService.GetAuthenticatedUserGroup()?.organizationGroupId]: true};

    stakeholder.familyName = this.stakeholderForm.controls.givenName.value;
    stakeholder.firstName = this.stakeholderForm.controls.firstName.value;
    stakeholder.email = this.stakeholderForm.controls.email.value;
    stakeholder.phoneNo = this.stakeholderForm.controls.phone.value;
    stakeholder.employer = this.stakeholderForm.controls.employer.value;
    //stakeholder.MemberType = MemberType.Member;
    //stakeholder.roles = +UserRole[this.stakeholderForm.controls.role.value];
    //stakeholder.incidentAlert = this.stakeholderForm.controls.policeIIR.value;
    //stakeholder.locationAlert = this.stakeholderForm.controls.location.value;
    //stakeholder.PartnerMessage = this.stakeholderForm.controls.partnerMessage.value;
    //stakeholder.section60Alert = this.stakeholderForm.controls.section60.value;
    //stakeholder.section35Alert = this.stakeholderForm.controls.section35.value;
    stakeholder.jobTitle = this.stakeholderForm.controls.jobTitle.value;
    //stakeholder.IncidentLaunchIfNotTeam = this.stakeholderForm.controls.protocolLaunch.value;
    //stakeholder.Avatar = this._data?.memberProfile?.avatar;
    //stakeholder.DashboardContact = this._data?.memberProfile?.dashboardContact;
    stakeholder.memberCategoryId = this.stakeholderForm.controls.category.value;
    
    if (this.isEdit){
      stakeholder.id = this._data.memberProfile.id;
    }
    //stakeholder.sendJoiningEmail = true;

    if (this.isEdit){
      this._addStakeholderService.EditStakeholder(stakeholder).subscribe(response => {
        this._addStakeholderService.StakeholderCommand.next([stakeholder, true]);
        this._toastr.success("Stakeholder is Updated Successfully");
        this._dialogRef.close();
      }, 
      (error) => {
        this._apiErrorService.ShowError(error);
      });
    }
    else{
      this._addStakeholderService.AddStakeholder(stakeholder).subscribe(response => {
        this._addStakeholderService.StakeholderCommand.next([stakeholder, false]);
        this._toastr.success("Stakeholder is Added Successfully");
        this._dialogRef.close();
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      });
    }

  }

}
