import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StringHelperService } from './string-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UrlHelperService {

  constructor(
    private _stringHelperService: StringHelperService
    ) { }

  LaUserDashboardUrl() : string{
    return '/home-dashboard';
  }

  BcuUserDashboardUrl() : string{
    return '/BCU/Dashboard';
  }

  La2UserDashboardUrl() : string{
    return '/la-dashboard';
  }

  BcuUserContactUrl() : string{
    return '/BCU/contacts';
  }

  IsSignupUrl(url: string) : boolean{
    if (this._stringHelperService.IsNullOrWhiteSpace(url)){
      return false;
    }
    return url.startsWith("/sign-up/");
  }

  IsChangePasswordUrl(url) : boolean{
    if (this._stringHelperService.IsNullOrWhiteSpace(url)){
      return false;
    }
    return url.startsWith("change-password");
  }

  GetSimHelpURL(){
    return environment.PCR_help_URL;
  }

  GetChangePasswordUrl(){
    return '/change-password';
  }
  
}
