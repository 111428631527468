import { UserProfileService } from './../core/services/user-profile.service';
import { MapsAPILoader, Marker, MouseEvent } from "@agm/core";
import { Component, ElementRef, NgZone, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CommonType,
  IncidentDTO,
  Markers,
} from "../Models/incident.modal";
import { IncidentService } from '../initial-incident-form/incident.service'
import { ToastrService } from "ngx-toastr";
import { ApiErrorService } from "../core/services/api-error.service";
import { BCURegionDTO } from '../Models/BCURegionDTO.Model';
import { AuthenticatedUserForGrouping } from '../Models/Members/AuthenticatedUserGroups';
import { UrlHelperService } from '../core/services/url-helper.service';
import { DateService } from '../core/services/date.service';

declare var google: any;

@Component({
  selector: "app-new-incident-la-store-user",
  templateUrl: "./new-incident-la-store-user.component.html",
  styleUrls: ["./new-incident-la-store-user.component.css"],
})
export class NewIncidentLaStoreUserComponent implements OnInit {
  newIncidentForm: FormGroup;
  isFormLoaded = false;
  isSubmitted = false;
  markers: Markers[] = [];
  // CreateIncidentDTO: CreateIncidentDTO = new CreateIncidentDTO();

  Days: CommonType[] = [];
  Months: CommonType[] = [];
  Years: CommonType[] = [];

  //#region map related fields
  lat: any;
  lng: any;
  zoom = 14;
  postCodeForSaving: string;
  //#endregion

  usersRegionsAndGroups: AuthenticatedUserForGrouping;
  selectedRegion: BCURegionDTO;
  isBCURegionLoaded = false;

  @ViewChild("search")
  public searchElementRef: ElementRef<HTMLInputElement>;

  constructor(
    private _fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _titleService: Title,
    private _apiloader: MapsAPILoader,
    private _incidentService: IncidentService,
    private _toastr: ToastrService,
    private _apiErrorService: ApiErrorService,
    private _userProfileService: UserProfileService,
    private _ngZone: NgZone,
    public _urlHelperService: UrlHelperService,
    private _dateService: DateService
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);

    this.InitForm();

    this.InItSelectedRegion();

    this.getLocation();
    this.getDays();
    this.getMonths();
    this.getYears();
    this.InitializeDateTime();
    this.isFormLoaded = true;
  }

  // applyRequiredValidation() {
  //   this.searchElementRef.nativeElement.required = true;
  // }

  ngAfterViewInit() {

    
    //load Places Autocomplete
    setTimeout(() => {
      //this.applyRequiredValidation();
      this._apiloader.load().then(() => {
        const self = this;
        let geoCoder = new google.maps.Geocoder();
  
        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement
        );
        autocomplete.addListener("place_changed", () => {
          this._ngZone.run(() => {
            //get the place result
            const place = autocomplete.getPlace();
  
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
  
            //set latitude, longitude and zoom
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
            this.zoom = this.zoom;
  
            self.markers[0].lat = self.lat;
            self.markers[0].lng = self.lng;
  
            let latlng = {
              lat: this.lat,
              lng: this.lng,
            };
  
            geoCoder.geocode(
              {
                location: latlng,
              },
              function (results) {
                if (results[0]) {
                  const postCode =
                    results[1].address_components[
                      results[1].address_components.length - 1
                    ].long_name;

                  self.newIncidentForm.patchValue({
                    location: place.formatted_address,
                  });
  
                  self.postCodeForSaving = postCode;
                } else {
                  console.log("Not found");
                }
              }
            );
          });
        });
      });
    }, 1000);

    
  }

  InitForm() {
    this.newIncidentForm = this._fb.group({
      title: [null, Validators.required],
      time: [null, Validators.required],
      day: [null, Validators.required],
      month: [null, Validators.required],
      year: [null, Validators.required],
      location: [null, Validators.required],
      incidentDetail: [null, Validators.required],
      peopleDisplaced: [null],
      OffenceCategory: [null, Validators.required],
      method: [null, Validators.required],
      localAuthority: [null, Validators.required],
      //postCode: [null, Validators.required],
    });
  }

  InItSelectedRegion() {
    let timerId = setInterval(() => {
      const userWithRegion =
        this._userProfileService.GetAuthenticatedUserGroups();
      if (userWithRegion) {
        this.usersRegionsAndGroups = userWithRegion;
        if (this.usersRegionsAndGroups && this.usersRegionsAndGroups.region) {
          this.selectedRegion = this.usersRegionsAndGroups.region;
        }

        if (this.selectedRegion) {
          this.isBCURegionLoaded = true;
          const userGroupName = this.selectedRegion.groups.find(
            (x) => x.isAuthenticatedUserGroup
          )?.organizationGroupName;
          if (userGroupName && userGroupName != "") {
            this.newIncidentForm.patchValue({
              localAuthority: userGroupName,
            });
          } else {
            this.newIncidentForm.patchValue({
              localAuthority:
                this.selectedRegion.groups[0].organizationGroupName,
            });
          }

          clearInterval(timerId);
        }
      }
    }, 100);
  }

  //#region getters
  get title() {
    return this.newIncidentForm.get("title") as FormControl;
  }

  get day() {
    return this.newIncidentForm.get("day") as FormControl;
  }

  get month() {
    return this.newIncidentForm.get("month") as FormControl;
  }

  get year() {
    return this.newIncidentForm.get("year") as FormControl;
  }

  get time() {
    return this.newIncidentForm.get("time") as FormControl;
  }

  get location() {
    return this.newIncidentForm.get("location") as FormControl;
  }

  get incidentDetail() {
    return this.newIncidentForm.get("incidentDetail") as FormControl;
  }

  get peopleDisplaced() {
    return this.newIncidentForm.get("peopleDisplaced") as FormControl;
  }

  get OffenceCategory() {
    return this.newIncidentForm.get("OffenceCategory") as FormControl;
  }

  get method() {
    return this.newIncidentForm.get("method") as FormControl;
  }

  get localAuthority() {
    return this.newIncidentForm.get("localAuthority") as FormControl;
  }
  //#endregion

  //#region set time fields
  getDays() {
    this.Days = this._incidentService.getDays();
  }

  getMonths() {
    this.Months = this._incidentService.getMonths();
  }

  getYears() {
    this.Years = this._incidentService.getYears();
  }


  InitializeDateTime() {
    let currentDate = new Date();
    let Hours = currentDate.getHours();
    let Time;
    let incidentDateMintes = currentDate.getMinutes().toString();
    if (incidentDateMintes.length == 1) {
      incidentDateMintes = "0" + incidentDateMintes;
    }
    if (Hours < 10) {
      if (currentDate.getHours() > 0) {
        Time =
          "0" +
          Number(Number(currentDate.getHours())) +
          ":" +
          incidentDateMintes;
      } else {
        Time = "0" + Number(currentDate.getHours()) + ":" + incidentDateMintes;
      }
    } else {
      Time = Number(Number(currentDate.getHours())) + ":" + incidentDateMintes;
    }
    const day = currentDate.getDate().toString();
    const month = Number(currentDate.getMonth() + 1).toString();
    const year = currentDate.getFullYear().toString();

    this.patchDateTime(
      Time,
      currentDate.getDate().toString(),
      Number(currentDate.getMonth() + 1).toString(),
      currentDate.getFullYear().toString()
    );
  }

  patchDateTime(time: any, day: any, month: any, year: any) {
    this.newIncidentForm.patchValue({
      time: time,
      day: day,
      month: month,
      year: year,
    });
  }

  //#endregion


  getLocation() {
    const self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            // this.getLocationDetailFromGoogleApi(position.coords.latitude, position.coords.longitude)
            // .then((result) => {
            //   console.log(result);
            // }).catch((err) => {
            //   console.log(err);
            // }).finally();

            self._apiloader.load().then(() => {
              let geocoder = new google.maps.Geocoder();
              let latlng = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              self.lat = position.coords.latitude;
              self.lng = position.coords.longitude;
              geocoder.geocode(
                {
                  location: latlng,
                },
                function (results) {
                  if (results[0]) {
                    const postCode =
                      results[1].address_components[
                        results[1].address_components.length - 1
                      ].long_name;

                    self.newIncidentForm.patchValue({
                      location: results[0].formatted_address,
                    });

                    // self.InitilizeFormInChangeOfPostCode(
                    //   postCode,
                    //   postCode + ", " + results[0].formatted_address
                    // );

                    //self.searchElementRef.nativeElement.value = results[0].formatted_address;
                    self.postCodeForSaving = postCode;
                    // self.newIncidentForm.setValue({

                    //   location: postCode + ", " + results[0].formatted_address,
                    //   postCode: postCode
                    // });
                  } else {
                    console.log("Not found");
                  }
                }
              );
            });

            if (
              self.lat == undefined ||
              self.lat == null ||
              self.lng == undefined ||
              self.lng == null
            ) {
              self.lat = position.coords.latitude;
              self.lng = position.coords.longitude;
              self.markers = [];
              self.markers.push({
                lat: self.lat,
                lng: self.lng,
                label: "",
                draggable: true,
                visible: true,
                opacity: 0.7,
                icon: "../../assets/images/red-marker.png",
              });
            }
          }
        },
        (error: any) => console.log(error)
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  mapClicked($event: MouseEvent) {
    (this.lat = $event.coords.lat), (this.lng = $event.coords.lng);
    this._apiloader.load().then(() => {
      let geocoder = new google.maps.Geocoder();
      let latlng = {
        lat: this.lat,
        lng: this.lng,
      };
      geocoder.geocode(
        {
          location: latlng,
        },
        function (results) {
          // if (results[0]) {
          //   this.currentLocation = results[0].formatted_address;
          //   console.log(this.currentLocation);
          // } else {
          //   console.log("Not found");
          // }
        }
      );
    });
  }

  markerDragEnd(m: Marker, $event: MouseEvent) {
    const self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          // this.lat = position.coords.latitude;
          // this.lng = position.coords.longitude;
          self._apiloader.load().then(() => {
            let geocoder = new google.maps.Geocoder();
            let latlng = {
              lat: $event.coords.lat,
              lng: $event.coords.lng,
            };
            this.lat = $event.coords.lat;
            this.lng = $event.coords.lng;
            geocoder.geocode(
              {
                location: latlng,
              },
              function (results) {
                if (results[0]) {
                  const postCode =
                    results[1].address_components[
                      results[1].address_components.length - 1
                    ].long_name;

                  self.newIncidentForm.patchValue({
                    location: results[0].formatted_address
                  });

                  //self.searchElementRef.nativeElement.value = results[0].formatted_address;
                  self.postCodeForSaving = postCode;

                } else {
                  console.log("Not found");
                }
              }
            );
          });
        }
      });
    }
  }


  onSubmit() {
    this.isSubmitted = true;
    if (this.newIncidentForm.invalid) {
      return;
    }

    let incidentDTO = new IncidentDTO();
    let incidentDate = new Date();
    if (
      !this.time ||
      this.time.value == null ||
      this.time.value == undefined ||
      this.time.value == "" ||
      !this.day ||
      this.day.value == null ||
      this.day.value == undefined ||
      this.day.value == "" ||
      !this.month ||
      this.month.value == null ||
      this.month.value == undefined ||
      this.month.value == "" ||
      !this.year ||
      this.year.value == null ||
      this.year.value == undefined ||
      this.year.value == ""
      // !this.searchElementRef.nativeElement.value ||
      // this.searchElementRef.nativeElement.value == ''
    ) {
      return;
    }

    let times = this.time.value.split(":");
    incidentDate.setHours(Number(Number(times[0])));
    incidentDate.setMinutes(Number(times[1]));

    incidentDate.setDate(Number(this.day.value));

    incidentDate.setMonth(Number(this.month.value) - 1);

    incidentDate.setFullYear(Number(this.year.value));

    incidentDTO.title = this.title.value;


    incidentDTO.incidentDate = this._dateService.ConvertDateToSend(incidentDate);
    incidentDTO.incidentDescription = this.incidentDetail.value;
    //incidentDTO.address = this.searchElementRef.nativeElement.value;
    incidentDTO.address = this.location.value;
    incidentDTO.postcode = this.postCodeForSaving;
    incidentDTO.status = 0;
    incidentDTO.latitude = this.lat.toString();
    incidentDTO.longitude = this.lng.toString();

    incidentDTO.bcuregionid = this.selectedRegion.id;
    incidentDTO.spotDetails = this.selectedRegion.name;
    const group = this.selectedRegion.groups.find(
      (x) => x.organizationGroupName === this.localAuthority.value
    );
    if (group) {
      incidentDTO.group = group.organizationGroupId;
    } else {
      incidentDTO.group = this.selectedRegion.groups[0].organizationGroupId;
    }

    // incidentDTO.cadnumber = this.CADNumber.value;
    // incidentDTO.spotDetails = this.CreateIncidentDTO.LocalAuthority;
    incidentDTO.offencegroup = this.OffenceCategory.value;
    incidentDTO.subgroup = this.method.value;
    incidentDTO.peopledisplaced = this.peopleDisplaced.value ?? false;
    incidentDTO.id = undefined;

    debugger;

    this._incidentService.saveUpdateIncident(incidentDTO).subscribe(
      (response) => {
        if (response && response.data && response.statusCode == 200) {
          this._toastr.success(response.message, "Success");
          this._router.navigate(["home-dashboard"]);
        }
      },
      (error) => {
        if (error && error.error && error.error.message) {
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this._toastr.error(error.error.message, "Error");
        } else {
          this._apiErrorService.ShowErrorModal();
          //this._toastr.error("Something went wrong!");
        }
      }
    );
  }

}
