import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ErrorConfirmationBoxComponent } from 'src/app/overlays/error-confirmation-box/error-confirmation-box.component';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {

  constructor(
    private _dialofRef: MatDialog
    ) { }

  ShowErrorModal(tital: string = "Error", body: string = "Something went wrong!"){
    this._dialofRef.open(ErrorConfirmationBoxComponent, {
      width: 'auto',
      panelClass: 'custom-dialog-container',
      data: {tital: tital, body: body}
    });
  }

  ShowError(error){
    if (error && error.error && error.error.message){
      console.log("Error:" + JSON.stringify(error));
      this.ShowErrorModal("Error", error.error.message);
      //this._toastr.error(error.error.message, "Error");
    }
    else{
      this.ShowErrorModal();
      //this._toastr.error("Something went wrong!");
    }
  }
}
