import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadScriptService {

  constructor() { }

  public loadScript(tagId: string, scriptFileName: string = 'home-dashboardScript.js') {
    const scriptTag = document.getElementById(tagId);
    if (scriptTag == null || scriptTag == undefined) { 
      let body = <HTMLDivElement> document.body;
      let script = document.createElement('script');
      script.innerHTML = '';
      script.src = `assets/js/${scriptFileName}`;
      script.async = true;
      script.defer = true;
      script.id = tagId;
      body.appendChild(script);
    }
  }

  public removeScript(tagId: string): void {
    const scriptTag = document.getElementById(tagId);
    if (scriptTag != null && scriptTag != undefined){
      document.getElementById(tagId).remove();
    }
  }

  ShouldToggleScriptLoad(){
    const incidentToggleJsElm = document.getElementsByClassName('incident_toggle-js');
    const incidentToggleViewJsElm = document.getElementsByClassName('incident_toggle-view-js');
    if (incidentToggleJsElm && incidentToggleJsElm.length > 0 && incidentToggleViewJsElm && incidentToggleViewJsElm.length > 0){
      return true;
    }
    return false;
  }

  ShouldDropdownScriptLoad(){
    const eachDropdownJsElm = document.getElementsByClassName('each-dropdown-js');
    const mainDropdownJsElm = document.getElementsByClassName('main-dropdown-js');
    const eachDropdownContentJsElm = document.getElementsByClassName('each-dropdown-content-js');
    if (this.IsAllElementRendered(eachDropdownJsElm, mainDropdownJsElm, eachDropdownContentJsElm)){
      return true;
    }
    return false;
  }

  IsAllElementRendered(eachDropdownJsElm: HTMLCollectionOf<Element>, mainDropdownJsElm: HTMLCollectionOf<Element>, eachDropdownContentJsElm: HTMLCollectionOf<Element>){
    return eachDropdownJsElm 
    && mainDropdownJsElm 
    && eachDropdownContentJsElm 
    && eachDropdownJsElm.length > 0 
    && mainDropdownJsElm.length > 0 
    && eachDropdownContentJsElm.length > 0;
  }
}
