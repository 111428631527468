import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { ResponseStatus } from 'src/app/Enum/ResponseStatus.enum';
import { EmergencyTaskAssignee } from 'src/app/Models/EmergencyPlanningModels/EmergencyTaskAssignee';
import { LAEmergencyIncidentForImmediate } from 'src/app/Models/EmergencyPlanningModels/LAEmergencyIncident';
import { LAEmergencyPlanningActionItem } from 'src/app/Models/EmergencyPlanningModels/LAEmergencyPlanningActions';
import { Member } from 'src/app/Models/Members/Member';
import { IncidentNoteDTO } from 'src/app/Models/Notes/IncidentNoteDTO';
import { PostProtocolActionsModel } from 'src/app/Models/PostProtocolActionsModel';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { AssignMemberPopupComponent } from 'src/app/overlays/assign-member-popup/assign-member-popup.component';
import { ConfirmationDialogServiceService } from 'src/app/overlays/confirmation-dialog/confirmation-dialog-service.service';
import { IncidentMessageSendService } from 'src/app/overlays/incident-message-send/incident-message-send.service';
import { NotesOverlayComponent } from 'src/app/overlays/notes-overlay/notes-overlay.component';
import { LoadScriptService } from '../load-script.service';
import { ImmediateResponseService } from './immediate-response.service';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';

@Component({
  selector: 'app-immediate-response',
  templateUrl: './immediate-response.component.html',
  styleUrls: ['./immediate-response.component.css']
})
export class ImmediateResponseComponent implements OnInit {

  incidentId: string = "";
  incidentsData: LAEmergencyIncidentForImmediate[] = [];
  incidentsDataDeepCopy: LAEmergencyIncidentForImmediate[] = [];
  isDataLoaded = false;

  selectedTeamMember: string;
  selectedincedence: string;
  selectedincedenceStatus: string;

  incedenceStatuses: string[] = [];
  teamMembers = new Set();
  incedences = new Set();

  dataLoadedEvent = new Subject<boolean>();
  dataLoadedEventSubscription: Subscription;

  isTeamLoaded = false;

  selectedincedenceId: string;
  loggedInUserId: string = '';
  allTeamMembers: Member[] = [];

  updateIndividualStatusSubscription: Subscription;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _loadScriptService: LoadScriptService,
    private _immediateResponseService: ImmediateResponseService,
    private _toastr: ToastrService,
    private _dialog: MatDialog,
    private _confirmationDialogServiceService: ConfirmationDialogServiceService,
    private _incidentMessageSendService: IncidentMessageSendService,
    private _apiErrorService: ApiErrorService,
    public _urlHelperService: UrlHelperService
  ) {}

  ngOnInit(): void {
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    
    this._activatedRoute.params.subscribe((param) => {
      
      this.incidentId = param["id"];
      this.selectedincedenceId = param["id"];

    });

    this._activatedRoute.queryParams.subscribe((params) => {
      if (params.category != null && params.category != undefined) {
        this.selectedincedenceStatus = params?.category;
      } else {
        this.selectedincedenceStatus = "All";
      }
      if (params.member != null && params.member != undefined){
        this.loggedInUserId = params.member;
      }
    });

    this._incidentMessageSendService.GetAllTeamMembers()
      .subscribe(response => {
         //console.log(response);
        this.allTeamMembers = response.data;
        this.isTeamLoaded = true;
      }, 
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this._toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this._toastr.error("Something went wrong!");
        }
      });

    this._immediateResponseService.GetAllIncidentEmergencyPlanningActions().subscribe(response => {
      if (!response || !response.data || response.statusCode != 200){
        return;
      }

      this.incidentsData = response.data;
      // console.log(this.incidentsData);
      this.incidentsData.forEach(incident => {
        // sort the organisationTeam memebers 
        incident.organisationTeamForIncident.sort((a, b) => a.firstName.localeCompare(b.firstName));

        // sort the actions
        const array = JSON.parse(JSON.stringify(incident.actions)) as LAEmergencyPlanningActionItem[];   //[...incident.actions];
      
        const SharePartnerMessage = array.find(x => x.EmergencyTaskTitle == 'Share partner message ');
        const PrepareCSTProtocol = array.find(x => x.EmergencyTaskTitle == 'Prepare CST protocol');
        const ShareMETHANEinformation = array.find(x => x.EmergencyTaskTitle == 'Share METHANE information');
       
        const indexString4 = array.indexOf(ShareMETHANEinformation);
        
        if (indexString4 !== -1) {
          
          const indexofSharePartnerMessage = array.indexOf(SharePartnerMessage);
          const indexPrepareCSTProtocol = array.indexOf(PrepareCSTProtocol);
          
          if (indexofSharePartnerMessage !== -1 || indexPrepareCSTProtocol !== -1) {
            
            !!SharePartnerMessage && array.splice(indexofSharePartnerMessage, 1);
            
            if(SharePartnerMessage){
              !!PrepareCSTProtocol && array.splice(indexPrepareCSTProtocol-(indexPrepareCSTProtocol-indexofSharePartnerMessage), 1);
            }
            else{
              !!PrepareCSTProtocol && array.splice(indexPrepareCSTProtocol, 1);
            }
            
            
            if( SharePartnerMessage && PrepareCSTProtocol){
              array.splice(indexString4 , 0, SharePartnerMessage, PrepareCSTProtocol);
              }
            else if(SharePartnerMessage && !PrepareCSTProtocol) {
              array.splice(indexString4 , 0, SharePartnerMessage);
            }
            else if(PrepareCSTProtocol && !SharePartnerMessage) {
              array.splice(indexString4 , 0, PrepareCSTProtocol);
            }
          }
        }

        incident.actions = array;
        
      });

      this.isDataLoaded = true;
      this.dataLoadedEvent.next(true);
    }, 
    (error) => {
      if (error && error.error && error.error.message){
        console.log("Error:" + JSON.stringify(error));
        this._apiErrorService.ShowErrorModal("Error", error.error.message);
        //this._toastr.error(error.error.message, "Error");
      }
      else{
        this._apiErrorService.ShowErrorModal();
        //this._toastr.error("Something went wrong!");
      }
    });

    this.dataLoadedEventSubscription = this.dataLoadedEvent.subscribe((x) => {
      if (x) {
        let timerId = setInterval(() => {
          //console.log("dataLoadedEventSubscription start");
        //console.log(x);
        if (this.isDataLoaded && this.isTeamLoaded){
          const title = this.incidentsData.find(
            (x) => x.id === this.selectedincedenceId
          )?.title;
          if (title == undefined || title == null) {
            this.selectedincedence = "All";
          } else {
            this.selectedincedence = title;
          }
  
          this.teamMembers.add("All");
          this.incedences.add("All");
  
          for (const actions of this.incidentsData) {
            this.incedences.add(actions.title);
          }
  
          for (const incident of this.incidentsData) {
            if (incident.actions && incident.actions.length > 0) {
              for (const task of incident.actions) {
                if (task.AssigneeName != null && task.AssigneeName != undefined){
                  this.teamMembers.add(task.AssigneeName);
                }
              }
            }
          }
  
          const staticDataIncedenceStatuses = [
            "All",
            "Not Required",
            "Pending",
            "Active",
            "Complete",
          ];
          this.incedenceStatuses.push(...staticDataIncedenceStatuses);

          if (this.allTeamMembers && this.allTeamMembers.length > 0 && this.loggedInUserId){
            const teamMember = this.allTeamMembers.find(x => x.id == this.loggedInUserId);

            const teamMembers = [...this.teamMembers] as string[];

            if (teamMember && teamMembers.find(x => x == `${teamMember.firstName} ${teamMember.familyName}`)){
              this.selectedTeamMember = `${teamMember.firstName} ${teamMember.familyName}`;
            }
            else{
              this.selectedTeamMember = [...this.teamMembers][0] as string;
            }
          }
          else{
            this.selectedTeamMember = [...this.teamMembers][0] as string;
          }
  
          //this.selectedincedenceStatus = this.incedenceStatuses[0];
  
          
          this.filterData();

          this._loadScriptService.loadScript(
            "response-actions-pre-protocol-launchScriptTag"
          );
          
          // this._loadScriptService.loadScript(
          //   "protocol-actions-post-protocol-launchScriptTag"
          // );
  
          //console.log(this.incidentsData);

          clearInterval(timerId);
        }
        }, 100);
        
        
      }
    });
    
  }

  teamMemberClicked(selectedValue: string) {
    this.selectedTeamMember = selectedValue;
    this.filterData();
    document.getElementById("t_dropdown").classList.remove("shown");
  }

  incedenceStatusClicked(selectedValue: string) {
    this.selectedincedenceStatus = selectedValue;
    this.filterData();
    document.getElementById("t_dropdown2").classList.remove("shown");
  }

  incedenceClicked(selectedValue: string) {
    this.selectedincedence = selectedValue;
    this.filterData();
    document.getElementById("t_dropdown3").classList.remove("shown");
  }

  incidentpopupClicked(status: string, incident: LAEmergencyIncidentForImmediate) {
    this._immediateResponseService
      .UpdateResponseStatus(status, incident.id)
      .subscribe(
        (response) => {
          if (response && response.statusCode == 200 && response.data > 0) {
            
            const outterIndex = this.incidentsData.findIndex(x => x.id === incident.id);
            if (outterIndex != -1){
              if (status === 'Reject'){
                this.incidentsData.splice(outterIndex, 1);
                //this.incidentsData[outterIndex].responseStatus = ResponseStatus.Declined;
              }
              else if (status === 'Acknowledge'){
                this.incidentsData[outterIndex].responseStatus = ResponseStatus.Active;
              }
            }

            this.filterData();
          }
        },
        (error) => {
          if (error && error.error && error.error.message){
            console.log("Error:" + JSON.stringify(error));
            this._apiErrorService.ShowErrorModal("Error", error.error.message);
            //this._toastr.error(error.error.message, "Error");
          }
          else{
            this._apiErrorService.ShowErrorModal();
            //this._toastr.error("Something went wrong!");
          }
        }
      );
  }

  filterData() {
    //console.log("filter data started");
    let temp: LAEmergencyIncidentForImmediate = new LAEmergencyIncidentForImmediate();
    let tempActions: LAEmergencyIncidentForImmediate[] = [];

    let tempDataForFilter = JSON.parse(JSON.stringify(this.incidentsData)); // creating deep copy

    if (this.selectedincedence == "All") {
      tempActions = tempDataForFilter;
    } else {
      tempActions = tempDataForFilter.filter(
        (x) => x.title === this.selectedincedence
      );
    }

    for (const teamActions of tempDataForFilter) {
      const incident = tempActions.find((x) => x === teamActions);
      if (incident != null && incident != undefined) {
        temp.id = teamActions.id;
        temp.location = teamActions.location;
        temp.title = teamActions.title;

        if (
          incident.actions != null &&
          incident.actions != undefined &&
          incident.actions.length > 0
        ) {
          let tempTasks : LAEmergencyPlanningActionItem[] = [];
          if (
            this.selectedTeamMember === "All" &&
            this.selectedincedenceStatus == "All"
          ) {
            tempTasks = teamActions.actions;
          } else if (
            this.selectedTeamMember != "All" &&
            this.selectedincedenceStatus != "All"
          ) {
            tempTasks = teamActions.actions.filter(
              (x) =>
                x.EmergencyTaskStatus ===
                  this._immediateResponseService.getStatusNumber(
                    this.selectedincedenceStatus
                  ) && x.AssigneeName === this.selectedTeamMember
            );
          } else if (
            this.selectedTeamMember == "All" &&
            this.selectedincedenceStatus != "All"
          ) {
            tempTasks = teamActions.actions.filter(
              (x) =>
                x.EmergencyTaskStatus ===
                this._immediateResponseService.getStatusNumber(
                  this.selectedincedenceStatus
                )
            );
          } else {
            tempTasks = teamActions.actions.filter(
              (x) => x.AssigneeName === this.selectedTeamMember
            );
          }
          //const actionToBeDisplyed = tempTasks.filter(x => x.EmergencyTaskStatus != 1)
          temp.actions = [...tempTasks];
          incident.actions = [...temp.actions];
        }

        tempActions.map((item, index) => {
          if (item === incident) {
            tempActions.splice(index, 1, incident);
          }
        });
      }
    }
    this.incidentsDataDeepCopy = [...tempActions];

    //console.log("filter data finished");
  }

  getStatusData(status: number): { classname: string; statusName: string } {
    return this._immediateResponseService.getStatusData(status);
  }

  getResponseStatusName(status: number): string {
    return this._immediateResponseService.getStringNameFromNumberResponseStatus(status);
  }

  IndividualTaskStatusClicked(
    status: number = null,
    OutterIndex: number,
    InnerIndex: number,
    //incident: PostProtocolActionsModel,
    //task: PostProtocolActionsTaskModel
    actions: LAEmergencyPlanningActionItem, 
    incident: LAEmergencyIncidentForImmediate
  ) {


    if (
      OutterIndex == undefined ||
      InnerIndex == undefined ||
      status == undefined ||
      status == null ||
      actions == undefined ||
      actions.EmergencyTaskMemberId == undefined
    ) {
      return;
    }

    if (actions.AssigneeName == undefined){
      this._toastr.error("Status can not be updated as no member is assigned");
      return;
    }

    this.updateIndividualStatusSubscription =
      this._confirmationDialogServiceService
        .confirmDialog(
          "Update Confirmation",
          "Are you sure you want to update status"
        )
        .subscribe((isConfirm) => {
          if (!isConfirm) {
            return;
          }

          document.getElementById(OutterIndex + 'update-status-spinner' + InnerIndex).style.display = 'block';
          document.getElementById(OutterIndex + 'btnTaskStatus' + InnerIndex).style.display = 'none';          
          document.getElementById(OutterIndex + 'status-td' + InnerIndex).style.backgroundColor = '#fff';

          this._immediateResponseService.UpdateIndividualStatus(actions.EmergencyTaskMemberId, status).subscribe(response => {
            //console.log(response);

            if (response == undefined || response.data == undefined || response.statusCode != 200){
              this._toastr.error("Something went wrong while updating status");
              return;
            }

            let tempDataForFilter = JSON.parse(
              JSON.stringify(this.incidentsDataDeepCopy)
            ); // creating deep copy

            let tempActions: LAEmergencyIncidentForImmediate[] = [];
            if (this.selectedincedence == "All") {
              tempActions = tempDataForFilter;
            } else {
              tempActions = tempDataForFilter.filter(
                (x) => x.title === this.selectedincedence
              );
            }

            tempActions[OutterIndex].actions[InnerIndex].EmergencyTaskStatus = status;
            if (status == 1){
              tempActions[OutterIndex].actions.splice(InnerIndex, 1);
            }

              this.incidentsDataDeepCopy = [...tempActions];

              const incidentIndex = this.incidentsData.findIndex(
                (x) => x.id === incident.id
              );
              if (incidentIndex != -1) {
                const taskIndex = this.incidentsData[incidentIndex].actions.findIndex(
                  (x) =>
                    x.EmergencyTaskId === actions.EmergencyTaskId
                );

                if (taskIndex != -1) {
                  this.incidentsData[incidentIndex].actions[taskIndex].EmergencyTaskStatus = status;
                  if (status == 1){
                    this.incidentsData[incidentIndex].actions.splice(taskIndex, 1);
                  }
                  this.filterData();
                }
              }

              document.getElementById(OutterIndex + 'update-status-spinner' + InnerIndex).style.display = 'none';
              document.getElementById(OutterIndex + 'btnTaskStatus' + InnerIndex).style.display = 'block';
          }, 
          );

        },
        (error) => {
          document.getElementById(OutterIndex + 'update-status-spinner' + InnerIndex).style.display = 'none';
              document.getElementById(OutterIndex + 'btnTaskStatus' + InnerIndex).style.display = 'block';

              if (error && error.error && error.error.message){
                console.log("Error:" + JSON.stringify(error));
                this._apiErrorService.ShowErrorModal("Error", error.error.message);
                //this._toastr.error(error.error.message, "Error");
              }
              else{
                this._apiErrorService.ShowErrorModal();
                //this._toastr.error("Something went wrong!");
              }
        });
  }

  
  personClicked(members: Member[], outerIndex: number, innerIndex: number, actions: LAEmergencyPlanningActionItem, incident: LAEmergencyIncidentForImmediate){
    let memberArray : {name: string, avatar: string}[] = [];
    memberArray = members.map(x => { 
      return {name: `${x.firstName} ${x.familyName}`, avatar: x.avatar}
    });

    //const memberStringArray = members.map(x => `${x.firstName} ${x.familyName}`);

    this._dialog.open(AssignMemberPopupComponent, {
      height: '80vh',
      data: {members: memberArray}
    }).afterClosed().subscribe(selectedMember => {
      if (selectedMember == null || selectedMember == undefined || selectedMember == ''){
        return;
      }

      const selectedTeamMember = members.find(x => `${x.firstName} ${x.familyName}` === selectedMember);
      if (!selectedTeamMember){
        return;
      }

      this.AssignTeamMember(selectedTeamMember, outerIndex, innerIndex, actions, incident);
    });
  }


  AssignTeamMember(
    member: Member, 
    OutterIndex: number,
    InnerIndex: number,
    actions: LAEmergencyPlanningActionItem, 
    incident: LAEmergencyIncidentForImmediate
    ){
    if (incident.id == null || incident.id == undefined ||
      member.id == null || member.id == undefined ||
      actions.EmergencyTaskId == null || actions.EmergencyTaskId == undefined || actions.EmergencyTaskId == 0){
        return;
      }

      document.getElementById(OutterIndex + 'update-assigned-member-spinner' + InnerIndex).style.display = 'block';
      document.getElementById(OutterIndex + 'assigned-member-div' + InnerIndex).style.display = 'none';

      let emergencyTaskAssignee : EmergencyTaskAssignee = new EmergencyTaskAssignee();
      emergencyTaskAssignee.assigneeId = member.id;
      emergencyTaskAssignee.incidentId = incident.id;
      emergencyTaskAssignee.emergencyTaskId = actions.EmergencyTaskId;
      this._immediateResponseService.AssignTeamMemberToTask(emergencyTaskAssignee).subscribe(response => {
        if (response == null || response == undefined ||
          response.data == null || response.data == undefined ||
          response.statusCode != 200){
            this._toastr.error("Something went wrong while assigning member");
          }


          let tempDataForFilter = JSON.parse(
            JSON.stringify(this.incidentsDataDeepCopy)
          ); // creating deep copy

          let tempActions: LAEmergencyIncidentForImmediate[] = [];
          if (this.selectedincedence == "All") {
            tempActions = tempDataForFilter;
          } else {
            tempActions = tempDataForFilter.filter(
              (x) => x.title === this.selectedincedence
            );
          }

          tempActions[OutterIndex].actions[InnerIndex].AssigneeName = member.firstName + ' ' + member.familyName;
          tempActions[OutterIndex].actions[InnerIndex].AssigneeAvatar = member.avatar;
          tempActions[OutterIndex].actions[InnerIndex].EmergencyTaskStatus = 2;

          this.incidentsDataDeepCopy = [...tempActions];
          const incidentIndex = this.incidentsData.findIndex(
            (x) => x.id === incident.id
          );

          if (incidentIndex != -1) {
            const taskIndex = this.incidentsData[incidentIndex].actions.findIndex(
              (x) =>
                x.EmergencyTaskId === actions.EmergencyTaskId
            );
            if (taskIndex != -1) {
              this.incidentsData[incidentIndex].actions[taskIndex].AssigneeName = member.firstName + ' ' + member.familyName;
              this.incidentsData[incidentIndex].actions[taskIndex].AssigneeAvatar = member.avatar;
              this.incidentsData[incidentIndex].actions[taskIndex].EmergencyTaskStatus = 2;
              this.filterData();
            }
          }

          document.getElementById(OutterIndex + 'update-assigned-member-spinner' + InnerIndex).style.display = 'none';
          document.getElementById(OutterIndex + 'assigned-member-div' + InnerIndex).style.display = 'block';

        //console.log(response);
      },
      (error) => {
        document.getElementById(OutterIndex + 'update-assigned-member-spinner' + InnerIndex).style.display = 'none';
        document.getElementById(OutterIndex + 'assigned-member-div' + InnerIndex).style.display = 'block';

        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this._toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this._toastr.error("Something went wrong!");
        }
      })
  }


  IssueClicked(action: LAEmergencyPlanningActionItem, incident: LAEmergencyIncidentForImmediate, i: number, ii: number) {
    this.NotesClicked(action, incident, i, ii);

    this.incidentsData[i].actions[ii].HasIssue = !this.incidentsData[i].actions[ii].HasIssue;
    this.incidentsDataDeepCopy[i].actions[ii].HasIssue = !this.incidentsDataDeepCopy[i].actions[ii].HasIssue;
  }

  
  NotesClicked(action: LAEmergencyPlanningActionItem, incident: LAEmergencyIncidentForImmediate, i: number, ii: number){
    // if (!action.hasNotes){
    //   return;
    // }

    this._immediateResponseService.GetEmergencyPlanningNotes(action.EmergencyTaskMemberId).subscribe(response => {
      if (!response || !response.data || response.statusCode != 200){
        this._toastr.error("Something went wrong", "Error");
      }

      const incidentNoteDTO = response.data as IncidentNoteDTO[];
      const dataToBeSend = { incidentId: incident.id, emergencyTaskMemberId: action.EmergencyTaskMemberId, noteType: "EMERGENCY" ,incidentTitle: action.EmergencyTaskTitle, noteData: incidentNoteDTO };

      this._dialog.open(NotesOverlayComponent, {
        data: dataToBeSend
      }).afterClosed().subscribe(isNoteAdded => {
        if (isNoteAdded !== true){
           return;
        }

        const incidentIndexForData = this.incidentsData.findIndex(x => x.id === incident.id);
        if (incidentIndexForData != -1){
          const actionIndex = this.incidentsData[incidentIndexForData].actions.findIndex(x => x.EmergencyTaskId === action.EmergencyTaskId);
          if (actionIndex != -1){
            this.incidentsData[incidentIndexForData].actions[actionIndex].hasNotes = true;
          }
        }

        const incidentIndexForTeamActions = this.incidentsDataDeepCopy.findIndex(x => x.id === incident.id);
        if (incidentIndexForTeamActions != -1){
          const actionIndex = this.incidentsDataDeepCopy[incidentIndexForTeamActions].actions.findIndex(x => x.EmergencyTaskId === action.EmergencyTaskId);
          if (actionIndex != -1){
            this.incidentsDataDeepCopy[incidentIndexForTeamActions].actions[actionIndex].hasNotes = true;
          }
        }

        this.incidentsData[i].actions[ii].hasNotes = true;
        this.incidentsDataDeepCopy[i].actions[ii].hasNotes = true;
       
      });
  
      //console.log(response.data);

    },
      (error) => {
        if (error && error.error && error.error.message){
          console.log("Error:" + JSON.stringify(error));
          this._apiErrorService.ShowErrorModal("Error", error.error.message);
          //this._toastr.error(error.error.message, "Error");
        }
        else{
          this._apiErrorService.ShowErrorModal();
          //this._toastr.error("Something went wrong!");
        }
      })
    
  }

  trackByFn(index: number, item: PostProtocolActionsModel) {
    return index;
  }

  GetStatusNumber(status: string){
    return this._immediateResponseService.getStatusNumber(status);
  }

  ngOnDestroy(): void {
    if (this.dataLoadedEventSubscription){
      this.dataLoadedEventSubscription.unsubscribe();
    }
    
    if (this.updateIndividualStatusSubscription){
      this.updateIndividualStatusSubscription.unsubscribe();
    }

    this._loadScriptService.removeScript(
      "response-actions-pre-protocol-launchScriptTag"
    );
  }

}