import { CognitoService } from 'src/app/Authentication/cognito.service';
import { ErrorInterceptor } from './ErrorInterceptors/ErrorInterceptor';
import { JwtInterceptor } from './Authentication/AuthInterceptors/JwtInterceptor';
import { LoadScriptService } from './home-dashboard/load-script.service';
import { HomeNavMenuService } from './home-dashboard/home-nav-menu/home-nav-menu.service';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CoreModule } from './core/core.module';
import { FooterComponent } from './footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocalAuthorityCstProtocolComponent } from './local-authority-cst-protocol/local-authority-cst-protocol.component';
import { StakeholderMessagingComponent } from './Stakeholder/stakeholder-messaging/stakeholder-messaging.component';
import { LocalAuthorityActionsResponseComponent } from './local-authority-actions-response/local-authority-actions-response.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard/home-dashboard.component';

// import { IncidentDetailNewComponent } from './incident-detail-new/incident-detail-new.component';
import { HomeNavMenuComponent } from './home-dashboard/home-nav-menu/home-nav-menu.component';
//import { EmergencyPlanningComponent } from './home-dashboard/emergency-planning/emergency-planning.component';
import { CstProtocolComponent } from './home-dashboard/cst-protocol/cst-protocol.component';
import { AppRoutingModule } from './app-routing.module';
import { IncidentSummaryPreProtocolLaunchComponent } from './incident-summary-pre-protocol-launch/incident-summary-pre-protocol-launch.component';
import { NewIncidentLaStoreUserComponent } from './new-incident-la-store-user/new-incident-la-store-user.component';
import { TeamMessagingDashboardComponent } from './team/team-messaging-dashboard/team-messaging-dashboard.component';
import { TeamMembersComponent } from './team/team-members/team-members.component';
import { FinaliseProtocolActionsPostProtocolLaunchComponent } from './home-dashboard/finalise-protocol-actions-post-protocol-launch/finalise-protocol-actions-post-protocol-launch.component';
import { NewIncidentComponent } from './new-incident/new-incident.component';
import { StakeholderMembersComponent } from './Stakeholder/stakeholder-members/stakeholder-members.component';
import { CstTemplateComponent } from './cst-template/cst-template/cst-template.component';
import { CreateCstTemplateComponent } from './cst-template/create-cst-template/create-cst-template.component';
import { NotesOverlayComponent } from './overlays/notes-overlay/notes-overlay.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IncidentMessageSendComponent } from './overlays/incident-message-send/incident-message-send.component';
import { IncidentMessageComponent } from './overlays/incident-message/incident-message.component';
import { IncidentMessageApproveComponent } from './overlays/incident-message-approve/incident-message-approve.component';
import { ConfirmationDialogComponent } from './overlays/confirmation-dialog/confirmation-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmationModalComponent } from './overlays/confirmation-modal/confirmation-modal.component';
import { SignUpComponent } from './Authentication/sign-up/sign-up.component';
// import { SignInComponent } from './Authentication/sign-in/sign-in.component';

// import { HttpInterceptorProviders} from './interceptors/interceptor-provider';
import { AuthGuard } from './Authentication/auth.guard/AuthGuard';
import { AddTeamMemberComponent } from './team/add-team-member/add-team-member.component';
import { AddStakeholderComponent } from './Stakeholder/add-stakeholder/add-stakeholder.component';
import { DrawMapComponent } from './overlays/draw-map/draw-map.component';
import { CstProtocolLaunchComponent } from './cst-template/cst-protocol-launch/cst-protocol-launch.component';
import { AssignMemberPopupComponent } from './overlays/assign-member-popup/assign-member-popup.component';
import { StakeholderMessageSendComponent } from './overlays/stakeholder-message-send/stakeholder-message-send.component';
import { ErrorConfirmationBoxComponent } from './overlays/error-confirmation-box/error-confirmation-box.component';
import { LoginReceiverComponent } from './Authentication/login-receiver/login-receiver.component';
import { IncidentLocationDetailsComponent } from './incident-location-details/incident-location-details.component';
import { NavbarService } from './nav-menu/navbar.service';
import { BCUAuthGuard } from './bcu/Authentication/BCUAuthGuard';
import { SharedModule } from './shared/shared.module';
import { EditCstTemplateComponent } from './cst-template/edit-cst-template/edit-cst-template.component';
import { ImmediateResponseComponent } from './home-dashboard/immediate-response/immediate-response.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    DashboardComponent,
    //ContactsComponent,
    //InitialIncidentFormComponent,
    FooterComponent,
    //TableLoaderComponent,
    // BcuDashboardComponent,
    IncidentLocationDetailsComponent,
    LocalAuthorityCstProtocolComponent,
    StakeholderMessagingComponent,
    LocalAuthorityActionsResponseComponent,
    PageNotFoundComponent,
    HomeDashboardComponent,
    //EmergencyPlanningComponent,
    CstProtocolComponent,
    //IncidentDetailNewComponent,
    HomeNavMenuComponent,
    IncidentSummaryPreProtocolLaunchComponent,
    NewIncidentLaStoreUserComponent,
    TeamMessagingDashboardComponent,
    TeamMembersComponent,
    FinaliseProtocolActionsPostProtocolLaunchComponent,
    NewIncidentComponent,
    StakeholderMembersComponent,
    CstTemplateComponent,
    CreateCstTemplateComponent,
    NotesOverlayComponent,
    IncidentMessageSendComponent,
    IncidentMessageComponent,
    IncidentMessageApproveComponent,
    ConfirmationDialogComponent,
    ConfirmationModalComponent,
    SignUpComponent,
    //SignInComponent,
    AddTeamMemberComponent,
    AddStakeholderComponent,
    DrawMapComponent,
    CstProtocolLaunchComponent,
    AssignMemberPopupComponent,
    StakeholderMessageSendComponent,
    ErrorConfirmationBoxComponent,
    LoginReceiverComponent,
    EditCstTemplateComponent,
    ImmediateResponseComponent,
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      // apiKey: 'AIzaSyCaSBzQsVtkOdQtx5C0p9iUauedMIskIBg',
      //  apiKey: 'AIzaSyA9qQJ3tHUQV6C-V3t2K97emshVE389yzg',
      apiKey: 'AIzaSyDDo9lsCFSrjaax812Ze81V-uhXuxJs310',
      libraries: ['places', 'drawing', 'geometry']
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    //AmplifyAngularModule,
    MatProgressSpinnerModule,
    /* configure App with AmplifyAuthenticatorModule */
    // AmplifyAuthenticatorModule,
    // BcuModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    HomeNavMenuService, 
    LoadScriptService, 
    //AmplifyService, 
    CognitoService,
    NavbarService,
    AuthGuard,
    BCUAuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NotesOverlayComponent
  ]
})
export class AppModule { }
